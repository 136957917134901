export const reAlphaNumeric = /^[a-z0-9, ]+$/i;
export const reAlphabetic = /^[a-z ]+$/i;
export const reNumeric = /^[0-9\b]+$/;
export const reForDate = /^[0-9/-]+$/;
export const reForNames = /^[a-z- ]+$/i;
//export const reEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

//export const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})+$/;
export const mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})+$/;

export const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export const atLeastOneLowercaseAlphabeticRegex = /^(?=.*[a-z ])/;

export const atLeastOneUppercaseAlphabeticRegex = /^(?=.*[A-Z ])/;

export const atLeastOneNumericRegex = /^(?=.*[0-9 ])/;

export const atLeastOneSpecialCharacterRegex = /^(?=.*[!@#?$%^&* ])/;

export const atLeastSevenCharactersLongRegex = /^(?=.{7,})/;

export const atMostTwentySevenCharactersLongRegex = /^(?=.{7,27}$)/;
