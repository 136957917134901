import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Schools from "./components/Schools";
import NotFound from "./components/NotFound";
import SchoolsList from "./features/schools/SchoolsList";
import SchoolsAdd from "./features/schools/SchoolsAdd";
import SchoolsSearch from "./features/schools/SchoolsSearch";
import SchoolsView from "./features/schools/SchoolsView";
import SchoolsUpdate from "./features/schools/SchoolsUpdate";
import SchoolsDiscard from "./features/schools/SchoolsDiscard";
import EduxooNavbar from "./components/layout/EduxooNavbar";
import SchoolsMain from "./features/schools/SchoolsMain";
import TeachersList from "./features/teachers/TeachersList";
import TeachersView from "./features/teachers/TeachersView";
import TeachersMain from "./features/teachers/TeachersMain";
import TeachersAddForm from "./features/teachers/TeachersAddForm";
import SchoolYearsMain from "./features/schoolyears/SchoolYearsMain";
import SchoolYearsAdd from "./features/schoolyears/SchoolYearsAdd";
import SchoolYearsList from "./features/schoolyears/SchoolYearsList";
import SchoolYearsView from "./features/schoolyears/SchoolYearsView";
import LocationsAdd from "./features/locations/LocationsAdd";
import LocationsList from "./features/locations/LocationsList";
import LocationsView from "./features/locations/LocationsView";
import ClassroomsAdd from "./features/classrooms/ClassroomsAdd";
import ClassroomsList from "./features/classrooms/ClassroomsList";
import ClassroomsView from "./features/classrooms/ClassroomsView";
import SchoolClassesAdd from "./features/schoolclasses/SchoolClassesAdd";
import SchoolClassesList from "./features/schoolclasses/SchoolClassesList";
import SchoolClassesView from "./features/schoolclasses/SchoolClassesView";
import StudentsMain from "./features/students/StudentsMain";
import StudentsAdd from "./features/students/StudentsAdd";
import ParentsAdd from "./features/parents/ParentsAdd";
import StudentsList from "./features/students/StudentsList";
import ParentsList from "./features/parents/ParentsList";
import StudentsRegister from "./features/students/StudentsRegister";
import ImportsExcel from "./features/imports/ImportsExcel";
import AdmissionsAdd from "./features/admissions/AdmissionsAdd";
import AdmissionsList from "./features/admissions/AdmissionsList";
import TeachersAddInfo from "./features/teachers/TeachersAddInfo";
import AdmissionRegistersList from "./features/admissionregisters/AdmissionRegistersList";
import AdmissionsView from "./features/admissions/AdmissionsView";
import { SignIn } from "./features/auths/SignIn";
import RequireAuth from "./features/auths/RequireAuth";
import Layout from "./components/layout/Layout";
import Logout from "./components/layout/Logout";
import StudentsView from "./features/students/StudentsView";
import WelcomesHome from "./features/welcomes/WelcomesHome";
import StudentsByClass from "./features/students/StudentsByClass";
import ChildrenList from "./features/parentsuser/ChildrenList";
import ParentsView from "./features/parents/ParentsView";
import ParentsMain from "./features/parentsuser/ParentsMain";
import ChildrenRegister from "./features/parentsuser/ChildrenRegister";
import AdmissionRegistersApplications from "./features/admissionregisters/AdmissionRegistersApplications";
import ParentRegistrationHistory from "./features/parentsuser/ParentRegistrationHistory";
import { AdmissionRegistersStudentRegister } from "./features/admissionregisters/AdmissionRegistersStudentRegister";
import AdmissionRegistersView from "./features/admissionregisters/AdmissionRegistersView";
import SchoolYearsUpdate from "./features/schoolyears/SchoolYearsUpdate";
import TeachersUpdate from "./features/teachers/TeachersUpdate";

import StudentsClassChange from "./features/students/StudentsClassChange";
import StudentsClassChangeView from "./features/students/StudentsClassChangeView";
import UsersMain from "./features/users/UsersMain";
import UsersAddParentSearch from "./features/users/UsersAddParentSearch";
import UsersAddParentSearchView from "./features/users/UsersAddParentSearchView";
import UsersAddTeacherSearch from "./features/users/UsersAddTeacherSearch";
import UsersAddTeacherSearchView from "./features/users/UsersAddTeacherSearchView";
import UserTeachersMain from "./features/userteachers/UserTeachersMain";
import UserTeachersList from "./features/userteachers/UserTeachersList";
import UsersAddStudentSearch from "./features/users/UsersAddStudentSearch";
import UsersAddStudentSearchView from "./features/users/UsersAddStudentSearchView";
import EducationalRecordsMain from "./features/educationalrecords/EducationalRecordMain";
import RegistrationsMain from "./features/registrations/RegistrationsMain";
import AdmissionsAddByClass from "./features/admissions/AdmissionsAddByClass";
import RegistrationsRegisterSearchView from "./features/registrations/RegistrationsRegisterSearchView";
import RegistrationsRegisterSearch from "./features/registrations/RegistrationsRegisterSearch";
import RegistrationsListSearch from "./features/registrations/RegistrationsListSearch";
import RegistrationsListSearchView from "./features/registrations/RegistrationsListSearchView";

import SchoolClassesDescriptionAddSearch from "./features/schoolclasses/SchoolClassesDescriptionAddSearch";
import SchoolClassesDescriptionAddSearchView from "./features/schoolclasses/SchoolClassesDescriptionAddSearchView";
import SchoolClassesDescriptionList from "./features/schoolclasses/SchoolClassesDescriptionList";
import WelcomeHomeSchoolAdmin from "./features/welcomes/WelcomeHomeSchoolAdmin";
import MedicalTypesMain from "./features/eduxoo/system/medicaltypes/MedicalTypesMain";
import MedicalTypesAdd from "./features/eduxoo/system/medicaltypes/MedicalTypesAdd";
import MedicalTypesList from "./features/eduxoo/system/medicaltypes/MedicalTypesList";
import MedicalTypesView from "./features/eduxoo/system/medicaltypes/MedicalTypesView";
import EduxooSystemMain from "./features/eduxoo/system/EduxooSystemMain";
import SchoolsUsersAddSearchAdmin from "./features/eduxoo/system/usersadmin/SchoolsUserAddSearchAdmin";
import SchoolsAddAdmin from "./features/eduxoo/system/usersadmin/SchoolsAddAdmin";
import SchoolsMainAdmin from "./features/eduxoo/system/usersadmin/SchoolsMainAdmin";
import SchoolsListAdmin from "./features/eduxoo/system/usersadmin/SchoolsListAdmin";
import SchoolsUserAddSearchViewAdmin from "./features/eduxoo/system/usersadmin/SchoolsUserAddSearchViewAdmin";
import ActivityLogsMain from "./features/eduxoo/system/activitylogs/ActivityLogsMain";
import ActivityLogsList from "./features/eduxoo/system/activitylogs/ActivityLogsList";
import ActivityLogsSearch from "./features/eduxoo/system/activitylogs/ActivityLogsSearch";
import SchoolsViewAdmin from "./features/eduxoo/system/usersadmin/SchoolsViewAdmin";
import SchoolsUpdateAdmin from "./features/eduxoo/system/usersadmin/SchoolsUpdateAdmin";

function App() {
  return (
    <>
      <EduxooNavbar />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Home />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="*" element={<NotFound />} />
          <Route path="logout" element={<Logout />} />

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="welcome" element={<WelcomesHome />} />

            <Route path="schools" element={<Schools />} />
            <Route path="schoolslist" element={<SchoolsList />} />
            <Route path="schoolsAdd" element={<SchoolsAdd />} />
            <Route path="schoolsUpdate" element={<SchoolsUpdate />} />
            <Route path="schoolsList" element={<SchoolsList />} />
            <Route path="schoolsSearch" element={<SchoolsSearch />} />
            <Route path="schoolsView" element={<SchoolsView />} />
            <Route path="schoolsDiscard" element={<SchoolsDiscard />} />
            <Route path="schoolsMain" element={<SchoolsMain />} />
            <Route path="teachersList" element={<TeachersList />} />
            <Route path="teachersView" element={<TeachersView />} />
            <Route path="teachersMain" element={<TeachersMain />} />
            <Route path="teachersAdd" element={<TeachersAddForm />} />
            <Route path="teachersAddInfo" element={<TeachersAddInfo />} />
            <Route path="teachersUpdate" element={<TeachersUpdate />} />
            <Route path="schoolYearsMain" element={<SchoolYearsMain />} />
            <Route path="schoolYearsAdd" element={<SchoolYearsAdd />} />
            <Route path="schoolYearsList" element={<SchoolYearsList />} />
            <Route path="schoolYearsView" element={<SchoolYearsView />} />
            <Route path="schoolYearsUpdate" element={<SchoolYearsUpdate />} />
            <Route path="locationsAdd" element={<LocationsAdd />} />
            <Route path="locationsList" element={<LocationsList />} />
            <Route path="locationsView" element={<LocationsView />} />
            <Route path="classroomsAdd" element={<ClassroomsAdd />} />
            <Route path="classroomsList" element={<ClassroomsList />} />
            <Route path="classroomsView" element={<ClassroomsView />} />
            <Route path="schoolClassesAdd" element={<SchoolClassesAdd />} />
            <Route path="schoolClassesAdd" element={<SchoolClassesAdd />} />
            <Route path="schoolClassesList" element={<SchoolClassesList />} />
            <Route path="schoolClassesView" element={<SchoolClassesView />} />
            <Route path="studentsMain" element={<StudentsMain />} />
            <Route path="studentsList" element={<StudentsList />} />
            <Route path="studentsAdd" element={<StudentsAdd />} />
            <Route path="studentsView" element={<StudentsView />} />
            <Route
              path="studentsClassChange"
              element={<StudentsClassChange />}
            />
            <Route
              path="studentsClassChangeView"
              element={<StudentsClassChangeView />}
            />

            <Route path="admissionsView" element={<AdmissionsView />} />
            <Route
              path="parentRegistrationHistory"
              element={<ParentRegistrationHistory />}
            />
            <Route path="parentsMain" element={<ParentsMain />} />
            <Route path="parentsView" element={<ParentsView />} />
            <Route path="parentsAdd" element={<ParentsAdd />} />
            <Route path="parentsList" element={<ParentsList />} />
            <Route path="childrenList" element={<ChildrenList />} />
            <Route path="childrenRegister" element={<ChildrenRegister />} />
            <Route path="studentsRegister" element={<StudentsRegister />} />
            <Route path="studentsByClass" element={<StudentsByClass />} />
            <Route path="importsExcel" element={<ImportsExcel />} />

            <Route path="admissionsAdd" element={<AdmissionsAdd />} />
            <Route path="admissionsList" element={<AdmissionsList />} />
            <Route path="admissionsView" element={<AdmissionsView />} />
            <Route
              path="admissionRegistersList"
              element={<AdmissionRegistersList />}
            />
            <Route
              path="admissionRegistersApplications"
              element={<AdmissionRegistersApplications />}
            />
            <Route
              path="admissionRegistersStudentRegister"
              element={<AdmissionRegistersStudentRegister />}
            />
            <Route
              path="admissionRegistersView"
              element={<AdmissionRegistersView />}
            />
            <Route
              path="admissionsAddByClass"
              element={<AdmissionsAddByClass />}
            />

            <Route path="medicalTypesMain" element={<MedicalTypesMain />} />
            <Route path="medicalTypesAdd" element={<MedicalTypesAdd />} />
            <Route path="medicalTypesList" element={<MedicalTypesList />} />
            <Route path="medicalTypesView" element={<MedicalTypesView />} />
            <Route path="usersMain" element={<UsersMain />} />
            <Route
              path="usersAddParentSearch"
              element={<UsersAddParentSearch />}
            />
            <Route
              path="usersAddTeacherSearch"
              element={<UsersAddTeacherSearch />}
            />
            <Route
              path="usersAddParentSearchView"
              element={<UsersAddParentSearchView />}
            />
            <Route
              path="usersAddTeacherSearchView"
              element={<UsersAddTeacherSearchView />}
            />
            <Route
              path="usersAddStudentSearch"
              element={<UsersAddStudentSearch />}
            />
            <Route
              path="usersAddStudentSearchView"
              element={<UsersAddStudentSearchView />}
            />
            <Route path="userTeachersMain" element={<UserTeachersMain />} />
            <Route path="userTeachersList" element={<UserTeachersList />} />
            <Route path="schoolsAddAdmin" element={<SchoolsAddAdmin />} />
            <Route path="schoolsMainAdmin" element={<SchoolsMainAdmin />} />
            <Route path="schoolsListAdmin" element={<SchoolsListAdmin />} />
            <Route path="schoolsViewAdmin" element={<SchoolsViewAdmin />} />
            <Route path="schoolsUpdateAdmin" element={<SchoolsUpdateAdmin />} />
            <Route
              path="schoolsUserAddSearchAdmin"
              element={<SchoolsUsersAddSearchAdmin />}
            />
            <Route
              path="schoolsUserAddSearchViewAdmin"
              element={<SchoolsUserAddSearchViewAdmin />}
            />
            <Route
              path="educationalRecordsMain"
              element={<EducationalRecordsMain />}
            />
            <Route path="registrationsMain" element={<RegistrationsMain />} />
            <Route
              path="registrationsRegisterSearch"
              element={<RegistrationsRegisterSearch />}
            />
            <Route
              path="registrationsRegisterSearchView"
              element={<RegistrationsRegisterSearchView />}
            />
            <Route
              path="registrationsListSearch"
              element={<RegistrationsListSearch />}
            />
            <Route
              path="registrationsListSearchView"
              element={<RegistrationsListSearchView />}
            />

            <Route path="activityLogsMain" element={<ActivityLogsMain />} />
            <Route path="activityLogsList" element={<ActivityLogsList />} />
            <Route path="activityLogsSearch" element={<ActivityLogsSearch />} />
            <Route
              path="schoolClassesDescriptionAddSearch"
              element={<SchoolClassesDescriptionAddSearch />}
            />
            <Route
              path="schoolClassesDescriptionAddSearchView"
              element={<SchoolClassesDescriptionAddSearchView />}
            />
            <Route
              path="schoolClassesDescriptionList"
              element={<SchoolClassesDescriptionList />}
            />
            <Route
              path="welcomeHomeSchoolAdmin"
              element={<WelcomeHomeSchoolAdmin />}
            />
            <Route path="eduxooSystemMain" element={<EduxooSystemMain />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
