import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { useGetSchoolClassesBySchoolYearIdAndTeacherIdQuery } from "../schoolclasses/apiSchoolClassesSlice";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import {
  useAddUserTeacherMutation,
  useGetAuthorizedSchoolsByUserQuery,
  useGetPersonQuery,
} from "./apiUsersSlice";

export const UsersAddTeacherSearchView = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [
    addUser,
    { isLoading, isSuccess, isError, error },
  ] = useAddUserTeacherMutation();

  const [userAddForm, setUserAddForm] = useState({
    username: "",
    password: "",
    schoolId: "",
    schoolYearId: "",
    schoolClassId: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
    errorCount: 0,
    isValid: true,
  });

  const {
    data: teacher,
    isLoading: isLoadingTeacher,
    isSuccess: isSuccessTeacher,
    isError: isErrorTeacher,
    error: errorTeacher,
  } = useGetPersonQuery(location.state.entityUrl);

  let content;

  if (isLoadingTeacher) {
    content = <EduxooBackdrop isLoading={isLoadingTeacher} />;
  } else if (isSuccessTeacher) {
    let elementsList = teacher
      ? {
          title: "Person Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                {
                  textName: "First Name",
                  textValue: teacher.person.firstName,
                },
                {
                  textName: "Last Name",
                  textValue: teacher.person.lastName,
                },
                {
                  textName: "Email(Personal)",
                  textValue: teacher.person.emailPersonal,
                },
                {
                  textName: "Email(Work)",
                  textValue: teacher.emailWork,
                },
              ],
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isErrorTeacher) {
    content = <div>{errorTeacher.toString}</div>;
  }

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();
  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem key={school.id} value={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const {
    data: schoolYears,
    isLoading: isLoadingSchoolYears,
    isSuccess: isSuccessSchoolYears,
    isError: isErrorSchoolYears,
    error: errorSchoolYears,
  } = useGetSchoolYearsBySchoolQuery(
    { school: userAddForm.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: userAddForm.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList;

  if (isLoadingSchoolYears) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolYears} />;
  } else if (isSuccessSchoolYears) {
    schoolYearsSelectList = schoolYears?._embedded?.schoolYears.map(
      (schoolYear) => {
        return (
          <MenuItem key={schoolYear.id} value={schoolYear.id}>
            {schoolYear.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolYears) {
    content = <div>{errorSchoolYears.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearIdAndTeacherIdQuery(
    {
      schoolYearId: userAddForm.schoolYearId,
      teacherId: teacher?.id,
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      skip: userAddForm.schoolYearId ? false : true,
    }
  );

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let username = "";
    let password = "";

    if (userAddForm.username.trim() === "") {
      username = "Please enter a username";
      isValid = false;
      errorCount++;
    }

    if (userAddForm.password.trim() === "") {
      password = "Please enter a password";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        username,
        password,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      username: "",
      password: "",
    });
  };

  const onUsersAddClicked = async (id) => {
    try {
      console.log("userAddForm", userAddForm);
      console.log("personId", id);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await addUser({
          personId: id,
          schoolClassId: userAddForm.schoolClassId,
          username: userAddForm.username,
          password: userAddForm.password,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
      isValidEntry = true;
    } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
      isValidEntry = true;
    }*/

    if (isValidEntry) {
      setUserAddForm({
        ...userAddForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {};

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"User created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      {message}
      {content}
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="school-select">School</InputLabel>
              <Select
                labelId="school-select"
                id="schoolId"
                name="schoolId"
                value={userAddForm.schoolId}
                label="School"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a School</MenuItem>
                {schoolsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="schoolyear-select">School Year</InputLabel>
              <Select
                labelId="schoolyear-select"
                id="schoolYearId"
                name="schoolYearId"
                value={userAddForm.schoolYearId}
                label="School Year"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Year</MenuItem>
                {schoolYearsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="schoolclass-select">School Class</InputLabel>
              <Select
                labelId="schoolclass-select"
                id="schoolClassId"
                name="schoolClassId"
                value={userAddForm.schoolClassId}
                label="School Year"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Class</MenuItem>
                {schoolClassesSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="username"
            name="username"
            label="Username"
            margin="dense"
            value={userAddForm.username}
            onChange={handleChange}
            error={validationErrors.username !== ""}
            helperText={validationErrors.username}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="password"
            name="password"
            label="Password"
            margin="dense"
            value={userAddForm.password}
            onChange={handleChange}
            error={validationErrors.password !== ""}
            helperText={validationErrors.password}
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton
            handleSubmit={() => onUsersAddClicked(teacher.person.id)}
          />
        </Box>
      </Container>
    </>
  );
};

export default UsersAddTeacherSearchView;
