import { apiSlice } from "../api/apiSlice";

export const apiRegistrationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query({
      query: (customPageable) =>
        `/schools?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["School"],
    }),
    register: builder.mutation({
      query: (registerationForm) => ({
        url: `/admissions/${registerationForm.admissionId}/admissionregisters/register`,
        method: "POST",
        body: registerationForm,
      }),
      invalidatesTags: ["Registration"],
    }),
    searchByStatus: builder.query({
      query: (searchQuery) =>
        `/admissionregisters/search?personId=&status=${searchQuery.status}&size=${searchQuery.rowsPerPage}&page=${searchQuery.page}`,
      providesTags: ["AdmissionRegister"],
    }),
  }),
});

export const {
  useLazyGetSchoolsQuery,
  useRegisterMutation,
  useLazySearchByStatusQuery,
} = apiRegistrationsSlice;
