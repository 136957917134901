import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuthenticateMutation } from "./apiAuthenticationSlice";

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./apiAuthenticationSlice";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";

import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
  reForNames,
  reAlphabetic,
  strongRegex,
} from "../../components/common/FormValueValidators";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const SignIn = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [open, setOpen] = useState(false);

  const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
    isValid: true,
    errorCount: 0,
  });

  const validateForm = () => {
    let isValid = true;

    let usernameLoc = "";
    let passwordLoc = "";
    let errorCount = 0;
    // || !reEmail.test(username)) {
    if (username.trim() === "") {
      usernameLoc = "Please enter a valid Username";
      isValid = false;
      errorCount++;
    }

    if (password.trim() === "") {
      passwordLoc = "Please enter a Password";
      isValid = false;
      errorCount++;
    }

    setValidationErrors({
      ...validationErrors,
      username: usernameLoc,
      password: passwordLoc,
      errorCount,
      isValid,
    });

    return isValid;
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userInfo = {
      username,
      password,
    };
    try {
      if (validateForm()) {
        setOpen(true);
        const userData = await login(userInfo).unwrap();

        dispatch(setCredentials({ ...userData, user: username }));

        navigate("/welcome");
        window.scroll(0, 0);
      }
    } catch (err) {
      console.error("Failed to signin: ", err);

      if (!err?.originalStatus) {
        setErrMsg("No Server Response");
      } else if (err.originalStatus?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        console.log("login failed");
        setErrMsg("Login failed");
      }
    }
  };

  let message;
  if (isError) {
    message = <EduxooErrorBox error={error} setOpen={setOpen} open={open} />;
  }

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  let isLoadingContent;
  if (isLoading) {
    isLoadingContent = <EduxooBackdrop isLoading={isLoading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {message}
        {isLoadingContent}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="username"
              value={username}
              onChange={handleUsernameChange}
              error={validationErrors.username !== ""}
              helperText={validationErrors.username}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
              error={validationErrors.password !== ""}
              helperText={validationErrors.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
};

export default SignIn;
