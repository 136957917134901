import { useImportsExcelMutation } from "./apiImportsSlice";
import * as xlsx from "xlsx";

export const ImportsExcel = () => {
  const [importExcel, { isLoading }] = useImportsExcelMutation();

  const handleFileSelect = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];

    const reader = new FileReader();
    let constPupilData = {};
    reader.onload = (e) => {
      var data = e.target.result;
      let readedData = xlsx.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = xlsx.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
      });
      console.log("dataParse", dataParse);

      let pupilData = { items: [] };
      for (let i = 0; i < dataParse.length; i++) {
        pupilData.items.push(dataParse[i]);
        console.log("");
      }
      constPupilData = pupilData;
    };

    reader.onloadend = (e) => {
      importExcel(JSON.stringify(constPupilData));
      console.log("onloadend");
    };

    reader.readAsBinaryString(f);
  };

  return (
    <div>
      <div>
        <form>
          <input id="files_input" type="file" onChange={handleFileSelect} />
        </form>
      </div>
    </div>
  );
};

export default ImportsExcel;
