import { apiSlice } from "../../../api/apiSlice";

export const apiActivityLogsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivityLogs: builder.query({
      query: (pageInfo) =>
        `/activitylogs?size=${pageInfo.rowsPerPage}&page=${pageInfo.page}`,
      providesTags: ["ActivityLog"],
    }),
    getActivityLogsWithParams: builder.query({
      query: (activityLogQuery) =>
        `/activitylogs/search?username=${activityLogQuery.username}&size=${activityLogQuery.rowsPerPage}&page=${activityLogQuery.page}`,
      providesTags: ["ActivityLog"],
    }),
  }),
});

export const {
  useGetActivityLogsQuery,
  useLazyGetActivityLogsWithParamsQuery,
} = apiActivityLogsSlice;
