import { styled } from "@mui/material/styles";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const AdmissionRegisterLogsView = ({ admissionRegisterLogs }) => {
  const entityHeaderList = [
    "Id",
    "Log Date",
    "Created By",
    "Created Date",
    "Prev Status",
    "Status",
    "Note",
  ];

  const entityValues = admissionRegisterLogs.map((admissionRegisterLog) => {
    let id = admissionRegisterLog.id;
    let logDate = dayjs(admissionRegisterLog.logDate).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    let createdBy = admissionRegisterLog.createdBy;
    let createdDate = dayjs(admissionRegisterLog.createdDate).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    let prevStatus = admissionRegisterLog.prevStatus;
    let status = admissionRegisterLog.status;
    let note = admissionRegisterLog.note;

    return {
      values: [id, logDate, createdBy, createdDate, prevStatus, status, note],
    };
  });
  let customCounter = 0;

  return (
    <Container maxWidth="md" style={{ marginTop: 10 }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {entityHeaderList?.map((entityHeader) => {
                return (
                  <StyledTableCell align="left" key={entityHeader}>
                    {entityHeader}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {entityValues?.map((entity) => {
              customCounter += 1;
              return (
                <StyledTableRow key={`rowie_${customCounter}`}>
                  {entity?.values?.map((cellValue) => {
                    customCounter += 1;
                    return (
                      <StyledTableCell key={`columnie_${customCounter}`}>
                        {cellValue}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdmissionRegisterLogsView;
