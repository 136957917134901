import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { useGetAdmissionsByParentQuery } from "./apiParentsUserSlice";

export const AdmissionRegistersListForParent = ({
  searchQuery,
  setIsSearch,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  console.log("searchQuery: ", searchQuery);
  const {
    data: admissionRegisters,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdmissionsByParentQuery(
    { searchQuery, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Admission Name",
    "School",
    "School Class",
    "Status",
    "Application Date",
    "Completion Date",
  ];

  const entityValues = admissionRegisters?._embedded?.admissionRegisters.map(
    (admissionRegister) => {
      //let id = admission.id;
      let admissionName = admissionRegister.admissionName;
      let school = admissionRegister.schoolName;
      let schoolClass = admissionRegister.schoolClassName;
      let applicationDate = admissionRegister.applicationDate;
      let completionDate = admissionRegister.completionDate;
      let status = admissionRegister.status;
      return {
        values: [
          admissionName,
          school,
          schoolClass,
          status,
          applicationDate,
          completionDate,
        ],
        links: admissionRegister._links.self.href,
      };
    }
  );

  let content;

  let pageInfo = {
    pageInfo: admissionRegisters?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 5,
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/admissionsView" },
            { type: "Update", to: "/admissionsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
};

export default AdmissionRegistersListForParent;
