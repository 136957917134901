import { apiSlice } from "../api/apiSlice";

export const apiParentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addParent: builder.mutation({
      query: (newParent) => ({
        url: "/relationships",
        method: "POST",
        body: newParent,
      }),
      invalidatesTags: ["Parent"],
    }),
    getParents: builder.query({
      query: (customPageable) =>
        `/relationships?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Parent"],
    }),
    getParentsBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/parents?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Parent"],
    }),

    getParent: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Parent"],
    }),
  }),
});

export const {
  useGetParentQuery,
  useGetParentsQuery,
  useGetParentsBySchoolQuery,
  useAddParentMutation,
} = apiParentsSlice;
