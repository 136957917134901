import { apiSlice } from "../api/apiSlice";

export const apiSchoolsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query({
      query: (customPageable) =>
        `/schools?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["School"],
    }),
    addNewSchool: builder.mutation({
      query: (initialSchool) => ({
        url: "/schools",
        method: "POST",
        body: initialSchool,
      }),
      invalidatesTags: ["School"],
    }),
    updateSchool: builder.mutation({
      query: (schoolInfo) => ({
        url: `/schools/${schoolInfo.id}`,
        method: "PUT",
        body: schoolInfo,
      }),
      invalidatesTags: ["School"],
    }),
    getAllSchools: builder.query({
      query: () => `/schools?size=5&sort=email,desc`,
      providesTags: ["School"],
    }),
    getSchool: builder.query({
      query: (schoolUrl) => `${schoolUrl}`,
      providesTags: ["School"],
    }),
  }),
});

export const selectSchool = (state) => state.school;

export const {
  useGetSchoolsQuery,
  useAddNewSchoolMutation,
  useUpdateSchoolMutation,
  useGetAllSchoolsQuery,
  useGetSchoolQuery,
} = apiSchoolsSlice;
