import {
  Box,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import {
  EduxooBackButton,
  EduxooUpdateButton,
} from "../../components/common/EduxooButton";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetSchoolClassesBySchoolYearQuery } from "../schoolclasses/apiSchoolClassesSlice";
import {
  useGetAdmissionRegisterQuery,
  useUpdateAdmissionRegisterForRegisterMutation,
} from "./apiAdmissionRegistersSlice";

export const AdmissionRegistersStudentRegister = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    status: "",
    schoolClassId: "",
    schoolId: "",
    startDate: "",
    endDate: "",
    endDateNotGreaterThan: "",
    errorCount: 0,
    isValid: true,
  });

  const [admissionRegistersForm, setAdmissionRegistersForm] = useState({
    status: "",
    schoolClassId: "",
    note: "",
    childId: "",
    admissionId: "",
  });

  const [
    updateAdmissionRegister,
    { isLoading, isSuccess, isError, error },
  ] = useUpdateAdmissionRegisterForRegisterMutation();

  const resetForm = () => {
    setAdmissionRegistersForm({
      status: "",
      schoolClassId: "",
      note: "",
    });
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Admission Register Updated Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const {
    data: admissionRegister,
    isLoading: isLoadingAdmReg,
    isSuccess: isSuccessAdmReg,
    isError: isErrorAdmReg,
    error: errorAdmReg,
  } = useGetAdmissionRegisterQuery(location.state.entityUrl);

  if (isLoadingAdmReg) {
    content = <EduxooBackdrop isLoading={isLoadingAdmReg} />;
  } else if (isSuccessAdmReg) {
    content = "";
  } else if (isErrorAdmReg) {
    content = <div>{errorAdmReg.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(admissionRegister?.schoolYearId, {
    skip: admissionRegister?.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const handleChange = (e) => {
    setAdmissionRegistersForm({
      ...admissionRegistersForm,
      [e.target.name]: e.target.value,
    });
  };

  const onUpdateClicked = async () => {
    try {
      if (validateForm()) {
        setOpen(true);
        resetValidationErrors();
        await updateAdmissionRegister({
          id: admissionRegister.id,
          status: admissionRegistersForm.status,
          schoolClassId: admissionRegistersForm.schoolClassId,
          note: admissionRegistersForm.note,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolId: "",
      schoolYearId: "",
      name: "",
      startDate: "",
      endDate: "",
      endDateNotGreaterThan: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  return (
    <>
      <CssBaseline />
      {content}
      {admissionRegister ? (
        <Container maxWidth="sm" style={{ marginTop: 10 }}>
          <Paper
            sx={{
              p: 1,
              margin: "auto",
              maxWidth: "md",
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <TextField
              disabled
              fullWidth
              id="admissionName"
              name="admissionName"
              label="Admission Name"
              margin="dense"
              value={admissionRegister.admissionName}
            />
            <TextField
              fullWidth
              id="studentName"
              name="studentName"
              label="Student Name"
              margin="dense"
              value={admissionRegister.personName}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              id="applicationDate"
              name="applicationDate"
              label="Application Date"
              margin="dense"
              value={admissionRegister.applicationDate}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              id="appliedBy"
              name="appliedBy"
              label="Applied By"
              margin="dense"
              value={admissionRegister.createdBy}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              id="prevNote"
              name="prevNote"
              label="Note"
              margin="dense"
              multiline
              maxRows={2}
              value={admissionRegister.note}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              id="prevStatus"
              name="prevStatus"
              label="Status"
              margin="dense"
              value={admissionRegister.status}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              fullWidth
              id="schoolYear"
              name="schoolYear"
              label="School Year"
              margin="dense"
              value={admissionRegister.schoolYearName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Paper>
          <Divider>Please, select values from below </Divider>
          <Paper
            sx={{
              p: 1,
              margin: "auto",
              maxWidth: "md",
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="application-status-select">Status</InputLabel>
                <Select
                  labelId="application-status-select"
                  id="status"
                  name="status"
                  value={admissionRegistersForm.status}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>Please Select a Status</MenuItem>;
                  <MenuItem value={"STARTED"}>STARTED</MenuItem>;
                  <MenuItem value={"PROCESSING"}>PROCESSING</MenuItem>;
                  <MenuItem value={"WAITING"}>WAITING</MenuItem>;
                  <MenuItem value={"COMPLETED"}>COMPLETED</MenuItem>;
                  <MenuItem value={"NOT_COMPLETED"}>NOT_COMPLETED</MenuItem>;
                  <MenuItem value={"REJECTED"}>REJECTED</MenuItem>;
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120, marginTop: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="schoolclass-select">School Class</InputLabel>
                <Select
                  labelId="schoolclass-select"
                  id="schoolClassId"
                  name="schoolClassId"
                  value={admissionRegistersForm.schoolClassId}
                  label="School Year"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>Please Select a Class</MenuItem>
                  {schoolClassesSelectList}
                </Select>
              </FormControl>
            </Box>
            <TextField
              fullWidth
              id="note"
              name="note"
              label="Note"
              margin="dense"
              multiline
              maxRows={2}
              value={admissionRegistersForm.note}
              onChange={handleChange}
            />
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <EduxooUpdateButton handleSubmit={onUpdateClicked} />
          </Box>
        </Container>
      ) : (
        <div>Something Wrong</div>
      )}
    </>
  );
};

export default AdmissionRegistersStudentRegister;
