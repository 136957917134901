import { EduxooMainMenu } from "../../../../components/common/EduxooMainMenu";

export default function MedicalTypesMain() {
  const tiers = [
    [
      {
        title: "Medical Types",
        id: "MedicalTypeCreate",
        subheader: "Create",
        description: ["You can add a new Medical Type by using this form"],
        buttonText: "Add a new Medical Type",
        buttonVariant: "contained",
        to: "/medicalTypesAdd",
      },
      {
        title: "Medical Types",
        id: "MedicalTypeView",
        subheader: "View or Update",
        description: ["You can view and update by using this form"],
        buttonText: "View all your Medical Types",
        buttonVariant: "outlined",
        to: "/medicalTypesList",
      },
    ],
  ];
  return <EduxooMainMenu tiers={tiers} />;
}
