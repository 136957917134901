import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetTeachersBySchoolQuery } from "./apiTeachersSlice";

export const TeachersList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);
  const {
    data: teachers,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTeachersBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["First Name", "Last Name", "School Name"];

  const entityValues = teachers?._embedded?.teachers.map((teacher) => {
    let firstName = teacher.person.firstName;
    let lastName = teacher.person.lastName;
    let schoolName = teacher.school.name;
    return {
      values: [firstName, lastName, schoolName],
      links: teacher._links.self.href,
    };
  });

  const pageInfo = {
    pageInfo: teachers?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 3,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/teachersView" },
            { type: "Update", to: "/teachersUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default TeachersList;
