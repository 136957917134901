import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
  reForNames,
  reAlphabetic,
} from "../../components/common/FormValueValidators";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { useGetSchoolsQuery } from "../schools/apiSchoolsSlice";
import {
  useGetChildrenByParentQuery,
  useRegisterChildrenMutation,
} from "./apiParentsUserSlice";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useGetAdmissionsBySchoolQuery } from "../admissions/apiAdmissionsSlice";

export const ChildrenRegister = () => {
  const [
    registerChildren,
    { isLoading, isSuccess, isError, error },
  ] = useRegisterChildrenMutation();

  const [open, setOpen] = useState(false);

  const [childrenRegisterForm, setChildrenRegisterForm] = useState({
    schoolId: "",
    admissionId: "",
    personId: "",
    note: "",
  });

  const resetForm = () => {
    setChildrenRegisterForm({
      schoolId: "",
      admissionId: "",
      personId: "",
      note: "",
    });
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Child is Registered Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} setOpen={setOpen} open={open} />;
  }

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetSchoolsQuery({ rowsPerPage: ROWS_PER_PAGE, page: 0 });

  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const {
    data: children,
    isLoading: isLoadingChildren,
    isSuccess: isSuccessChildren,
    isError: isErrorChildren,
    error: errorChildren,
  } = useGetChildrenByParentQuery(
    {
      parent: "550f02b6-b1c6-42f6-8dac-fe95a2dd4a6b",
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  let childrenSelectList;

  if (isLoadingChildren) {
    content = <EduxooBackdrop isLoading={isLoadingChildren} />;
  } else if (isSuccessChildren) {
    childrenSelectList = children?._embedded?.relationships.map((relation) => {
      return (
        <MenuItem
          value={relation.personRelatedId}
          key={relation.personRelatedId}
        >
          {relation.personRelatedFirstName +
            " " +
            relation.personRelatedLastName}
        </MenuItem>
      );
    });
  } else if (isErrorChildren) {
    content = <div>{errorChildren.toString}</div>;
  }

  const {
    data: admissions,
    isLoading: isLoadingAdm,
    isSuccess: isSuccessAdm,
    isError: isErrorAdm,
    error: errorAdm,
  } = useGetAdmissionsBySchoolQuery(
    {
      school: childrenRegisterForm.schoolId,
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      skip: childrenRegisterForm.schoolId ? false : true,
    }
  );

  let admissionsSelectList;

  if (isLoadingAdm) {
    content = <EduxooBackdrop isLoading={isLoadingAdm} />;
  } else if (isSuccessAdm) {
    admissionsSelectList = admissions?._embedded?.admissions.map(
      (admission) => {
        return (
          <MenuItem value={admission.id} key={admission.id}>
            {admission.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorAdm) {
    content = <div>{errorAdm.toString}</div>;
  }

  const [validationErrors, setValidationErrors] = useState({
    schoolId: "",
    admissionId: "",
    childId: "",

    isValid: true,
    errorCount: 0,
  });

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setChildrenRegisterForm({
        ...childrenRegisterForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onRegisterChildrenClicked = async () => {
    try {
      if (validateForm()) {
        setOpen(true);
        resetValidationErrors();
        await registerChildren(childrenRegisterForm);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save register the child: ", err);
    }
  };

  const validateForm = () => {
    let isValid = true;

    let childId = "";
    let schoolId = "";
    let admissionId = "";

    let errorCount = 0;

    if (childrenRegisterForm.schoolId.trim() === "") {
      schoolId = "Please, select a School";
      isValid = false;
      errorCount++;
    }

    if (childrenRegisterForm.personId.trim() === "") {
      childId = "Please, select a Child";
      isValid = false;
      errorCount++;

      if (childrenRegisterForm.admissionId.trim() === "") {
        admissionId = "Please, select an Admission";
        isValid = false;
        errorCount++;
      }
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        childId,
        schoolId,
        admissionId,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      childId: "",
      schoolId: "",
      admissionId: "",
      isValid: true,
      errorCount: 0,
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Register Child
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.childId !== ""}
                  required
                >
                  <InputLabel id="child-select">Child</InputLabel>
                  <Select
                    labelId="child-select"
                    id="personId"
                    name="personId"
                    value={childrenRegisterForm.personId}
                    label="Child"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Child</MenuItem>;
                    {childrenSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.childId}</FormHelperText>
                </FormControl>
              </Box>
            </div>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.schoolId !== ""}
                  required
                >
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={childrenRegisterForm.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.schoolId}</FormHelperText>
                </FormControl>
              </Box>
            </div>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.admissionId !== ""}
                  required
                >
                  <InputLabel id="admission-select">Admission</InputLabel>
                  <Select
                    labelId="admission-select"
                    id="admissionId"
                    name="admissionId"
                    value={childrenRegisterForm.admissionId}
                    label="Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select an Admission</MenuItem>
                    {admissionsSelectList}
                  </Select>
                  <FormHelperText>
                    {validationErrors.admissionId}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>

            <div>
              <TextField
                fullWidth
                id="note"
                name="note"
                label="Note"
                variant="outlined"
                value={childrenRegisterForm.note}
                onChange={handleChange}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onRegisterChildrenClicked} />
        </Box>
      </Container>
    </>
  );
};

export default ChildrenRegister;
