import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import {
  EduxooGetAllButton,
  EduxooSearchButton,
} from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { useLazySearchByStatusQuery } from "./apiRegistrationsSlice";

export const RegistrationsListSearch = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    schoolId: "",
    admissionId: "",
    childId: "",
    status: "",
  });

  const [
    searchByStatus,
    { data: admissionRegisters, isLoading, isSuccess, isError, error },
  ] = useLazySearchByStatusQuery();

  let message;

  const validateForm = () => {
    return true;
  };

  const resetValidationErrors = () => {};

  const resetForm = () => {};

  const handleChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };

  const onSearchClicked = async () => {
    try {
      await searchByStatus({
        status: searchQuery.status,
        rowsPerPage: ROWS_PER_PAGE,
        page: 0,
      });
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleAdmissionRegisterSelectClicked = ({ e, entityId, entityUrl }) => {
    e.preventDefault();
    navigate("/registrationsListSearchView", {
      state: {
        entityId: entityId,
        entityUrl: entityUrl,
      },
    });
  };

  const onGetAllClicked = async () => {
    try {
      setOpen(true);

      resetValidationErrors();
      resetForm();
      await searchByStatus({
        status: "Na",
        rowsPerPage: ROWS_PER_PAGE,
        page: 0,
      });
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Registered Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Container component="main" maxWidth="sm">
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Search For Admission Registers
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="application-status-select">Status</InputLabel>
                  <Select
                    labelId="application-status-select"
                    id="status"
                    name="status"
                    value={searchQuery.status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={"Na"}>Please Select a Status</MenuItem>;
                    <MenuItem value={"STARTED"}>STARTED</MenuItem>;
                    <MenuItem value={"PROCESSING"}>PROCESSING</MenuItem>;
                    <MenuItem value={"WAITING"}>WAITING</MenuItem>;
                    <MenuItem value={"COMPLETED"}>COMPLETED</MenuItem>;
                    <MenuItem value={"NOT_COMPLETED"}>NOT_COMPLETED</MenuItem>;
                    <MenuItem value={"REJECTED"}>REJECTED</MenuItem>;
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
          <EduxooSearchButton handleSubmit={onSearchClicked} />
          <EduxooGetAllButton handleSubmit={onGetAllClicked} />
        </Box>

        <Container sx={{ marginTop: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>School</TableCell>
                  <TableCell>Admission</TableCell>
                  <TableCell>Person</TableCell>
                  <TableCell>Application Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admissionRegisters?._embedded?.admissionRegisters?.map(
                  (admissionRegister) => {
                    return (
                      <TableRow key={admissionRegister.id}>
                        <TableCell align="left">
                          {admissionRegister.schoolName}
                        </TableCell>
                        <TableCell align="left">
                          {admissionRegister.admissionName}
                        </TableCell>
                        <TableCell align="left">
                          {admissionRegister.personName}
                        </TableCell>
                        <TableCell align="left">
                          {dayjs(admissionRegister.applicationDate).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {admissionRegister.status}
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                            //TODO className={classes.button}
                            onClick={(e) =>
                              handleAdmissionRegisterSelectClicked({
                                e,
                                entityId: admissionRegister.id,
                                entityUrl: admissionRegister._links.self.href,
                              })
                            }
                          >
                            SELECT
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
    </>
  );
};

export default RegistrationsListSearch;
