import { useGetSchoolQuery } from "./apiSchoolsSlice";

export default function SchoolsDiscard() {
  const { data: schoolee } = useGetSchoolQuery(
    {
      schoolUrl:
        "http://localhost:8080/schools/b17c1f6b-2b4d-4ae0-afe6-45ff9fcd1cdc",
    },
    { refetchOnMountOrArgChange: true }
  );

  return <div>{schoolee.name}</div>;
}
