import { apiSlice } from "../api/apiSlice";

export const apiAuthenticationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/authenticate",
        method: "POST",
        body: JSON.stringify(credentials),
      }),
    }),
  }),
});

export const { useLoginMutation } = apiAuthenticationSlice;
