import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetAllDescriptionsBySchoolQuery } from "./apiSchoolClassDescriptionsSlice";

export const SchoolClassesDescriptionList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);
  const {
    data: schoolClassDescriptions,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllDescriptionsBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["School", "Class", "Description"];

  const entityValues = schoolClassDescriptions?._embedded?.schoolClassDescriptions.map(
    (schoolClassDescription) => {
      let schoolName = schoolClassDescription.schoolName;
      let schoolClassName = schoolClassDescription.schoolClassName;
      let description = schoolClassDescription.description;
      return {
        values: [schoolName, schoolClassName, description],
        links: schoolClassDescription._links.self.href,
      };
    }
  );

  const pageInfo = {
    pageInfo: schoolClassDescriptions?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 3,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/teachersView" },
            { type: "Update", to: "/teachersUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default SchoolClassesDescriptionList;
