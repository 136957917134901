import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetTeacherQuery } from "./apiTeachersSlice";

export default function TeachersView() {
  const location = useLocation();
  const {
    data: teacher,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTeacherQuery(location.state.entityUrl);

  let elementsList = teacher
    ? {
        title: "Teacher Information",

        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "First Name", textValue: teacher.person.firstName },
              { textName: "Last Name", textValue: teacher.person.lastName },
              { textName: "School", textValue: teacher.school.name },
            ],
          },
        ],
      }
    : [];

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
}
