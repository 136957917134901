import { CssBaseline } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import {
  useGetAdmissionRegistersByAdmissionQuery,
  useGetAdmissionRegistersQuery,
} from "./apiAdmissionRegistersSlice";

export const AdmissionRegistersListSearchResult = ({ admissionId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: admissionRegisters,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdmissionRegistersQuery(
    { admissionId, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Admission Name",
    "School",
    "Student Name",
    "Application Date",
    "Status",
  ];

  const entityValues = admissionRegisters?._embedded?.admissionRegisters.map(
    (admissionRegister) => {
      //let id = admission.id;
      let admissionName = admissionRegister.admissionName;
      let school = admissionRegister.schoolName;
      let studentName = admissionRegister.personName;
      let applicationDate = dayjs(admissionRegister.applicationDate).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      let status = admissionRegister.status;
      return {
        values: [admissionName, school, studentName, applicationDate, status],
        links: admissionRegister._links.self.href,
      };
    }
  );

  let content;

  let pageInfo = {
    pageInfo: admissionRegisters?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 5,
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/admissionRegistersView" },
            { type: "Update", to: "/admissionRegistersUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
};

export default AdmissionRegistersListSearchResult;
