import { Container, CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetClassroomsBySchoolQuery } from "./apiClassroomsSlice";

export const ClassroomsList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);

  const {
    data: classrooms,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetClassroomsBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Name",
    "Location",
    "Level",
    "Min Capacity",
    "Max Capacity",
  ];

  const entityValues = classrooms?._embedded?.classrooms?.map((classroom) => {
    let name = classroom.name;
    let level = classroom.level;
    let minCapacity = classroom.minCapacity;
    let maxCapacity = classroom.maxCapacity;
    let location = classroom.locationName;
    return {
      values: [name, location, level, minCapacity, maxCapacity],
      links: classroom._links.self.href,
    };
  });

  let pageInfo = {
    pageInfo: classrooms?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 4,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/classroomsView" },
            { type: "Update", to: "/classroomsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {content}
      </Container>
    </>
  );
};

export default ClassroomsList;
