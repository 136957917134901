import { apiSlice } from "../api/apiSlice";

export const apiClassroomsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClassrooms: builder.query({
      query: (customPageable) =>
        `/classrooms?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Classroom"],
    }),
    getClassroom: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Classroom"],
    }),
    addNewClassroom: builder.mutation({
      query: (initialClassroom) => ({
        url: "/classrooms",
        method: "POST",
        body: initialClassroom,
      }),
      invalidatesTags: ["Classroom"],
    }),
    getClassroomsByLocation: builder.query({
      query: (queryInfo) =>
        `/locations/${queryInfo.location}/classrooms?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Classroom"],
    }),
    getClassroomsBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/classrooms?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Classroom"],
    }),
  }),
});

export const {
  useGetClassroomsQuery,
  useGetClassroomQuery,
  useAddNewClassroomMutation,
  useGetClassroomsByLocationQuery,
  useGetClassroomsBySchoolQuery,
} = apiClassroomsSlice;
