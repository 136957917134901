import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { selectCurrentSchool } from "../auths/authSlice";
import { useGetChildrenByParentQuery } from "./apiParentsUserSlice";
export const ChildrenList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSchool);

  const {
    data: children,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetChildrenByParentQuery(
    { parent: "550f02b6-b1c6-42f6-8dac-fe95a2dd4a6b", rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["First Name", "Last Name"];

  const entityValues = children?._embedded?.relationships?.map((child) => {
    let firstName = child.personRelatedFirstName;
    let lastName = child.personRelatedLastName;

    return {
      values: [firstName, lastName],
      links: child._links.self.href,
    };
  });

  let pageInfo = {
    pageInfo: children?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 4,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/childrenView" },
            { type: "Update", to: "/childrenUpdate" },
            { type: "Register", to: "/childrenRegister" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
};

export default ChildrenList;
