import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ErrorsSummaryBox } from "../../../../components/common/ErrorsSummaryBox";
import {
  EduxooGetAllButton,
  EduxooSearchButton,
} from "../../../../components/common/EduxooButton";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import { EduxooErrorBox } from "../../../../components/common/EduxooErrorBox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useLazyGetActivityLogsWithParamsQuery } from "./apiActivityLogsSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export const ActivityLogsSearch = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [activityLogQuery, setActivityLogQuery] = useState({
    user: "",
    startDate: null,
    endDate: null,
  });

  let content;

  const [
    activityLogsWithParamsQueryTrigger,
    { data: activityLogs, isLoading, isSuccess, isError, error },
  ] = useLazyGetActivityLogsWithParamsQuery();

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const [validationErrors, setValidationErrors] = useState({
    username: "",
    startDate: "",
    endDate: "",
    isValid: true,
    errorCount: 0,
  });

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setActivityLogQuery({
        ...activityLogQuery,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let username = "";
    let startDate = "";
    let endDate = "";
    let errorCount = 0;

    if (activityLogQuery.username.trim() === "") {
      username = "Please enter a username";
      isValid = false;
      errorCount++;
    }

    {
      /*if (activityLogQuery.startDate === null) {
      startDate = "Please enter a Start date";
      isValid = false;
      errorCount++;
    }

    if (activityLogQuery.endDate === null) {
      endDate = "Please enter an End date";
      isValid = false;
      errorCount++;
    }*/
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        username,
        startDate,
        endDate,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      username: "",
      lastName: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const resetForm = () => {
    setActivityLogQuery({
      schoolName: "",
    });
  };

  const onSearchClicked = async () => {
    try {
      setOpen(true);
      if (validateForm()) {
        resetValidationErrors();
        await activityLogsWithParamsQueryTrigger({
          username: activityLogQuery.username,
          rowsPerPage: 10,
          page: 0,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const onGetAllClicked = async () => {
    try {
      setOpen(true);

      resetValidationErrors();
      resetForm();
      await activityLogsWithParamsQueryTrigger({
        schoolName: "",
        rowsPerPage: 10,
        page: 0,
      });
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  const handleActivityLogClicked = ({ e, entityId, schoolId }) => {
    e.preventDefault();
    navigate("/schoolsUserAddSearchViewAdmin", {
      state: {
        entityId: entityId,
        schoolId: schoolId,
      },
    });
  };

  const handleStartDateChange = (newStartDate) => {
    setActivityLogQuery({
      ...activityLogQuery,
      startDate: dayjs(newStartDate).format("YYYY-MM-DD"),
    });
  };

  const handleEndDateChange = (newEndDate) => {
    setActivityLogQuery({
      ...activityLogQuery,
      endDateDate: dayjs(newEndDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Container component="main" maxWidth="sm">
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Search For Schools to Create a User
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="username"
                name="username"
                label="Userame"
                variant="outlined"
                value={activityLogQuery.username}
                onChange={handleChange}
                error={validationErrors.username !== ""}
                helperText={validationErrors.userame}
                margin="dense"
              />
            </div>

            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Start Date"
                    value={activityLogQuery.startDate}
                    format="DD-MM-YYYY"
                    onChange={(newStartDate) =>
                      handleStartDateChange(newStartDate)
                    }
                    slotProps={{
                      textField: {
                        required: true,
                        error: validationErrors.startDate !== "",
                        helperText: validationErrors.startDate,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="End Date"
                    value={activityLogQuery.endDate}
                    format="DD-MM-YYYY"
                    onChange={(newEndDate) => handleEndDateChange(newEndDate)}
                    slotProps={{
                      textField: {
                        required: true,
                        error: validationErrors.endDate !== "",
                        helperText: validationErrors.endDate,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
          <EduxooSearchButton handleSubmit={onSearchClicked} />
          <EduxooGetAllButton handleSubmit={onGetAllClicked} />
        </Box>

        <Container sx={{ marginTop: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>School</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityLogs?._embedded?.activityLogs?.map((activityLog) => {
                  return (
                    <TableRow key={activityLog.id}>
                      <TableCell align="left">
                        {activityLog.activityUser}
                      </TableCell>
                      <TableCell align="left">
                        {activityLog.loggedTime}
                      </TableCell>
                      <TableCell align="left">{activityLog.page}</TableCell>

                      <TableCell>
                        <Button
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                          //TODO className={classes.button}
                          onClick={(e) =>
                            handleActivityLogClicked({
                              e,
                              entityId: activityLog.personId,
                              schoolId: activityLog.schoolId,
                            })
                          }
                        >
                          SELECT
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
    </>
  );
};

export default ActivityLogsSearch;
