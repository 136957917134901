import { ContentCopyTwoTone } from "@mui/icons-material";
import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetSchoolYearQuery } from "./apiSchoolYearsSlice";

export default function SchoolYearsView() {
  const location = useLocation();
  const {
    data: schoolYear,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolYearQuery(location.state.entityUrl);
  const navigate = useNavigate();

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    let elementsList = schoolYear
      ? {
          title: "School Year Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                { textName: "Name", textValue: schoolYear.name },
                { textName: "Description", textValue: schoolYear.description },
                { textName: "Start Date", textValue: schoolYear.startDate },
                { textName: "End Date", textValue: schoolYear.endDate },
              ],
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
}
