import { apiSlice } from "../api/apiSlice";

export const apiSchoolYearsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolYears: builder.query({
      query: (customPageable) =>
        `/schoolyears?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["SchoolYear"],
    }),
    getSchoolYear: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["SchoolYear"],
    }),
    addNewSchoolYear: builder.mutation({
      query: (initialSchoolYear) => ({
        url: "/schoolyears",
        method: "POST",
        body: initialSchoolYear,
      }),
      invalidatesTags: ["SchoolYear"],
    }),
    getSchoolYearsBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/schoolyears?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["SchoolYear"],
    }),
    updateSchoolYear: builder.mutation({
      query: (updatedSchoolYear) => ({
        url: `/schoolyears/${updatedSchoolYear.id}`,
        method: "PUT",
        body: updatedSchoolYear,
      }),
      invalidatesTags: ["SchoolYear"],
    }),
  }),
});

export const {
  useGetSchoolYearQuery,
  useGetSchoolYearsQuery,
  useAddNewSchoolYearMutation,
  useGetSchoolYearsBySchoolQuery,
  useUpdateSchoolYearMutation,
} = apiSchoolYearsSlice;
