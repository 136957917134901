import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

export const TeachersMain = () => {
  const tiers = [
    [
      {
        title: "Teacher",
        id: "TeacherCreate",
        subheader: "Create",
        description: ["You can add a new Teacher by using this form"],
        buttonText: "Add a new Teacher",
        buttonVariant: "contained",
        to: "/teachersAdd",
      },
      {
        title: "Teacher",
        id: "TeacherView",
        subheader: "View or Update",
        description: ["You can view and update by using this form"],
        buttonText: "View all your teachers",
        buttonVariant: "outlined",
        to: "/teachersList",
      },
    ],
  ];
  return <EduxooMainMenu tiers={tiers} />;
};

export default TeachersMain;
