import { apiSlice } from "../api/apiSlice";

export const apiWelcomesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateWelcomesForUser: builder.mutation({
      query: () => ({ url: `/welcomes/generate`, method: "POST" }),
      invalidatesTags: ["Welcome"],
    }),
    getWelcomesByLoggedInUser: builder.query({
      query: () => `/welcomes/users`,
      providesTags: ["Welcome"],
    }),
  }),
});

export const {
  useGenerateWelcomesForUserMutation,
  useGetWelcomesByLoggedInUserQuery,
} = apiWelcomesSlice;
