import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../../features/auths/authSlice";

export const EduxooSchoolBar = () => {
  const school = useSelector(selectCurrentSelectedSchool);

  return <div align="center">{school?.name}</div>;
};

export default EduxooSchoolBar;
