import { Alert, AlertTitle, Container, Snackbar } from "@mui/material";

export const EduxooSuccessBox = ({ success, open, setOpen, resetForm }) => {
  const handleAlertClick = () => {
    resetForm();
    setOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleAlertClick}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClick} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EduxooSuccessBox;
