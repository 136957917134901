import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";

import { useGetParentQuery } from "./apiParentsSlice";

export const ParentsView = () => {
  const location = useLocation();
  const {
    data: parent,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetParentQuery(location.state.entityUrl);

  let elementsList = parent
    ? {
        title: "Parent Information",

        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "First Name", textValue: parent.personFirstName },
              { textName: "Last Name", textValue: parent.personLastName },
            ],
          },
        ],
      }
    : [];

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default ParentsView;
