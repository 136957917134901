import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import AdmissionRegisterLogsView from "../admissionregisters/AdmissionRegisterLogsView";
import { useGetAdmissionRegisterQuery } from "../admissionregisters/apiAdmissionRegistersSlice";

export const RegistrationsListSearchView = () => {
  const location = useLocation();
  const {
    data: admissionRegister,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdmissionRegisterQuery(location.state.entityUrl);

  let elementsList = admissionRegister
    ? {
        title: "Admission Register Information",
        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "Id", textValue: admissionRegister.id },
              { textName: "Name", textValue: admissionRegister.name },
              { textName: "School", textValue: admissionRegister.schoolName },
              {
                textName: "School Year",
                textValue: admissionRegister.schoolYearName,
              },
              {
                textName: "Start Date",
                textValue: admissionRegister.startDate,
              },
              { textName: "End Date", textValue: admissionRegister.endDate },
            ],
          },
        ],
      }
    : [];

  let content;
  let contentPlus;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
    contentPlus = (
      <AdmissionRegisterLogsView
        admissionRegisterLogs={admissionRegister.admissionRegisterLogs}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
      {contentPlus}
    </>
  );
};

export default RegistrationsListSearchView;
