import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useAddSchoolClassDescriptionMutation } from "./apiSchoolClassDescriptionsSlice";
import { useGetSchoolClassQuery } from "./apiSchoolClassesSlice";

export const SchoolClassesDescriptionAddSearchView = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const school = useSelector(selectCurrentSelectedSchool);

  const [
    addDescriptionToSchoolClass,
    { isLoading, isSuccess, isError, error },
  ] = useAddSchoolClassDescriptionMutation();

  const [descriptionAddForm, setDescriptionAddForm] = useState({
    description: "",
    schoolId: school.id,
    schoolYearId: "",
    schoolClassId: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    description: "",
    errorCount: 0,
    isValid: true,
  });

  const {
    data: schoolClass,
    isLoading: isLoadingSchoolClass,
    isSuccess: isSuccessSchoolClass,
    isError: isErrorSchoolClass,
    error: errorSchoolClass,
  } = useGetSchoolClassQuery(location.state.entityUrl);

  let content;

  if (isLoadingSchoolClass) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClass} />;
  } else if (isSuccessSchoolClass) {
    let elementsList = schoolClass
      ? {
          title: "School Class Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                {
                  textName: "School Name",
                  textValue: schoolClass.schoolName,
                },
                {
                  textName: "Year Name",
                  textValue: schoolClass.schoolYearName,
                },
                {
                  textName: "Class Name",
                  textValue: schoolClass.name,
                },

                {
                  textName: "Teacher",
                  textValue: schoolClass.teacherName,
                },
              ],
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isErrorSchoolClass) {
    content = <div>{errorSchoolClass.toString}</div>;
  }

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let description = "";

    if (descriptionAddForm.description.trim() === "") {
      description = "Please enter a description";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        description,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      description: "",
    });
  };

  const onSchoolClassDescriptionAddClicked = async (id) => {
    try {
      console.log("userAddForm", descriptionAddForm);
      console.log("personId", id);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await addDescriptionToSchoolClass({
          schoolClassId: schoolClass.id,
          description: descriptionAddForm.description,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
            isValidEntry = true;
          } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
            isValidEntry = true;
          }*/

    if (isValidEntry) {
      setDescriptionAddForm({
        ...descriptionAddForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {};

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"School Class Description created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      {message}
      {content}
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="description"
            name="description"
            label="Description"
            margin="dense"
            value={descriptionAddForm.description}
            onChange={handleChange}
            error={validationErrors.description !== ""}
            helperText={validationErrors.description}
          />
        </div>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton
            handleSubmit={() =>
              onSchoolClassDescriptionAddClicked(schoolClass.id)
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default SchoolClassesDescriptionAddSearchView;
