import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetSchoolClassQuery } from "./apiSchoolClassesSlice";

export const SchoolClassesView = (props) => {
  const location = useLocation();
  const {
    data: schoolClass,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolClassQuery(location.state.entityUrl);

  let elementsList = schoolClass
    ? {
        title: "School Class Information",
        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "Id", textValue: schoolClass.id },
              { textName: "Name", textValue: schoolClass.name },
              { textName: "Min Capacity", textValue: schoolClass.minCapacity },
              { textName: "Max Capacity", textValue: schoolClass.maxCapacity },
              {
                textName: "Occupied Capacity",
                textValue: schoolClass.occupiedCapacity,
              },
              {
                textName: "Reserved Capacity",
                textValue: schoolClass.reservedCapacity,
              },
              { textName: "School", textValue: schoolClass.schoolName },
              {
                textName: "School Year",
                textValue: schoolClass.schoolYearName,
              },
              { textName: "Classroom", textValue: schoolClass.classroomName },
              { textName: "Teacher", textValue: schoolClass.teacherName },
            ],
          },
        ],
      }
    : [];

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default SchoolClassesView;
