import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import {
  selectCurrentSchool,
  selectCurrentSelectedSchool,
} from "../auths/authSlice";
import {
  useGetStudentsBySchoolQuery,
  useGetStudentsQuery,
} from "./apiStudentsSlice";

export const StudentsList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);

  const {
    data: students,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentsBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Ref",
    "First Name",
    "Last Name",
    "Date Of Birth",
    "School Name",
  ];

  const entityValues = students?._embedded?.students?.map((student) => {
    let id = student.referenceNo;
    let firstName = student.firstName;
    let lastName = student.lastName;
    let schoolName = student.schoolName;
    let dateOfBirth = student.dateOfBirth;
    return {
      values: [id, firstName, lastName, dateOfBirth, schoolName],
      links: student._links.self.href,
    };
  });

  let pageInfo = {
    pageInfo: students?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 4,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/studentsView" },
            { type: "Update", to: "/studentsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
};

export default StudentsList;
