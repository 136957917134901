import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import {
  selectCurrentSchool,
  selectCurrentSelectedSchool,
} from "../auths/authSlice";
import {
  useGetAdmissionsBySchoolQuery,
  useGetAdmissionsQuery,
} from "./apiAdmissionsSlice";

export const AdmissionsList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);

  const {
    data: admissions,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdmissionsBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Name",
    "School",
    "School Year",
    "Start Date",
    "End Date",
  ];

  const entityValues = admissions?._embedded?.admissions.map((admission) => {
    //let id = admission.id;
    let name = admission.name;
    let school = admission.schoolName;
    let schoolYear = admission.schoolYearName;
    let startDate = admission.startDate;
    let endDate = admission.endDate;
    return {
      values: [name, school, schoolYear, endDate, startDate],
      links: admission._links.self.href,
    };
  });

  let content;

  let pageInfo = {
    pageInfo: admissions?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 5,
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/admissionsView" },
            { type: "Update", to: "/admissionsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
};

export default AdmissionsList;
