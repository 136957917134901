import { apiSlice } from "../../../api/apiSlice";

export const apiMedicalTypesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addMedicalType: builder.mutation({
      query: (newMedicalType) => ({
        url: "/medicaltypes",
        method: "POST",
        body: newMedicalType,
      }),
      invalidatesTags: ["MedicalType"],
    }),
    getMedicalTypes: builder.query({
      query: (queryInfo) =>
        `/medicaltypes?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["MedicalType"],
    }),
    getMedicalType: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["MedicalType"],
    }),
  }),
});

export const {
  useAddMedicalTypeMutation,
  useGetMedicalTypesQuery,
  useGetMedicalTypeQuery,
} = apiMedicalTypesSlice;
