import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { EduxooDisplayRow } from "../../components/common/EduxooDisplayRow";
import {
  useAddNewSchoolMutation,
  useGetSchoolQuery,
  useUpdateSchoolMutation,
} from "./apiSchoolsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EduxooBackButton,
  EduxooCreateButton,
} from "../../components/common/EduxooButton";
import {
  HeaderForSubtitleView,
  HeaderForView,
  ItemForView,
} from "../../components/common/EduxooViewElements";
import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";

import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
} from "../../components/common/FormValueValidators";
import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export default function SchoolUpdate(props) {
  const location = useLocation();
  const { data: schoolee, isSuccess } = useGetSchoolQuery(
    location.state.schoolUrl
  );
  const navigate = useNavigate();

  const [updateSchool, { isLoading }] = useUpdateSchoolMutation();

  const [school, setSchool] = useState(skipToken);

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    //TODO this.props.resetForBackButtonSchool();
    navigate(-1);
  };

  useEffect(() => {
    updateState();
  }, [isSuccess]);

  const updateState = () => {
    setSchool(schoolee);
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    address1: "",
    postcode: "",
    errorCount: 0,
    isValid: true,
  });

  const handleChange = (e) => {
    let isValidEntry = false;

    if (e.target.name === "fax" || e.target.name === "phone") {
      if (e.target.value === "" || reNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else if (
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "postcode"
    ) {
      if (e.target.value === "" || reAlphaNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSchool({
        ...school,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(school, "School in update");
      updateSchool(school);
      resetValidationErrors();
      window.scroll(0, 0);
    }
  };

  const validateForm = () => {
    let isValid = true;

    let name = "";
    let email = "";
    let phone = "";
    let address1 = "";
    let postcode = "";

    let errorCount = 0;

    if (school.name.trim() === "") {
      name = "Please enter a name";
      isValid = false;
      errorCount++;
    }

    if (school.email.trim() === "" || !reEmail.test(school.email)) {
      email = "Please enter a valid email address";
      errorCount++;
      isValid = false;
    }

    if (school.phone.trim() === "") {
      phone = "Please enter a valid phone";
      errorCount++;
      isValid = false;
    }

    {
      /*if (school.mainLocation.address.address1.trim() === "") {
      address1 = "Please enter Address1";
      isValid = false;
      errorCount++;
    }

    if (school.mainLocation.address.postcode.trim() === "") {
      postcode = "Please enter a Postcode";
      isValid = false;
      errorCount++;
    }*/
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        email,
        phone,
        address1,
        postcode,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      phone: "",
      email: "",
      address1: "",
      postcode: "",
      isValid: true,
      errorCount: 0,
    });
  };
  const handleAddressChange = (e) => {
    let isValidEntry = false;

    if (
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "postcode"
    ) {
      if (e.target.value === "" || reAlphaNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSchool({
        ...school,
        mainLocation: {
          ...school.mainLocation,
          address: {
            ...school.mainLocation.address,
            [e.target.name]: e.target.value,
          },
        },
      });
    }
  };

  let message = "";

  return (
    <>
      <CssBaseline />

      {school ? (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              School Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>
            <div>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                required
                variant="outlined"
                margin="dense"
                value={school.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
              />
            </div>
            <div>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                value={school.phone}
                onChange={handleChange}
                helperText={validationErrors.phone}
                error={validationErrors.phone !== ""}
                margin="dense"
                fullWidth
                required
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="fax"
                name="fax"
                label="Fax"
                variant="outlined"
                value={school.fax}
                onChange={handleChange}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                value={school.email}
                onChange={handleChange}
                margin="dense"
                error={validationErrors.email !== ""}
                helperText={validationErrors.email}
                required
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="website"
                name="website"
                label="Website"
                variant="outlined"
                value={school.website}
                onChange={handleChange}
                margin="dense"
              />
            </div>
            {/*<div>
            <Typography variant="h6" gutterBottom>
              Address Details
            </Typography>
            <div>
              <TextField
                fullWidth
                required
                error={validationErrors.address1 !== ""}
                id="address1"
                name="address1"
                label="Address line 1"
                variant="outlined"
                value={school.mainLocation.address.address1}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address1}
              />

              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2 (optional)"
                variant="outlined"
                value={school.mainLocation.address.address2}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address2}
              />

              <TextField
                fullWidth
                id="address3"
                name="address3"
                label="Address line 3 (optional)"
                variant="outlined"
                value={school.mainLocation.address.address3}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="town"
                name="town"
                label="Town or City (optional)"
                variant="outlined"
                value={school.mainLocation.address.town}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="county"
                name="county"
                label="County (optional)"
                variant="outlined"
                value={school.mainLocation.address.county}
                onChange={handleAddressChange}
                margin="dense"
              />
            </div>
            <TextField
              fullWidth
              required
              error={validationErrors.postcode !== ""}
              id="postcode"
              name="postcode"
              label="Postcode"
              variant="outlined"
              value={school.mainLocation.address.postcode}
              onChange={handleAddressChange}
              margin="dense"
              helperText={validationErrors.postcode}
            />
          </div>*/}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <EduxooCreateButton handleSubmit={handleSubmit} />
            </Box>
          </Paper>
        </Container>
      ) : (
        "Nothing"
      )}
    </>
  );
}
