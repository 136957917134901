import { apiSlice } from "../api/apiSlice";

export const apiTeachersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeachers: builder.query({
      query: () => "/teachers/all",
      providesTags: ["Teacher"],
    }),
    getTeachersPageable: builder.query({
      query: (customPageable) =>
        `/teachers?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Teacher"],
    }),
    getTeacher: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Teacher"],
    }),
    addNewTeacher: builder.mutation({
      query: (initialTeacher) => ({
        url: "/teachers",
        method: "POST",
        body: initialTeacher,
      }),
      invalidatesTags: ["Teacher"],
    }),
    getTeachersBySchool: builder.query({
      query: (entityInfo) =>
        `/schools/${entityInfo.school}/teachers?size=${entityInfo.rowsPerPage}&page=${entityInfo.page}`,
      providesTags: ["Teacher"],
    }),
    updateTeacher: builder.mutation({
      query: (updatedTeacher) => ({
        url: `/teachers/${updatedTeacher.id}`,
        method: "PUT",
        body: updatedTeacher,
      }),
      invalidatesTags: ["Teacher"],
    }),
  }),
});

export const {
  useGetTeachersQuery,
  useGetTeachersPageableQuery,
  useGetTeacherQuery,
  useGetTeachersBySchoolQuery,
  useAddNewTeacherMutation,
  useUpdateTeacherMutation,
} = apiTeachersSlice;

export const selectTeachersResult = apiTeachersSlice.endpoints;
