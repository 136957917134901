import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooBackButton } from "../../components/common/EduxooButton";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetAdmissionQuery } from "./apiAdmissionsSlice";

export const AdmissionsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: admission,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdmissionQuery(location.state.entityUrl);

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  let elementsList = admission
    ? {
        title: "Admission Information",
        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "Id", textValue: admission.id },
              { textName: "Name", textValue: admission.name },
              { textName: "School", textValue: admission.schoolName },
              { textName: "School Year", textValue: admission.schoolYearName },
              { textName: "Start Date", textValue: admission.startDate },
              { textName: "End Date", textValue: admission.endDate },
            ],
          },
        ],
      }
    : [];

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default AdmissionsView;
