import { EduxooMainMenu } from "../../../../components/common/EduxooMainMenu";

const tiers = [
  [
    {
      title: "Logs",
      id: "ActivityLogsView",
      subheader: "View",
      price: "15",
      description: ["You can view all your activity logs"],
      buttonText: "View",
      buttonVariant: "outlined",
      to: "/activityLogsList",
    },
    {
      title: "Logs",
      id: "ActivityLogsSearch",
      subheader: "Search",
      price: "15",
      description: ["You can search Logs"],
      buttonText: "Search activity logs",
      buttonVariant: "outlined",
      to: "/activityLogsSearch",
    },
  ],
];

export const ActivityLogsMain = () => {
  return <EduxooMainMenu tiers={tiers} />;
};

export default ActivityLogsMain;
