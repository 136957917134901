import { apiSlice } from "../../../api/apiSlice";

export const apiUsersAdminSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSchoolForAdmin: builder.mutation({
      query: (newSchool) => ({
        url: "/schools/create",
        method: "POST",
        body: newSchool,
      }),
      invalidatesTags: ["School"],
    }),
    getSchoolsWithoutUsers: builder.query({
      query: (queryInfo) =>
        `/users/schools/nouser?schoolName=${queryInfo.schoolName}&size=10&page=0`,
      providesTags: ["SchoolsWithoutUsers"],
    }),
    addUserForSchoolForAdmin: builder.mutation({
      query: (newUser) => ({
        url: "/users/schools",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    getPersonForAdmin: builder.query({
      query: (personId) => `/people/${personId}`,
      providesTags: ["Person"],
    }),
  }),
});

export const {
  useAddSchoolForAdminMutation,
  useAddUserForSchoolForAdminMutation,
  useGetPersonForAdminQuery,
  useLazyGetSchoolsWithoutUsersQuery,
} = apiUsersAdminSlice;
