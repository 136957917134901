import { apiSlice } from "../api/apiSlice";

export const apiAdmissionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAdmission: builder.mutation({
      query: (newAdmission) => ({
        url: "/admissions",
        method: "POST",
        body: newAdmission,
      }),
      invalidatesTags: ["Admission"],
    }),
    addAdmissionByClass: builder.mutation({
      query: (newAdmission) => ({
        url: "/admissions",
        method: "POST",
        body: newAdmission,
      }),
      invalidatesTags: ["Admission"],
    }),
    getAdmissions: builder.query({
      query: (customPageable) =>
        `/admissions?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Admission"],
    }),
    getAdmissionsBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/admissions?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Admission"],
    }),
    getAdmission: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Admission"],
    }),
    getAdmissionsBySchoolYear: builder.query({
      query: (queryInfo) =>
        `/schoolyears/${queryInfo.schoolYearId}/admissions?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Admission"],
    }),
  }),
});

export const {
  useGetAdmissionQuery,
  useGetAdmissionsQuery,
  useGetAdmissionsBySchoolQuery,
  useAddAdmissionMutation,
  useAddAdmissionByClassMutation,
  useGetAdmissionsBySchoolYearQuery,
} = apiAdmissionsSlice;
