import { apiSlice } from "../api/apiSlice";

export const apiParentsUserSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChildrenByParent: builder.query({
      query: (queryInfo) =>
        `/parents/${queryInfo.parent}/children?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Children"],
    }),
    registerChildren: builder.mutation({
      query: (registerChildForm) => ({
        url: `/admissions/${registerChildForm.admissionId}/admissionregisters`,
        method: "POST",
        body: registerChildForm,
      }),
      invalidatesTags: ["Parent"],
    }),
    getAdmissionsByParent: builder.query({
      query: (queryInfo) =>
        `/admissions/searchByUser?status=${queryInfo.searchQuery.status}&childId=${queryInfo.searchQuery.childId}&size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Admission"],
    }),
  }),
});

export const {
  useGetChildrenByParentQuery,
  useRegisterChildrenMutation,
  useGetAdmissionsByParentQuery,
} = apiParentsUserSlice;
