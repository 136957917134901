import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "dayjs/locale/en-gb";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useAddNewSchoolYearMutation } from "./apiSchoolYearsSlice";
import dayjs from "dayjs";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../auths/authSlice";

export const SchoolYearsAdd = () => {
  const [open, setOpen] = useState(false);

  const school = useSelector(selectCurrentSelectedSchool);

  const [schoolYear, setSchoolYear] = useState({
    name: "",
    description: "",
    startDate: null,
    endDate: null,
    schoolId: school.id,
  });

  const resetForm = () => {
    setSchoolYear({
      ...schoolYear,
      name: "",
      description: "",
      startDate: "",
      endDate: "",
    });
  };

  const [
    addNewSchoolYear,
    { isLoading, isSuccess, isError, error },
  ] = useAddNewSchoolYearMutation();

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"School Year Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    endDateNotGreaterThan: "",
    schoolId: "",
    isValid: true,
    errorCount: 0,
  });

  const validateForm = () => {
    let isValid = true;

    let endDateNotGreaterThan = "";
    let startDate = "";
    let endDate = "";
    let name = "";
    let schoolId = "";
    let description = "";

    let errorCount = 0;

    console.log("schoolYear", schoolYear);

    {
      /*if (schoolYear.schoolId.trim() === "") {
      schoolId = "Please, Select a school";
      isValid = false;
      errorCount++;
    }

    if (schoolYear.name.trim() === "") {
      name = "Please enter a valid Name";
      isValid = false;
      errorCount++;
    }

    if (
      (schoolYear.startDate !== null &&
        schoolYear.startDate.toString() === "Invalid Date") ||
      schoolYear.startDate === null
    ) {
      console.log("startDate");
      startDate = "Please enter a valid Start Date";
      isValid = false;
      errorCount = errorCount + 1;
    }

    if (
      (schoolYear.endDate !== null &&
        schoolYear.endDate.toString() === "Invalid Date") ||
      schoolYear.endDate === null
    ) {
      endDate = "Please enter a valid End Date";
      isValid = false;
      errorCount = errorCount + 1;
    }

    if (!(startDate && endDate)) {
      if (schoolYear.endDate <= schoolYear.startDate) {
        endDateNotGreaterThan =
          "Please select an End date Greater than Start Date";

        isValid = false;
        errorCount++;
      }
    }

    if (schoolYear.description.trim() === "") {
      description = "Please, enter a description";
      isValid = false;
      errorCount++;
    }*/
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        startDate,
        endDate,
        endDateNotGreaterThan,
        schoolId,
        description,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      startDate: "",
      endDate: "",
      endDateNotGreaterThan: "",
      schoolId: "",
      description: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const handleChange = (e) => {
    setSchoolYear({
      ...schoolYear,
      [e.target.name]: e.target.value,
    });
  };

  const handleStartDateChange = (newStartDate) => {
    setSchoolYear({
      ...schoolYear,
      startDate: dayjs(newStartDate).format("YYYY-MM-DD"),
    });
  };

  const handleEndDateChange = (newEndDate) => {
    setSchoolYear({
      ...schoolYear,
      endDate: dayjs(newEndDate).format("YYYY-MM-DD"),
    });
  };

  const onCreateSchoolYearClicked = async () => {
    if (validateForm) {
      {
        try {
          if (validateForm()) {
            setOpen(true);
            resetValidationErrors();
            await addNewSchoolYear(schoolYear).unwrap();
          }
        } catch (err) {
          console.error("Failed to save the School Year: ", err);
        }
      }
    }
  };

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              School Year Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                value={schoolYear.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
                margin="dense"
                inputProps={{ maxLength: 150 }}
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="description"
                name="description"
                label="Description"
                variant="outlined"
                value={schoolYear.description}
                onChange={handleChange}
                helperText={validationErrors.description}
                error={validationErrors.description !== ""}
                margin="dense"
              />
            </div>

            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Start Date"
                    value={schoolYear.startDate}
                    onChange={(newStartDate) =>
                      handleStartDateChange(newStartDate)
                    }
                    slotProps={{
                      textField: {
                        required: true,
                        error: validationErrors.startDate !== "",
                        helperText: validationErrors.startDate,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="End Date"
                    value={schoolYear.endDate}
                    onChange={(newEndDate) => handleEndDateChange(newEndDate)}
                    slotProps={{
                      textField: {
                        required: true,
                        error:
                          validationErrors.endDate !== "" ||
                          validationErrors.endDateNotGreaterThan != "",
                        helperText:
                          validationErrors.endDate +
                          validationErrors.endDateNotGreaterThan,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Paper>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onCreateSchoolYearClicked} />
        </Box>
      </Container>
    </>
  );
};

export default SchoolYearsAdd;
