import { Container, CssBaseline, Grid, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SchoolsMain() {
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="md"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            mb: 2,
          }}
        >
          <Grid
            container
            //direction="column"
            //justifyContent="space-around"
            //alignItems="center"
            spacing={2}
          >
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolsAdd");
                  }}
                >
                  Create School
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolsList");
                  }}
                >
                  View Schools
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/locationsAdd");
                  }}
                >
                  Add Location
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/locationsList");
                  }}
                >
                  View Locations
                </Link>
              </Item>
            </Grid>

            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/classroomsAdd");
                  }}
                >
                  Add Classroom
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/classroomsList");
                  }}
                >
                  View Classrooms
                </Link>
              </Item>
            </Grid>

            <Grid item xs={3}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolClassesAdd");
                  }}
                >
                  Add Class
                </Link>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolClassesList");
                  }}
                >
                  View Classes
                </Link>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolClassesDescriptionAddSearch");
                  }}
                >
                  Add Class Description
                </Link>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/schoolClassesDescriptionList");
                  }}
                >
                  View Class Descriptions
                </Link>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/admissionsAdd");
                  }}
                >
                  Add Admission
                </Link>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/admissionsAddByClass");
                  }}
                >
                  Add Admission By Class
                </Link>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/admissionsList");
                  }}
                >
                  View Admissions
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/admissionRegistersList");
                  }}
                >
                  View Admission Registers
                </Link>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    navigate("/admissionRegistersApplications");
                  }}
                >
                  Update Admission Registers
                </Link>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default SchoolsMain;
