import { EduxooMainMenu } from "../../../../components/common/EduxooMainMenu";

export default function SchoolsMainAdmin() {
  const tiers = [
    [
      {
        title: "School",
        id: "SchoolAdminCreate",
        subheader: "Create",
        description: ["You can add a new School by using this form"],
        buttonText: "Add a new School",
        buttonVariant: "contained",
        to: "/schoolsAddAdmin",
      },
      {
        title: "School",
        id: "SchoolAdminView",
        subheader: "View or Update",
        description: ["You can view and update by using this form"],
        buttonText: "View all your School",
        buttonVariant: "outlined",
        to: "/schoolsListAdmin",
      },
    ],
    [
      {
        title: "User",
        id: "UserAdminCreate",
        subheader: "Create",
        description: ["You can add a new User to Schools by using this form"],
        buttonText: "Add a new User",
        buttonVariant: "contained",
        to: "/schoolsUserAddSearchAdmin",
      },
      {
        title: "User",
        id: "UserAdminView",
        subheader: "View or Update",
        description: ["You can view and update users by using this form"],
        buttonText: "View all your Users",
        buttonVariant: "outlined",
        to: "/schoolsUserListSearchAdmin",
      },
    ],
  ];
  return <EduxooMainMenu tiers={tiers} />;
}
