import { apiSlice } from "../api/apiSlice";

export const apiAdmissionRegistersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdmissionRegistersByAdmission: builder.query({
      query: (queryParams) =>
        `/admissions/${queryParams.admissionId}/admissionregisters?size=${queryParams.rowsPerPage}&page=${queryParams.page}`,
      providesTags: ["AdmissionRegister"],
    }),
    getAdmissionRegisters: builder.query({
      query: (queryParams) =>
        `/admissionregisters?size=${queryParams.rowsPerPage}&page=${queryParams.page}`,
      providesTags: ["AdmissionRegister"],
    }),
    getAdmissionRegistersByAdmissionAndActive: builder.query({
      query: (queryParams) =>
        `/admissions/${queryParams.admissionId}/admissionregisters/search?status=STARTED&size=${queryParams.rowsPerPage}&page=${queryParams.page}`,
      providesTags: ["AdmissionRegister"],
    }),
    getAdmissionRegister: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["AdmissionRegister"],
    }),
    updateAdmissionRegisterForRegister: builder.mutation({
      query: (admissionRegister) => ({
        url: `/admissionregisters/${admissionRegister.id}/status`,
        method: "PATCH",
        body: admissionRegister,
      }),
      invalidatesTags: ["AdmissionRegister"],
    }),
  }),
});

export const {
  useGetAdmissionRegisterQuery,
  useGetAdmissionRegistersQuery,
  useGetAdmissionRegistersByAdmissionQuery,
  useGetAdmissionRegistersByAdmissionAndActiveQuery,
  useUpdateAdmissionRegisterForRegisterMutation,
} = apiAdmissionRegistersSlice;
