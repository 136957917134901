import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAddLocationMutation } from "./apiLocationsSlice";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../auths/authSlice";

export const LocationsAdd = () => {
  const [
    addLocation,
    { isLoading, isSuccess, isError, error },
  ] = useAddLocationMutation();

  const selectedSchool = useSelector(selectCurrentSelectedSchool);

  const [open, setOpen] = useState(false);

  const [location, setLocation] = useState({
    name: "",
    schoolId: selectedSchool.id,
    address: {
      address1: "",
      address2: "",
      address3: "",
      county: "",
      town: "",
      postcode: "",
      addressType: "HOME",
    },
  });

  const resetForm = () => {
    setLocation({
      ...location,
      name: "",
      address: {
        address1: "",
        address2: "",
        address3: "",
        county: "",
        town: "",
        postcode: "",
        addressType: "HOME",
      },
    });
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Location is Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} setOpen={setOpen} open={open} />;
  }

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    schoolId: "",
    address1: "",
    postcode: "",
    isValid: true,
    errorCount: 0,
  });

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setLocation({
        ...location,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddressChange = (e) => {
    setLocation({
      ...location,
      address: {
        ...location.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onSaveLocationClicked = async () => {
    try {
      if (validateForm()) {
        setOpen(true);
        resetValidationErrors();
        await addLocation(location);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const validateForm = () => {
    let isValid = true;

    let name = "";
    let schoolId = "";

    let address1 = "";
    let postcode = "";

    let errorCount = 0;

    if (location.name.trim() === "") {
      name = "Please enter a First Name";
      isValid = false;
      errorCount++;
    }

    if (location.schoolId.trim() === "") {
      schoolId = "Please, select a School";
      isValid = false;
      errorCount++;
    }

    if (
      location.address &&
      (location.address.address1.trim() !== "" ||
        location.address.address2.trim() !== "" ||
        location.address.address3.trim() !== "" ||
        location.address.town.trim() !== "" ||
        location.address.county.trim() !== "" ||
        location.address.postcode.trim() !== "")
    ) {
      if (location.address.address1.trim() === "") {
        address1 = "Please enter a valid Address";
        isValid = false;
        errorCount++;
      }
      if (location.address.postcode.trim() === "") {
        postcode = "Please enter a valid Postcode";
        isValid = false;
        errorCount++;
      }
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        schoolId,
        address1,
        postcode,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      schoolId: "",
      address1: "",
      postcode: "",
      isValid: true,
      errorCount: 0,
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Location Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Location Name"
                variant="outlined"
                value={location.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
                margin="dense"
                inputProps={{ maxLength: 150 }}
              />
            </div>
          </Paper>
        </Container>

        <Container maxWidth="md" margin="normal">
          <Paper style={{ padding: "5px" }}>
            <Typography variant="h5">Address Details</Typography>
            <div>
              <TextField
                fullWidth
                id="address1"
                name="address1"
                label="Address line 1"
                variant="outlined"
                value={location.address.address1}
                onChange={handleAddressChange}
                margin="dense"
                //required
                error={validationErrors.address1 !== ""}
                helperText={validationErrors.address1}
              />

              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2 (optional)"
                variant="outlined"
                value={location.address.address2}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address2}
              />

              <TextField
                fullWidth
                id="address3"
                name="address3"
                label="Address line 3 (optional)"
                variant="outlined"
                value={location.address.address3}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="town"
                name="town"
                label="Town or City (optional)"
                variant="outlined"
                value={location.address.town}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="county"
                name="county"
                label="County (optional)"
                variant="outlined"
                value={location.address.county}
                onChange={handleAddressChange}
                margin="dense"
              />
            </div>
            <TextField
              fullWidth
              id="postcode"
              name="postcode"
              label="Postcode"
              variant="outlined"
              value={location.address.postcode}
              onChange={handleAddressChange}
              margin="dense"
              //required
              error={validationErrors.postcode !== ""}
              helperText={validationErrors.postcode}
            />
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveLocationClicked} />
        </Box>
      </Container>
    </>
  );
};

export default LocationsAdd;
