import { Container, Grid, Paper, Typography } from "@mui/material";

export const WelcomesHome = () => {
  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        sx={{
          p: 2,
          marginTop: 1,
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1" component="div">
              Welcome to your Dashboard for managing your School
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          p: 2,
          marginTop: 1,
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1" component="div">
              You have 11 tasks to complete
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default WelcomesHome;
