import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetClassroomQuery } from "./apiClassroomsSlice";

export const ClassroomsView = (props) => {
  const location = useLocation();
  const {
    data: classroom,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetClassroomQuery(location.state.entityUrl);

  let elementsList = classroom
    ? {
        title: "Classroom Information",
        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "Id", textValue: classroom.id },
              { textName: "Name", textValue: classroom.name },
              { textName: "Location", textValue: classroom.locationName },
              { textName: "Level", textValue: classroom.level },
              { textName: "Min Capacity", textValue: classroom.minCapacity },
              { textName: "Max Capacity", textValue: classroom.maxCapacity },
            ],
          },
        ],
      }
    : [];

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default ClassroomsView;
