import { apiSlice } from "../api/apiSlice";

export const apiUserTeachersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolClassesForTeacher: builder.query({
      query: () => `schoolclasses/teachers?size=10&page=0`,
      providesTags: ["SchoolClass"],
    }),
  }),
});

export const { useGetSchoolClassesForTeacherQuery } = apiUserTeachersSlice;
