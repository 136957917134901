import { apiSlice } from "../api/apiSlice";

export const apiStudentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addStudent: builder.mutation({
      query: (newStudent) => ({
        url: "/students",
        method: "POST",
        body: newStudent,
      }),
      invalidatesTags: ["Student"],
    }),
    getStudentsBySchool: builder.query({
      query: (entityInfo) =>
        `/schools/${entityInfo.school}/students?size=${entityInfo.rowsPerPage}&page=${entityInfo.page}`,
      providesTags: ["Student"],
    }),
    getStudents: builder.query({
      query: (customPageable) =>
        `/students?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Student"],
    }),
    registerStudent: builder.mutation({
      query: (student) => ({
        url: "/students/register",
        method: "POST",
        body: student,
      }),
      invalidatesTags: ["Student", "EducationalRecord", "SchoolClass"],
    }),
    getStudent: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Student"],
    }),
    getStudentsByParam: builder.query({
      query: (queryInfo) =>
        `/students/search?firstName=${queryInfo.firstName}&lastName=${queryInfo.lastName}`,
      providesTags: ["Student"],
    }),
  }),
});

export const {
  useGetStudentQuery,
  useGetStudentsQuery,
  useAddStudentMutation,
  useGetStudentsBySchoolQuery,
  useRegisterStudentMutation,
  useLazyGetStudentsByParamQuery,
} = apiStudentsSlice;
