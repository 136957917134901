import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { useGetAdmissionsBySchoolQuery } from "../admissions/apiAdmissionsSlice";
import { useGetSchoolQuery } from "../schools/apiSchoolsSlice";
import { useRegisterMutation } from "./apiRegistrationsSlice";

export const RegistrationsRegisterSearchView = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [
    register,
    { isLoading, isSuccess, isError, error },
  ] = useRegisterMutation();

  const [registrationForm, setRegistrationForm] = useState({
    admissionId: "",
    note: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    admissionId: "",
    note: "",
    errorCount: 0,
    isValid: true,
  });

  const {
    data: school,
    isLoading: isLoadingSchool,
    isSuccess: isSuccessSchool,
    isError: isErrorSchool,
    error: errorSchool,
  } = useGetSchoolQuery(location.state.entityUrl);

  let content;

  if (isLoadingSchool) {
    content = <EduxooBackdrop isLoading={isLoadingSchool} />;
  } else if (isSuccessSchool) {
    let elementsList = school
      ? {
          title: "School Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                {
                  textName: "Name",
                  textValue: school.name,
                },
              ],
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isErrorSchool) {
    content = <div>{errorSchool.toString}</div>;
  }

  const {
    data: admissions,
    isLoading: isLoadingAdm,
    isSuccess: isSuccessAdm,
    isError: isErrorAdm,
    error: errorAdm,
  } = useGetAdmissionsBySchoolQuery(
    {
      school: location.state.entityId,
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      skip: location.state.entityId ? false : true,
    }
  );

  let admissionsSelectList;

  if (isLoadingAdm) {
    content = <EduxooBackdrop isLoading={isLoadingAdm} />;
  } else if (isSuccessAdm) {
    admissionsSelectList = admissions?._embedded?.admissions.map(
      (admission) => {
        return (
          <MenuItem value={admission.id} key={admission.id}>
            {admission.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorAdm) {
    content = <div>{errorAdm.toString}</div>;
  }

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let admissionId = "";

    if (registrationForm.admissionId.trim() === "") {
      admissionId = "Please select an admission";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        admissionId,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      admissionId: "",
    });
  };

  const onUsersAddClicked = async (id) => {
    try {
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await register(registrationForm);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
          isValidEntry = true;
        } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
          isValidEntry = true;
        }*/

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {};

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Registered Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      {message}
      {content}
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={validationErrors.admissionId !== ""}
              required
            >
              <InputLabel id="admission-select">Admission</InputLabel>
              <Select
                labelId="admission-select"
                id="admissionId"
                name="admissionId"
                value={registrationForm.admissionId}
                label="Location"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select an Admission</MenuItem>
                {admissionsSelectList}
              </Select>
              <FormHelperText>{validationErrors.admissionId}</FormHelperText>
            </FormControl>
          </Box>
        </div>
        <div>
          <TextField
            fullWidth
            id="note"
            name="note"
            label="Note"
            variant="outlined"
            value={registrationForm.note}
            onChange={handleChange}
            margin="dense"
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton
            handleSubmit={() => onUsersAddClicked(school.id)}
          />
        </Box>
      </Container>
    </>
  );
};

export default RegistrationsRegisterSearchView;
