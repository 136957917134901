import { Container, CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import {
  selectCurrentSchool,
  selectCurrentSelectedSchool,
} from "../auths/authSlice";
import { useGetSchoolClassesBySchoolQuery } from "./apiSchoolClassesSlice";

export const SchoolClassesList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSelectedSchool);

  const {
    data: schoolClasses,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolClassesBySchoolQuery(
    { school: school.id, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = [
    "Name",
    "Min Capacity",
    "Max Capacity",
    "School",
    "School Year",
    "Classroom",
    "Teacher",
  ];

  const entityValues = schoolClasses?._embedded?.schoolClasses?.map(
    (schoolClass) => {
      let name = schoolClass.name;

      let minCapacity = schoolClass.minCapacity;
      let maxCapacity = schoolClass.maxCapacity;
      let school = schoolClass.schoolName;
      let schoolYear = schoolClass.schoolYearName;
      let classroom = schoolClass.classroomName;
      let teacher = schoolClass.teacherName;
      return {
        values: [
          name,
          minCapacity,
          maxCapacity,
          school,
          schoolYear,
          classroom,
          teacher,
        ],
        links: schoolClass._links.self.href,
      };
    }
  );

  let content;

  let pageInfo = {
    pageInfo: schoolClasses?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 5,
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/schoolClassesView" },
            { type: "Update", to: "/schoolClassesUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {content}
      </Container>
    </>
  );
};

export default SchoolClassesList;
