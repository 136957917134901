import { Container, CssBaseline } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { selectCurrentSchool } from "../auths/authSlice";
import {
  useGetParentsQuery,
  useGetParentsBySchoolQuery,
} from "./apiParentsSlice";

export const ParentsList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const school = useSelector(selectCurrentSchool);

  const {
    data: parents,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetParentsBySchoolQuery(
    { school, rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  let pageInfo = {
    pageInfo: parents?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 6,
  };

  const entityHeaderList = [
    "Parent Type",
    "First Name",
    "Last Name",
    "Child First Name",
    "Child Last Name",
  ];

  const entityValues = parents?._embedded?.relationships?.map((parent) => {
    let relationshipType = parent.relationshipType;
    let id = parent.personId;
    let firstName = parent.personFirstName;
    let lastName = parent.personLastName;
    let childId = parent.personRelatedId;
    let childFirstName = parent.personRelatedFirstName;
    let childLastName = parent.personRelatedLastName;

    return {
      values: [
        relationshipType,
        firstName,
        lastName,
        childFirstName,
        childLastName,
      ],
      links: parent._links.self.href,
    };
  });

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/parentsView" },
            { type: "Update", to: "/parentsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {content}
      </Container>
    </>
  );
};

export default ParentsList;
