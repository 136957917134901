import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EduxooBackButton } from "./EduxooButton";
import {
  HeaderForSubtitleView,
  HeaderForView,
  ItemForView,
} from "./EduxooViewElements";

export const EduxooViewGenerator = ({ elementsList }) => {
  let customKeyId = 0;
  const navigate = useNavigate();

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: 10 }}>
        <Paper
          sx={{
            p: 1,
            margin: "auto",
            maxWidth: "md",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Box
            sx={{
              backgroundColor: "primary.title",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              align="center"
              sx={{ color: "#FFFFFF" }}
            >
              {elementsList.title}
            </Typography>
          </Box>
          {elementsList?.elements?.map((element) => {
            customKeyId += 1;
            return (
              <Grid container spacing={2} key={customKeyId}>
                <Grid item xs={12} key={customKeyId}>
                  <Box
                    sx={{
                      backgroundColor: "primary.subtitle",
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      component="div"
                      align="center"
                      sx={{ color: "#FFFFFF" }}
                    >
                      {element.title}
                    </Typography>
                  </Box>
                </Grid>
                {element.subElements?.map((subie) => {
                  customKeyId += 1;
                  return (
                    <Grid item xs={12} key={customKeyId}>
                      {subie.textName} : {subie.textValue}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Paper>
      </Container>
      <EduxooBackButton handleBackButtonClick={handleBackButtonClick} />
    </>
  );
};
