import { Grid, Typography } from "@mui/material";

export const EduxooDisplayRow = (props) => {
  return (
    <Grid item container>
      <Grid item xs={12} sm={3}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            textAlign: "start",
            //borderBottom: "1px solid grey",
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography
          variant="body1"
          gutterBottom
          style={{
            textAlign: "start",
            //borderBottom: "1px solid grey",
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
