import { Alert, Container } from "@mui/material";

export const ErrorsSummaryBox = (props) => {
  return (
    <Container maxWidth="sm">
      <Alert severity="error" style={{ fontSize: 21, color: "red" }}>
        There {props.errorCount === 1 ? "is" : "are"} {props.errorCount} error
        {props.errorCount === 1 ? " " : "s "}
        below <br /> Please correct {props.errorCount === 1 ? " " : "them"} and
        try again
      </Alert>
    </Container>
  );
};
