import { CssBaseline } from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { useGetSchoolClassesForTeacherQuery } from "./apiUserTeachersSlice";

export const UserTeachersList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: schoolClasses,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolClassesForTeacherQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["School", "Class", "Year"];

  const entityValues = schoolClasses?._embedded?.schoolClasses.map(
    (schoolClass) => {
      let schoolName = schoolClass.schoolName;
      let schoolClassName = schoolClass.name;
      let schoolYearName = schoolClass.schoolYearName;
      return {
        values: [schoolName, schoolClassName, schoolYearName],
        links: schoolClass._links.self.href,
      };
    }
  );

  const pageInfo = {
    pageInfo: 0,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 3,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/teachersView" },
            { type: "Update", to: "/teachersUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default UserTeachersList;
