import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#2196f3",
      dark: "#002884",
      contrastText: "#fff",
      title: "#03a9f4",
      subtitle: "#4fc3f7",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    ochre: {
      main: "#E3D026",
      light: "#E9DB5D",
      dark: "#A29415",
      contrastText: "#242105",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: [
      "Tahoma",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      /*"-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',*/
    ].join(","),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  searchContainer: {
    marginBottom: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "center",
  },
  grow: {
    flexGrow: 0,
  },
});

export default theme;
