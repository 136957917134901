import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import {
  EduxooGetAllButton,
  EduxooSearchButton,
} from "../../components/common/EduxooButton";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooErrorBox } from "../../components/common/EduxooErrorBox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useLazyGetPersonsWithoutUsersQuery } from "./apiUsersSlice";

export const UsersAddParentSearch = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [studentQuery, setStudentQuery] = useState({
    firstName: "",
    lastName: "",
    referenceNo: "",
  });

  let content;

  const [
    personsWithoutUserQueryTrigger,
    { data: people, isLoading, isSuccess, isError, error },
  ] = useLazyGetPersonsWithoutUsersQuery();

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    isValid: true,
    errorCount: 0,
  });

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setStudentQuery({
        ...studentQuery,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let firstName = "";
    let lastName = "";
    let errorCount = 0;

    if (
      studentQuery.firstName.trim() === "" &&
      studentQuery.lastName.trim() === ""
    ) {
      firstName = "Please enter a First Name or Last Name";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        firstName,
        lastName,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      firstName: "",
      lastName: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const resetForm = () => {
    setStudentQuery({
      firstName: "",
      lastName: "",
    });
  };

  const onSearchClicked = async () => {
    try {
      setOpen(true);
      if (validateForm()) {
        resetValidationErrors();
        await personsWithoutUserQueryTrigger({
          firstName: studentQuery.firstName,
          lastName: studentQuery.lastName,
          rowsPerPage: 10,
          page: 0,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const onGetAllClicked = async () => {
    try {
      setOpen(true);

      resetValidationErrors();
      resetForm();
      await personsWithoutUserQueryTrigger({
        firstName: "",
        lastName: "",
        rowsPerPage: 10,
        page: 0,
      });
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  const handlePersonSelectClicked = ({ e, entityUrl, entityId }) => {
    e.preventDefault();
    navigate("/usersAddSearchView", {
      state: {
        entityUrl: entityUrl,
        entityId: entityId,
      },
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Container component="main" maxWidth="sm">
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Search For Parent to Create a User
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={studentQuery.firstName}
                onChange={handleChange}
                error={validationErrors.firstName !== ""}
                helperText={validationErrors.firstName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                error={validationErrors.lastName !== ""}
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={studentQuery.lastName}
                onChange={handleChange}
                helperText={validationErrors.lastName}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
          <EduxooSearchButton handleSubmit={onSearchClicked} />
          <EduxooGetAllButton handleSubmit={onGetAllClicked} />
        </Box>

        <Container sx={{ marginTop: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {people?._embedded?.people?.map((person) => {
                  return (
                    <TableRow key={person.id}>
                      <TableCell align="left">{person.firstName}</TableCell>
                      <TableCell align="left">{person.lastName}</TableCell>

                      <TableCell>
                        <Button
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                          //TODO className={classes.button}
                          onClick={(e) =>
                            handlePersonSelectClicked({
                              e,
                              entityUrl: person._links.self.href,
                              entityId: person.id,
                            })
                          }
                        >
                          SELECT
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
    </>
  );
};

export default UsersAddParentSearch;
