import { apiSlice } from "../api/apiSlice";

export const apiSchoolClassDescriptionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSchoolClassDescription: builder.mutation({
      query: (addInfo) => ({
        url: `/schoolclassdescriptions`,
        method: "POST",
        body: addInfo,
      }),
      invalidatesTags: ["SchoolClassDescription"],
    }),
    getAllDescriptionsBySchool: builder.query({
      query: (customQuery) =>
        `/school/${customQuery.school}/schoolclassdescriptions?size=${customQuery.rowsPerPage}&page=${customQuery.page}`,
      providesTags: ["SchoolClassDescription"],
    }),
  }),
});

export const {
  useAddSchoolClassDescriptionMutation,
  useGetAllDescriptionsBySchoolQuery,
} = apiSchoolClassDescriptionSlice;
