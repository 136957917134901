import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ErrorsSummaryBox } from "../../../../components/common/ErrorsSummaryBox";
import {
  EduxooGetAllButton,
  EduxooSearchButton,
} from "../../../../components/common/EduxooButton";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import { EduxooErrorBox } from "../../../../components/common/EduxooErrorBox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useLazyGetSchoolsWithoutUsersQuery } from "./apiUsersAdminSlice";

export const SchoolsUsersAddSearchAdmin = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [schoolQuery, setSchoolQuery] = useState({
    schoolName: "",
  });

  let content;

  const [
    schoolsWithoutUserQueryTrigger,
    { data: schoolsWithNoUser, isLoading, isSuccess, isError, error },
  ] = useLazyGetSchoolsWithoutUsersQuery();

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const [validationErrors, setValidationErrors] = useState({
    schoolName: "",
    lastName: "",
    isValid: true,
    errorCount: 0,
  });

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setSchoolQuery({
        ...schoolQuery,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let schoolName = "";
    let lastName = "";
    let errorCount = 0;

    if (schoolQuery.schoolName.trim() === "") {
      schoolName = "Please enter a School Name";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        schoolName,
        lastName,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      schoolName: "",
      lastName: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const resetForm = () => {
    setSchoolQuery({
      schoolName: "",
    });
  };

  const onSearchClicked = async () => {
    try {
      setOpen(true);
      if (validateForm()) {
        resetValidationErrors();
        await schoolsWithoutUserQueryTrigger({
          schoolName: schoolQuery.schoolName,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const onGetAllClicked = async () => {
    try {
      setOpen(true);

      resetValidationErrors();
      resetForm();
      await schoolsWithoutUserQueryTrigger({
        schoolName: "",
        rowsPerPage: 10,
        page: 0,
      });
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  const handlePersonSelectClicked = ({ e, entityId, schoolId }) => {
    e.preventDefault();
    navigate("/schoolsUserAddSearchViewAdmin", {
      state: {
        entityId: entityId,
        schoolId: schoolId,
      },
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Container component="main" maxWidth="sm">
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Search For Schools to Create a User
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="schoolName"
                name="schoolName"
                label="School Name"
                variant="outlined"
                value={schoolQuery.schoolName}
                onChange={handleChange}
                error={validationErrors.schoolName !== ""}
                helperText={validationErrors.schoolName}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
          <EduxooSearchButton handleSubmit={onSearchClicked} />
          <EduxooGetAllButton handleSubmit={onGetAllClicked} />
        </Box>

        <Container sx={{ marginTop: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>School</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schoolsWithNoUser?._embedded?.users?.map((user) => {
                  return (
                    <TableRow key={user.schoolId}>
                      <TableCell align="left">{user.schoolName}</TableCell>
                      <TableCell align="left">{user.firstName}</TableCell>
                      <TableCell align="left">{user.lastName}</TableCell>

                      <TableCell>
                        <Button
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                          //TODO className={classes.button}
                          onClick={(e) =>
                            handlePersonSelectClicked({
                              e,
                              entityId: user.personId,
                              schoolId: user.schoolId,
                            })
                          }
                        >
                          SELECT
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
    </>
  );
};

export default SchoolsUsersAddSearchAdmin;
