import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { selectCurrentSelectedSchool } from "../auths/authSlice";

import { useAddUserStudentMutation, useGetPersonQuery } from "./apiUsersSlice";

export const UsersAddStudentSearchView = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const school = useSelector(selectCurrentSelectedSchool);

  const [
    addUser,
    { isLoading, isSuccess, isError, error },
  ] = useAddUserStudentMutation();

  const [userAddForm, setUserAddForm] = useState({
    username: "",
    password: "",
    schoolId: school.id,
    schoolYearId: "",
    schoolClassId: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    username: "",
    password: "",
    errorCount: 0,
    isValid: true,
  });

  const {
    data: person,
    isLoading: isLoadingPerson,
    isSuccess: isSuccessPerson,
    isError: isErrorPerson,
    error: errorPerson,
  } = useGetPersonQuery(location.state.entityUrl);

  let content;

  let usernameSelectList = [];

  if (isLoadingPerson) {
    content = <EduxooBackdrop isLoading={isLoadingPerson} />;
  } else if (isSuccessPerson) {
    let elementsList = person
      ? {
          title: "Person Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                {
                  textName: "First Name",
                  textValue: person.firstName,
                },
                {
                  textName: "Last Name",
                  textValue: person.lastName,
                },
                {
                  textName: "Email(Personal)",
                  textValue: person.emailPersonal,
                },
                {
                  textName: "Email(Work)",
                  textValue: person.emailWork,
                },
              ],
            },
          ],
        }
      : [];

    if (person.emailPersonal.trim() !== "") {
      usernameSelectList.push(
        <MenuItem value={person.emailPersonal} key={1}>
          {person.emailPersonal}
        </MenuItem>
      );
    }

    if (person.emailWork.trim() !== "") {
      usernameSelectList.push(
        <MenuItem value={person.emailWork} key={2}>
          {person.emailWork}
        </MenuItem>
      );
    }

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isErrorPerson) {
    content = <div>{errorPerson.toString}</div>;
  }

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let username = "";
    let password = "";

    if (userAddForm.username.trim() === "") {
      username = "Please enter a username";
      isValid = false;
      errorCount++;
    }

    if (userAddForm.password.trim() === "") {
      password = "Please enter a password";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        username,
        password,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      username: "",
      password: "",
    });
  };

  const onUsersAddClicked = async (id) => {
    try {
      console.log("userAddForm", userAddForm);
      console.log("personId", id);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await addUser({
          personId: id,
          schoolId: school.id,
          username: userAddForm.username,
          password: userAddForm.password,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
          isValidEntry = true;
        } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
          isValidEntry = true;
        }*/

    if (isValidEntry) {
      setUserAddForm({
        ...userAddForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {};

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"User created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  console.log("usernameSelectList", usernameSelectList);

  return (
    <>
      <CssBaseline />
      {message}
      {content}
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={validationErrors.username !== ""}
              required
            >
              <InputLabel id="username-select">Username</InputLabel>
              <Select
                labelId="username-select"
                id="username"
                name="username"
                value={userAddForm.username}
                label="Username"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Username</MenuItem>;
                {usernameSelectList}
              </Select>
              <FormHelperText>{validationErrors.username}</FormHelperText>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="username"
            name="username"
            label="Username"
            margin="dense"
            value={userAddForm.username}
            onChange={handleChange}
            error={validationErrors.username !== ""}
            helperText={validationErrors.username}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="password"
            name="password"
            label="Password"
            margin="dense"
            value={userAddForm.password}
            onChange={handleChange}
            error={validationErrors.password !== ""}
            helperText={validationErrors.password}
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton
            handleSubmit={() => onUsersAddClicked(person.id)}
          />
        </Box>
      </Container>
    </>
  );
};

export default UsersAddStudentSearchView;
