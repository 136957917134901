import {
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import {
  EduxooGetAllButton,
  EduxooSearchButton,
} from "../../components/common/EduxooButton";
import { useGetAllSchoolsQuery } from "./apiSchoolsSlice";

export default function SchoolsSearch() {
  const theme = useTheme();

  const isLoading = false;

  const [validationErrors, setValidationErrors] = useState({
    searchValidationMessage: "",
    errorCount: 0,
    isValid: true,
  });

  const [searchCriteria, setSearchCriteria] = useState({
    name: "",
    schoolId: localStorage.getItem("selectedSchool"),
  });

  const handleChange = (e) => {
    setSearchCriteria({
      ...searchCriteria,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmitforSchoolSearch");
  };

  const handleReadAllClick = (e) => {
    e.preventDefault();
    console.log("handleReadAllClick");

    /*this.props.readAllSchools();
    this.resetValidationErrors();
    this.resetSearchCriteria();
    this.setState({
      isFirstSearch: false,
    });*/
  };

  return (
    <>
      <CssBaseline />
      <EduxooBackdrop isLoading={isLoading} />

      <Typography variant="h4" className={theme.title}>
        School Search
      </Typography>

      <Container maxWidth="md" className={theme.searchContainer}>
        <Paper>
          <div>
            <TextField
              required
              error={validationErrors.searchValidationMessage !== ""}
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={searchCriteria.name}
              onChange={handleChange}
              helperText={validationErrors.searchValidationMessage}
              margin="dense"
              style={{ minWidth: "500px", marginTop: "21px" }}
            />
          </div>
          <div className={theme.grow}>
            <EduxooSearchButton handleSubmit={handleSubmit} />
            <EduxooGetAllButton handleSubmit={handleReadAllClick} />
          </div>
        </Paper>
      </Container>
      {/*<Container maxWidth="md" className={classes.container}>
          {
            <SchoolSearchList
              isSearchValidationOk={this.state.validationErrors.isValid}
              isFirstSearch={this.state.isFirstSearch}
              addViewButton={addViewButton}
              addUpdateButton={addUpdateButton}
              addDeleteButton={addDeleteButton}
              onNavigate={this.onNavigate}
              links={this.props.links}
              pageInfo={this.props.pageInfo}
              navigationInfo={navigationInfo}
              searchCriteriaString={this.state.searchCriteriaString}
            />
          }
        </Container>*/}
    </>
  );
}
