import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

export default function UsersMain() {
  const tiers = [
    [
      {
        title: "Users - Parent",
        id: "UsersParentCreate",
        subheader: "Create",
        description: ["You can add a new User by using this form"],
        buttonText: "Add a new User",
        buttonVariant: "contained",
        to: "/usersAddParentSearch",
      },
      {
        title: "Users - Parent",
        id: "UsersParentView",
        subheader: "View or Update",
        description: ["You can view and update by using this form"],
        buttonText: "View all your Users",
        buttonVariant: "outlined",
        to: "/usersList",
      },
    ],
    [
      {
        title: "Users - Teacher",
        id: "UsersTeacherCreate",
        subheader: "Create",
        description: ["You can add a new User by using this form"],
        buttonText: "Add a new Teacher User",
        buttonVariant: "contained",
        to: "/usersAddTeacherSearch",
      },
      {
        title: "Users - Teacher",
        id: "UsersTeacherView",
        subheader: "View or Update",
        description: ["You can view and update Teacher(s) by using this form"],
        buttonText: "View all your Teacher Users",
        buttonVariant: "outlined",
        to: "/usersTeachersList",
      },
    ],
    [
      {
        title: "Users - Student",
        id: "UsersStudentCreate",
        subheader: "Create",
        description: ["You can add a new User by using this form"],
        buttonText: "Add a new Student User",
        buttonVariant: "contained",
        to: "/usersAddStudentSearch",
      },
      {
        title: "Users - Student",
        id: "UsersStudentView",
        subheader: "View or Update",
        description: ["You can view and update Teacher(s) by using this form"],
        buttonText: "View all your Teacher Users",
        buttonVariant: "outlined",
        to: "/usersTeachersList",
      },
    ],
  ];
  return <EduxooMainMenu tiers={tiers} />;
}
