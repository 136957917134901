import { apiSlice } from "../api/apiSlice";

export const apiEducationalRecordsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEducationalRecordsBySchoolClass: builder.query({
      query: (queryInfo) =>
        `/schoolclasses/${queryInfo.schoolClass}/educationalrecords?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["EducationalRecord"],
    }),
    getEducationalRecordByStudent: builder.query({
      query: (studentId) => `/students/${studentId}/educationalrecords`,
      providesTags: ["EducationalRecord"],
    }),
    getEducationalRecordsByParam: builder.query({
      query: (queryInfo) =>
        `/educationalrecords/search?firstName=${queryInfo.firstName}&lastName=${queryInfo.lastName}`,
      providesTags: ["EducationalRecord"],
    }),
    getEducationalRecord: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["EducationalRecord"],
    }),
    updateEducationalRecordForClass: builder.mutation({
      query: (updatedEducationalRecordInfo) => ({
        url: `/admissions/educationalrecords/${updatedEducationalRecordInfo.educationalRecordId}/class`,
        method: "PATCH",
        body: JSON.stringify({
          note: updatedEducationalRecordInfo.note,
          newSchoolClassId: updatedEducationalRecordInfo.newSchoolClassId,
        }),
      }),
      invalidatesTags: ["SchoolClass"],
    }),
  }),
});

export const {
  useGetEducationalRecordsBySchoolClassQuery,
  useGetEducationalRecordByStudentQuery,
  useLazyGetEducationalRecordsByParamQuery,
  useGetEducationalRecordQuery,
  useUpdateEducationalRecordForClassMutation,
} = apiEducationalRecordsSlice;
