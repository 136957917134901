import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useAddAdmissionByClassMutation } from "./apiAdmissionsSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import { useGetSchoolClassesBySchoolYearQuery } from "../schoolclasses/apiSchoolClassesSlice";

export const AdmissionsAddByClass = () => {
  const [
    addAdmission,
    { isLoading, isSuccess, isError, error },
  ] = useAddAdmissionByClassMutation();
  const [open, setOpen] = useState(false);

  const school = useSelector(selectCurrentSelectedSchool);

  const [admission, setAdmission] = useState({
    schoolId: school.id,
    schoolYearId: "",
    schoolClassId: "",
    name: "",
    startDate: null,
    endDate: null,
  });

  let content;

  const resetForm = () => {
    setAdmission({
      schoolYearId: "",
      schoolClassId: "",
      name: "",
      startDate: null,
      endDate: null,
    });
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Admission Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const {
    data: schoolYears,
    error: errorSy,
    status: statusSy,
  } = useGetSchoolYearsBySchoolQuery(
    { school: admission.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: admission.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList = schoolYears?._embedded?.schoolYears.map(
    (schoolYear) => {
      return (
        <MenuItem value={schoolYear.id} key={schoolYear.id}>
          {schoolYear.name}
        </MenuItem>
      );
    }
  );

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(admission?.schoolYearId, {
    skip: admission.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    schoolYearId: "",
    schoolId: "",
    schoolClassId: "",
    startDate: "",
    endDate: "",
    endDateNotGreaterThan: "",
    errorCount: 0,
    isValid: true,
  });

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolId: "",
      schoolYearId: "",
      name: "",
      startDate: "",
      endDate: "",
      endDateNotGreaterThan: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let name = "";
    let schoolYearId = "";
    let schoolId = "";
    let startDate = "";
    let endDate = "";
    let endDateNotGreaterThan = "";

    if (admission.name.trim() === "") {
      name = "Please enter a Name";
      isValid = false;
      errorCount++;
    }

    if (admission.schoolYearId === "") {
      schoolYearId = "Please select an School Year";
      isValid = false;
      errorCount++;
    }

    if (admission.schoolId.trim() === "") {
      schoolId = "Please select a School";
      isValid = false;
      errorCount++;
    }

    if (
      (admission.startDate !== null &&
        admission.startDate.toString() === "Invalid Date") ||
      admission.startDate === null
    ) {
      console.log("startDate");
      startDate = "Please enter a valid Start Date";
      isValid = false;
      errorCount = errorCount + 1;
    }

    if (
      (admission.endDate !== null &&
        admission.endDate.toString() === "Invalid Date") ||
      admission.endDate === null
    ) {
      endDate = "Please enter a valid End Date";
      isValid = false;
      errorCount = errorCount + 1;
    }

    if (!(startDate && endDate)) {
      if (admission.endDate <= admission.startDate) {
        endDateNotGreaterThan =
          "Please select an End date Greater than Start Date";

        isValid = false;
        errorCount++;
      }
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        schoolYearId,
        schoolId,
        name,
        startDate,
        endDate,
        endDateNotGreaterThan,
      });
    }

    return isValid;
  };

  const handleChange = (e) => {
    setAdmission({
      ...admission,
      [e.target.name]: e.target.value,
    });
  };

  const onSaveAdmissionClicked = async () => {
    try {
      if (validateForm()) {
        setOpen(true);
        resetValidationErrors();
        await addAdmission(admission);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleStartDateChange = (newDate) => {
    setAdmission({
      ...admission,
      startDate: dayjs(newDate).format("YYYY-MM-DD"),
    });
  };

  const handleEndDateChange = (newDate) => {
    setAdmission({
      ...admission,
      endDate: dayjs(newDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {content}
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Admission Create
          </Typography>
          <Typography variant="h6" gutterBottom>
            Main Details
          </Typography>

          <div style={{ marginTop: 10 }}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                error={validationErrors.schoolYearId !== ""}
                required
              >
                <InputLabel id="schoolyear-select">School Year</InputLabel>
                <Select
                  labelId="schoolyear-select"
                  id="schoolYearId"
                  name="schoolYearId"
                  value={admission.schoolYearId}
                  label="School Year"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>Please Select a School Year</MenuItem>
                  {schoolYearsSelectList}
                </Select>
                <FormHelperText>{validationErrors.schoolYearId}</FormHelperText>
              </FormControl>
            </Box>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="schoolclass-select">School Class</InputLabel>
                <Select
                  labelId="schoolclass-select"
                  id="schoolClassId"
                  name="schoolClassId"
                  value={admission.schoolClassId}
                  label="School Year"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>Please Select a Class</MenuItem>
                  {schoolClassesSelectList}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div style={{ marginTop: 10 }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={admission.name}
              onChange={handleChange}
              error={validationErrors.name !== ""}
              helperText={validationErrors.name}
              margin="dense"
            />
          </div>
          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en-gb"}
            >
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  value={admission.startDate}
                  format="DD-MM-YYYY"
                  onChange={(newStartDate) =>
                    handleStartDateChange(newStartDate)
                  }
                  slotProps={{
                    textField: {
                      required: true,
                      error:
                        validationErrors.startDate !== "" ||
                        validationErrors.endDateNotGreaterThan != "",
                      helperText:
                        validationErrors.startDate +
                        validationErrors.endDateNotGreaterThan,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en-gb"}
            >
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date"
                  value={admission.endDate}
                  format="DD-MM-YYYY"
                  onChange={(newEndDate) => handleEndDateChange(newEndDate)}
                  slotProps={{
                    textField: {
                      required: true,
                      error:
                        validationErrors.endDate !== "" ||
                        validationErrors.endDateNotGreaterThan != "",
                      helperText:
                        validationErrors.endDate +
                        validationErrors.endDateNotGreaterThan,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveAdmissionClicked} />
        </Box>
      </Container>
    </>
  );
};

export default AdmissionsAddByClass;
