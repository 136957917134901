import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EduxooBackButton } from "../../../../components/common/EduxooButton";
import { EduxooViewGenerator } from "../../../../components/common/EduxooViewGenerator";
import { useGetSchoolQuery } from "../../../schools/apiSchoolsSlice";

export default function SchoolsViewAdmin() {
  const location = useLocation();

  const {
    data: school,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolQuery(location.state.entityUrl);

  let elementsList = school
    ? {
        title: "School Information",
        elements: [
          {
            title: "Main Details",
            subElements: [
              { textName: "Name", textValue: school.name },
              { textName: "Phone", textValue: school.phone },
              { textName: "Fax", textValue: school.fax },
              { textName: "Email", textValue: school.email },
              { textName: "Website", textValue: school.website },
            ],
          },
          {
            title: "Address Details",
            subElements: [
              { textName: "Address 1", textValue: school.address.address1 },
              { textName: "Address 2", textValue: school.address.address2 },
              { textName: "Address 3", textValue: school.address.address3 },
              { textName: "City or Town", textValue: school.address.town },
              { textName: "County", textValue: school.address.county },
              { textName: "Postal Code", textValue: school.address.postcode },
            ],
          },
        ],
      }
    : [];

  return (
    <>
      <CssBaseline />
      {school ? <EduxooViewGenerator elementsList={elementsList} /> : "Nothing"}
    </>
  );
}
