import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

const tiers = [
  [
    {
      title: "Student",
      id: "StudentCreate",
      subheader: "Create",
      price: "0",
      description: ["You can add new Student by using this form"],
      buttonText: "Add a new Student",
      buttonVariant: "contained",
      to: "/studentsAdd",
    },
    {
      title: "Student",
      id: "StudentView",
      subheader: "View or Update",
      price: "15",
      description: ["You can view and update by using this form"],
      buttonText: "View all your students",
      buttonVariant: "outlined",
      to: "/studentsList",
    },
    {
      title: "Student",
      id: "StudentRegister",
      subheader: "Register Student",
      price: "15",
      description: ["You can register a student by using this form"],
      buttonText: "Register a Student",
      buttonVariant: "outlined",
      to: "/studentsRegister",
    },
  ],
  [
    {
      title: "Student",
      id: "StudentChangeClass",
      subheader: "Change Student Class",
      price: "15",
      description: ["You can change students class by using this form"],
      buttonText: "Change Student",
      buttonVariant: "outlined",
      to: "/studentsClassChange",
    },
    {
      title: "Student",
      id: "StudentByClass",
      subheader: "View Students By Class",
      price: "15",
      description: ["You can view students by using this form"],
      buttonText: "View Students",
      buttonVariant: "outlined",
      to: "/studentsByClass",
    },
  ],
  [
    {
      title: "Parent",
      id: "ParentCreate",
      subheader: "Create",
      price: "0",
      description: ["You can add new Parent by using this form"],
      buttonText: "Add a new Parent",
      buttonVariant: "contained",
      to: "/parentsAdd",
    },
    {
      title: "Parent",
      id: "ParentView",
      subheader: "View & Update",
      price: "15",
      description: ["You can view and update by using this form"],
      buttonText: "View all your Parents",
      buttonVariant: "outlined",
      to: "/parentsList",
    },
  ],
];

export const StudentsMain = () => {
  return <EduxooMainMenu tiers={tiers} />;
};

export default StudentsMain;
