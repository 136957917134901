import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

const tiers = [
  [
    {
      title: "Register",
      id: "EducationRecordRegister",
      subheader: "Register",
      price: "15",
      description: ["You can register to a course"],
      buttonText: "Register",
      buttonVariant: "outlined",
      to: "/registrationsRegisterSearch",
    },
    {
      title: "Register",
      id: "EducationRecordRegisterView",
      subheader: "View",
      price: "15",
      description: ["You can view your active registrations"],
      buttonText: "View all your registrations",
      buttonVariant: "outlined",
      to: "/registrationsListSearch",
    },
  ],
];

export const RegistrationsMain = () => {
  return <EduxooMainMenu tiers={tiers} />;
};

export default RegistrationsMain;
