import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetSchoolQuery } from "../schools/apiSchoolsSlice";
import { useGetLocationQuery } from "./apiLocationsSlice";

export const LocationsView = () => {
  const location = useLocation();

  const {
    data: schoolLocation,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetLocationQuery(location.state.entityUrl);

  const {
    data: school,
    isLoading: isLoadingSchool,
    isSuccess: isSuccessSchool,
    isError: isErrorSchool,
    error: errorSchool,
  } = useGetSchoolQuery(schoolLocation?._links.school.href, {
    skip: schoolLocation?._links.school ? false : true,
  });

  let content;

  if (isLoadingSchool) {
    content = <EduxooBackdrop isLoading={isLoadingSchool} />;
  } else if (isSuccessSchool) {
    content = "";
  } else if (isErrorSchool) {
    content = <div>{errorSchool.toString}</div>;
  }

  let elementsList =
    schoolLocation && school
      ? {
          title: "School Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                { textName: "Id", textValue: schoolLocation.id },
                { textName: "Name", textValue: schoolLocation.name },
              ],
            },
            {
              title: "School Details",
              subElements: [
                { textName: "School Name", textValue: school.name },
                { textName: "Phone", textValue: school.phone },
                { textName: "Fax", textValue: school.fax },
                { textName: "Email", textValue: school.email },
                { textName: "Website", textValue: school.website },
              ],
            },
            {
              title: "Address Details",
              subElements: [
                {
                  textName: "Address 1",
                  textValue: schoolLocation.address.address1,
                },
                {
                  textName: "Address 2",
                  textValue: schoolLocation.address.address2,
                },
                {
                  textName: "Address 3",
                  textValue: schoolLocation.address.address3,
                },
                {
                  textName: "City or Town",
                  textValue: schoolLocation.address.town,
                },
                {
                  textName: "County",
                  textValue: schoolLocation.address.county,
                },
                {
                  textName: "Postal Code",
                  textValue: schoolLocation.address.postcode,
                },
              ],
            },
          ],
        }
      : [];

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess && isSuccessSchool) {
    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default LocationsView;
