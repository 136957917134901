import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useGetAdmissionsBySchoolQuery } from "../admissions/apiAdmissionsSlice";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import AdmissionRegistersAppSearchResult from "./AdmissionRegistersAppSearchResult";
import AdmissionRegistersListSearchResult from "./AdmissionRegistersListSearchResult";

export const AdmissionRegistersApplications = () => {
  const [searchQuery, setSearchQuery] = useState({
    schoolId: "",
    admissionId: "",
  });

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let content;
  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const {
    data: admissions,
    error: errorAdms,
    status: statusAdms,
  } = useGetAdmissionsBySchoolQuery(
    { school: searchQuery.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: searchQuery.schoolId ? false : true,
    }
  );

  let admissionsSelectList = admissions?._embedded?.admissions.map(
    (admission) => {
      return (
        <MenuItem value={admission.id} key={admission.id}>
          {admission.name}
        </MenuItem>
      );
    }
  );

  let message;

  const validateForm = () => {
    return true;
  };

  const resetValidationErrors = () => {};

  const onSaveSchoolClassClicked = async () => {
    try {
      console.log("searchQuery", searchQuery);
      if (validateForm()) {
        resetValidationErrors();
        //await addSchoolClass(schoolClass);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Admission Registers
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={searchQuery.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="admission-select">Admission</InputLabel>
                  <Select
                    labelId="admission-select"
                    id="admissionId"
                    name="admissionId"
                    value={searchQuery.admissionId}
                    label="Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select admission
                    </MenuItem>
                    {admissionsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Paper>
        </Container>
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Paper>
            {searchQuery?.admissionId ? (
              <AdmissionRegistersAppSearchResult
                admissionId={searchQuery.admissionId}
              />
            ) : (
              <div>waiting </div>
            )}
          </Paper>
        </Container>
      </Container>
    </>
  );
};

export default AdmissionRegistersApplications;
