import { useEffect, useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import { useGetSchoolClassesBySchoolYearQuery } from "../schoolclasses/apiSchoolClassesSlice";
import { useGetEducationalRecordsBySchoolClassQuery } from "../educationalrecords/apiEducationalRecordsSlice";
import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";

export const StudentsByClass = () => {
  const [selectInfo, setSelectInfo] = useState({
    schoolYearId: "",
    schoolId: "",
    schoolClassId: "",
  });

  useEffect(() => {
    let useEffectSelectInfo = window.localStorage.getItem("selectInfo");
    if (useEffectSelectInfo !== null) {
      setSelectInfo(JSON.parse(window.localStorage.getItem("selectInfo")));
    }
  }, []);

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let content;
  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    content = "";
    schoolsSelectList = schools?._embedded?.schools?.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const {
    data: schoolYears,
    isLoading: isLoadingSY,
    isSuccess: isSuccessSY,
    isError: isErrorSY,
    error: errorSY,
  } = useGetSchoolYearsBySchoolQuery(
    { school: selectInfo?.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: selectInfo?.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList;

  if (isLoadingSY) {
    content = <EduxooBackdrop isLoading={isLoadingSY} />;
  } else if (isSuccessSY) {
    schoolYearsSelectList = schoolYears?._embedded?.schoolYears?.map(
      (schoolYear) => {
        return (
          <MenuItem key={schoolYear.id} value={schoolYear.id}>
            {schoolYear.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSY) {
    content = <div>{errorSY.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(selectInfo?.schoolYearId, {
    skip: selectInfo?.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: educationalRecords,
    isLoading: isLoadingEducationalRecords,
    isSuccess: isSuccessEducationalRecords,
    isError: isErrorEducationalRecords,
    error: errorEducationalRecords,
  } = useGetEducationalRecordsBySchoolClassQuery(
    {
      schoolClass: selectInfo?.schoolClassId,
      rowsPerPage,
      page,
    },
    {
      skip: selectInfo?.schoolClassId ? false : true,
      refetchOnMountOrArgChange: true,
    }
  );

  let pageInfo = {
    pageInfo: educationalRecords?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 5,
  };

  const entityHeaderList = [
    "Student Ref No",
    "Firt Name",
    "Last Name",
    "School",
    "School Year",
    "Class",
  ];

  const entityValues = educationalRecords?._embedded?.educationalRecords.map(
    (educationalRecord) => {
      //let id = admission.id;
      let refNo = educationalRecord.studentRef;
      let firstName = educationalRecord.studentFirstName;
      let lastName = educationalRecord.studentLastName;
      let school = educationalRecord.schoolName;
      let schoolYear = educationalRecord.schoolYearName;
      let schoolClass = educationalRecord.schoolClassName;

      return {
        values: [refNo, firstName, lastName, school, schoolYear, schoolClass],
        links: educationalRecord._links.self.href,
      };
    }
  );

  if (isLoadingEducationalRecords) {
    content = <EduxooBackdrop isLoading={isLoadingEducationalRecords} />;
  } else if (isSuccessEducationalRecords) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/studentsView" },
            { type: "Update", to: "/admissionsUpdate" },
          ],
        }}
      />
    );
    window.localStorage.setItem("selectInfo", JSON.stringify(selectInfo));
  } else if (isErrorEducationalRecords) {
    content = <div>{errorEducationalRecords.toString}</div>;
  }

  const handleChange = (e) => {
    let isValidEntry = false;

    if (e.target.value !== "") {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSelectInfo({
        ...selectInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  let message;

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}

          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Students By School Class
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={selectInfo.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="schoolyear-select">School Year</InputLabel>
                  <Select
                    labelId="schoolyear-select"
                    id="schoolYearId"
                    name="schoolYearId"
                    value={selectInfo.schoolYearId}
                    label="School Year"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a School Year</MenuItem>
                    {schoolYearsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="schoolclass-select">School Class</InputLabel>
                  <Select
                    labelId="schoolclass-select"
                    id="schoolClassId"
                    name="schoolClassId"
                    value={selectInfo.schoolClassId}
                    label="School Year"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Class</MenuItem>
                    {schoolClassesSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Paper>
        </Container>
      </Container>
      <Container maxWidth="md" style={{ marginTop: 10 }}>
        {content}
      </Container>
    </>
  );
};

export default StudentsByClass;
