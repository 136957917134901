import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

const tiers = [
  [
    {
      title: "Children",
      id: "ChildrenView",
      subheader: "View or Update",
      price: "15",
      description: ["You can view and update by using this form"],
      buttonText: "View all your children",
      buttonVariant: "outlined",
      to: "/childrenList",
    },
    {
      title: "Children",
      id: "ChildrenRegister",
      subheader: "Register",
      price: "15",
      description: ["You can register using this form"],
      buttonText: "Register all your children",
      buttonVariant: "outlined",
      to: "/childrenRegister",
    },
  ],
  [
    {
      title: "Children",
      id: "ChildrenView",
      subheader: "View or Update",
      price: "15",
      description: ["You can view and update Registrations by using this form"],
      buttonText: "View all Registration History",
      buttonVariant: "outlined",
      to: "/parentRegistrationHistory",
    },
  ],
];

export const ParentsMain = () => {
  return <EduxooMainMenu tiers={tiers} />;
};

export default ParentsMain;
