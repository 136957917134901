import { apiSlice } from "../api/apiSlice";

export const apiSchoolClassesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSchoolClass: builder.mutation({
      query: (initialSchoolClass) => ({
        url: "/schoolclasses",
        method: "POST",
        body: initialSchoolClass,
      }),
      invalidatesTags: ["SchoolClass"],
    }),
    getSchoolClasses: builder.query({
      query: (customPageable) =>
        `/schoolclasses?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["SchoolClass"],
    }),
    getSchoolClass: builder.query({
      query: (schoolClassUrl) => `${schoolClassUrl}`,
      providesTags: ["SchoolClass"],
    }),
    getSchoolClassesBySchoolYear: builder.query({
      query: (schoolYearId) => `/schoolyears/${schoolYearId}/schoolclasses`,
      providesTags: ["SchoolClass"],
    }),
    getSchoolClassesBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/schoolclasses?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["SchoolClass"],
    }),
    getSchoolClassesBySchoolYearIdAndTeacherId: builder.query({
      query: (queryInfo) =>
        `/schoolyears/${queryInfo.schoolYearId}/teachers/${queryInfo.teacherId}/schoolclasses?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["SchoolClass"],
    }),
  }),
});

export const {
  useGetSchoolClassesBySchoolQuery,
  useGetSchoolClassQuery,
  useGetSchoolClassesQuery,
  useGetSchoolClassesBySchoolYearQuery,
  useAddSchoolClassMutation,
  useGetSchoolClassesBySchoolYearIdAndTeacherIdQuery,
} = apiSchoolClassesSlice;
