import { styled } from "@mui/material/styles";
import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  EduxooListRegisterButton,
  EduxooListUpdateButton,
  EduxooListViewButton,
} from "./EduxooButton";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import EduxooTablePagination from "./EduxooTablePagination";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    fontSize: 8,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const EduxooCustomTable = (props) => {
  const { entityList, entityHeaderList, pageInfo } = props;

  const navigate = useNavigate();

  const handleClick = (e, entityUrl, entityTo) => {
    e.preventDefault();
    if (e.currentTarget.id === "Update") {
      navigate(entityTo, {
        state: {
          entityUrl: entityUrl,
        },
      });
    } else if (e.currentTarget.id === "View") {
      navigate(entityTo, {
        state: {
          entityUrl: entityUrl,
        },
      });
    } else if (e.currentTarget.id === "Register") {
      navigate(entityTo, {
        state: {
          entityUrl: entityUrl,
        },
      });
    } else if (e.currentTarget.id === "Delete") {
      console.log("schoolClassroomDelete");
    }
  };

  let customCounter = 0;

  return (
    <Container maxWidth="md" style={{ marginTop: 10 }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {entityHeaderList?.map((entityHeader) => {
                return (
                  <StyledTableCell align="left" key={entityHeader}>
                    {entityHeader}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {entityList?.values?.map((entity) => {
              customCounter += 1;
              return (
                <StyledTableRow key={`rowie_${customCounter}`}>
                  {entity?.values?.map((cellValue) => {
                    customCounter += 1;
                    return (
                      <StyledTableCell key={`columnie_${customCounter}`}>
                        {cellValue}
                      </StyledTableCell>
                    );
                  })}
                  {entityList?.buttons?.map((buttonCell) => {
                    if (buttonCell.type === "View") {
                      customCounter += 1;
                      return (
                        <StyledTableCell key={`columnie_${customCounter}`}>
                          <EduxooListViewButton
                            handleSubmit={(e) =>
                              handleClick(e, entity.links, buttonCell.to)
                            }
                          />
                        </StyledTableCell>
                      );
                    } else if (buttonCell.type === "Update") {
                      customCounter += 1;
                      return (
                        <StyledTableCell key={`columnie_${customCounter}`}>
                          <EduxooListUpdateButton
                            handleSubmit={(e) =>
                              handleClick(e, entity.links, buttonCell.to)
                            }
                          />
                        </StyledTableCell>
                      );
                    } else if (buttonCell.type === "Register") {
                      customCounter += 1;
                      return (
                        <StyledTableCell key={`columnie_${customCounter}`}>
                          <EduxooListRegisterButton
                            handleSubmit={(e) =>
                              handleClick(e, entity.links, buttonCell.to)
                            }
                          />
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <EduxooTablePagination pageInfo={pageInfo} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};
