import { apiSlice } from "../api/apiSlice";

export const apiUsersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorizedSchoolsByUser: builder.query({
      query: () => "/users/schools",
      providesTags: ["School"],
    }),
    getPersonsWithoutUsers: builder.query({
      query: (queryInfo) =>
        `/persons/search?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}&firstName=${queryInfo.firstName}&lastName=${queryInfo.lastName}`,
      providesTags: ["PersonsWithoutUsers"],
    }),
    getPerson: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["PersonWithoutUser"],
    }),
    addUser: builder.mutation({
      query: (newUser) => ({
        url: "/users",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    getTeachersWithoutUsers: builder.query({
      query: (queryInfo) =>
        `/teachers/search?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}&firstName=${queryInfo.firstName}&lastName=${queryInfo.lastName}`,
      providesTags: ["TeachersWithoutUsers"],
    }),
    addUserTeacher: builder.mutation({
      query: (newUser) => ({
        url: "/users/teachers",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    addUserStudent: builder.mutation({
      query: (newUser) => ({
        url: "/users/students",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    getStudentsWithoutUsers: builder.query({
      query: (queryInfo) =>
        `/users/students/nouser?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}&schoolId=${queryInfo.schoolId}&firstName=${queryInfo.firstName}&lastName=${queryInfo.lastName}`,
      providesTags: ["StudentssWithoutUsers"],
    }),
  }),
});

export const {
  useGetAuthorizedSchoolsByUserQuery,
  useGetPersonQuery,
  useLazyGetPersonsWithoutUsersQuery,
  useLazyGetTeachersWithoutUsersQuery,
  useLazyGetStudentsWithoutUsersQuery,
  useAddUserMutation,
  useAddUserTeacherMutation,
  useAddUserStudentMutation,
} = apiUsersSlice;
