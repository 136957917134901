import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentRole,
  selectCurrentToken,
} from "../../features/auths/authSlice";
import EduxooSchoolBar from "./EduxooSchoolBar";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function EduxooNavbar() {
  const token = useSelector(selectCurrentToken);
  const role = useSelector(selectCurrentRole);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let pages;

  if (role === "EDUXOO_ADMIN") {
    pages = [
      { name: "Home", link: "/welcomeHome" },
      { name: "Schools", link: "/schoolsMainAdmin" },
      { name: "Logs", link: "/activityLogsMain" },
      { name: "System", link: "/eduxooSystemMain" },
      { name: "Imports", link: "/importsExcel" },
    ];
  } else if (role === "EDUXOO_SCHOOL_ADMIN") {
    pages = [
      { name: "Home", link: "/welcomeHomeSchoolAdmin" },
      { name: "Schools", link: "/schoolsMain" },
      { name: "Teachers", link: "/teachersMain" },
      { name: "Years", link: "/schoolYearsMain" },
      { name: "Students", link: "/studentsMain" },
      { name: "Users", link: "/usersMain" },
    ];
  } else if (role === "EDUXOO_USER_PARENT") {
    pages = [
      { name: "Home", link: "/" },
      { name: "Parents", link: "/parentsMain" },
    ];
  } else if (role === "EDUXOO_USER") {
    pages = [
      { name: "Home", link: "/welcome" },
      { name: "Registrations", link: "/registrationsMain" },
      { name: "Family", link: "/parentsMain" },
    ];
  } else if (role === "EDUXOO_TEACHER") {
    pages = [
      { name: "Home", link: "/welcome" },
      { name: "Classes", link: "/userTeachersMain" },
    ];
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            EDUXOO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages?.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.link} style={{ textDecoration: "none" }}>
                    <Typography style={{ color: "#757ce8" }}>
                      {page.name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            EDUXOO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {token
              ? pages?.map((page) => (
                  <Button
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    <Link to={page.link} style={{ textDecoration: "none" }}>
                      <Typography style={{ color: "white" }}>
                        {page.name}
                      </Typography>
                    </Link>
                  </Button>
                ))
              : console.log("Nothing to see")}
          </Box>

          <Box sx={{ flexGrow: 0, alignItems: "center" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            <Typography
              gutterBottom
              component="div"
              align="center"
              sx={{ color: "#FFFFFF" }}
            >
              {token ? (
                <Link to="/logout">Logout</Link>
              ) : (
                <Link to="/signin">Login</Link>
              )}
            </Typography>
          </Button>
        </Toolbar>
      </Container>
      <Container>
        <EduxooSchoolBar />
      </Container>
    </AppBar>
  );
}
export default EduxooNavbar;
