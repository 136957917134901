import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";

export default function EduxooTablePagination({ pageInfo }) {
  const handleChangePage = (event, newPage) => {
    pageInfo.customSetPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pageInfo.customSetRowsPerPage(parseInt(event.target.value, 10));
    pageInfo.customSetPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 15, 20, { label: "All", value: -1 }]}
      colSpan={pageInfo.colSpan}
      count={pageInfo.pageInfo.totalElements}
      page={pageInfo.customPage}
      onPageChange={handleChangePage}
      rowsPerPage={pageInfo.customRowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
