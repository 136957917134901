import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
  reForNames,
  reAlphabetic,
} from "../../components/common/FormValueValidators";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useAddNewClassroomMutation } from "./apiClassroomsSlice";
import { useGetLocationsBySchoolQuery } from "../locations/apiLocationsSlice";
import { useSelector } from "react-redux";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import {
  selectCurrentSchool,
  selectCurrentSelectedSchool,
} from "../auths/authSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";

export const ClassroomsAdd = () => {
  const [
    addClassroom,
    { isLoading, isSuccess, isError, error },
  ] = useAddNewClassroomMutation();

  const [open, setOpen] = useState(false);
  const school = useSelector(selectCurrentSelectedSchool);

  const [classroom, setClassroom] = useState({
    name: "",
    locationId: "",
    level: "",
    schoolId: school.id,
    minCapacity: 0,
    maxCapacity: 0,
  });

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let content;
  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    content = "";
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const { data: locations } = useGetLocationsBySchoolQuery(
    {
      school: classroom.schoolId,
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      skip: classroom.schoolId ? false : true,
    }
  );

  const locationSelectContent = locations?._embedded?.locations?.map(
    (location) => {
      return (
        <MenuItem key={location.id} value={location.id}>
          {location.name}
        </MenuItem>
      );
    }
  );

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    level: "",
    minCapacity: "",
    maxCapacity: "",
    maxCapacitySmallerThanMinCapacity: "",
    locationId: "",
    schoolId: "",
    isValid: true,
    errorCount: 0,
  });

  const resetForm = () => {
    setClassroom({
      ...classroom,
      name: "",
      locationId: "",
      level: "",
      minCapacity: "",
      maxCapacity: "",
    });
  };

  const handleChange = (e) => {
    let isValidEntry = false;

    if (e.target.name === "locationId") {
      isValidEntry = true;
    } else if (e.target.name === "schoolId") {
      isValidEntry = true;
    } else if (
      e.target.name === "name" &&
      reAlphaNumeric.test(e.target.value)
    ) {
      isValidEntry = true;
    } else if (
      e.target.name === "level" &&
      reAlphaNumeric.test(e.target.value)
    ) {
      isValidEntry = true;
    } else if (
      e.target.name === "minCapacity" &&
      reNumeric.test(e.target.value)
    ) {
      isValidEntry = true;
    } else if (
      e.target.name === "maxCapacity" &&
      reNumeric.test(e.target.value)
    ) {
      isValidEntry = true;
    } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setClassroom({
        ...classroom,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSaveLocationClicked = async () => {
    try {
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await addClassroom(classroom);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const validateForm = () => {
    let isValid = true;

    let name = "";
    let locationId = "";
    let minCapacity = "";
    let maxCapacity = "";
    let maxCapacitySmallerThanMinCapacity = "";

    let errorCount = 0;

    if (classroom.locationId.trim() === "") {
      locationId = "Please, Select a Location";
      isValid = false;
      errorCount++;
    }

    if (parseInt(classroom.minCapacity) <= 0) {
      minCapacity = "Please, enter a valid Min Capacity";
      isValid = false;
      errorCount++;
    }

    if (parseInt(classroom.maxCapacity) <= 0) {
      maxCapacity = "Please, enter a valid Max Capacity";
      isValid = false;

      errorCount++;
    }

    if (parseInt(classroom.maxCapacity) < parseInt(classroom.minCapacity)) {
      maxCapacitySmallerThanMinCapacity =
        "Please, enter a Max Capacity Greater than Min Capacity";
      isValid = false;
      errorCount++;
    }

    if (classroom.name.trim() === "") {
      name = "Please enter a First Name";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        locationId,
        minCapacity,
        maxCapacity,
        maxCapacitySmallerThanMinCapacity,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      locationId: "",
      minCapacity: "",
      maxCapacity: "",
      maxCapacitySmallerThanMinCapacity: "",
      isValid: true,
      errorCount: 0,
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Classroom is Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Classroom Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>
            {/*<div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.schoolId !== ""}
                  required
                >
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={classroom.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.schoolId}</FormHelperText>
                </FormControl>
              </Box>
            </div>*/}

            <div>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.locationId !== ""}
                  required
                >
                  <InputLabel id="demo-simple-select-label">
                    Location
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="locationId"
                    value={classroom.locationId}
                    label="Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Location</MenuItem>
                    {locationSelectContent}
                  </Select>
                  <FormHelperText>{validationErrors.locationId}</FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                value={classroom.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
                margin="dense"
                inputProps={{ maxLength: 150 }}
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="level"
                name="level"
                label="Level"
                variant="outlined"
                value={classroom.level}
                onChange={handleChange}
                error={validationErrors.level !== ""}
                helperText={validationErrors.level}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                required
                id="minCapacity"
                name="minCapacity"
                label="Min Capacity"
                variant="outlined"
                value={classroom.minCapacity}
                onChange={handleChange}
                error={validationErrors.minCapacity !== ""}
                helperText={validationErrors.minCapacity}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                required
                id="maxCapacity"
                name="maxCapacity"
                label="Max Capacity"
                variant="outlined"
                value={classroom.maxCapacity}
                onChange={handleChange}
                error={
                  validationErrors.maxCapacity !== "" ||
                  validationErrors.maxCapacitySmallerThanMinCapacity !== ""
                }
                helperText={
                  validationErrors.maxCapacity +
                  validationErrors.maxCapacitySmallerThanMinCapacity
                }
                margin="dense"
              />
            </div>
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveLocationClicked} />
        </Box>
      </Container>
    </>
  );
};

export default ClassroomsAdd;
