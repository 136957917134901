import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import { useGetAdmissionsBySchoolYearQuery } from "../admissions/apiAdmissionsSlice";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetSchoolClassesBySchoolYearQuery } from "../schoolclasses/apiSchoolClassesSlice";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import {
  useGetStudentsBySchoolQuery,
  useRegisterStudentMutation,
} from "./apiStudentsSlice";

export const StudentsRegister = () => {
  const [open, setOpen] = useState(false);

  const school = useSelector(selectCurrentSelectedSchool);

  const [registrationForm, setRegistrationForm] = useState({
    schoolId: school.id,
    studentId: "",
    schoolYearId: "",
    schoolClassId: "",
    occupiedCapacity: 0,
    maxCapacity: 0,
    id: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    schoolId: "",
    studentId: "",
    schoolYearId: "",
    schoolClassId: "",
    id: "",
    errorCount: 0,
    isValid: true,
  });

  const [
    registerStudent,
    { isLoading, isSuccess, isError, error },
  ] = useRegisterStudentMutation();

  {
    /*const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();
  let schoolsSelectList;


  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem key={school.id} value={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  } */
  }

  let content;

  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
    isError: isErrorStudents,
    error: errorStudents,
  } = useGetStudentsBySchoolQuery(
    { school: registrationForm.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: registrationForm.schoolId ? false : true,
    }
  );

  let studentsSelectList;

  if (isLoadingStudents) {
    content = <EduxooBackdrop isLoading={isLoadingStudents} />;
  } else if (isSuccessStudents) {
    studentsSelectList = students?._embedded?.students.map((student) => {
      return (
        <MenuItem key={student.id} value={student.id}>
          {student.firstName + " " + student.lastName}
        </MenuItem>
      );
    });
  } else if (isErrorStudents) {
    content = <div>{errorStudents.toString}</div>;
  }

  const {
    data: schoolYears,
    isLoading: isLoadingSchoolYears,
    isSuccess: isSuccessSchoolYears,
    isError: isErrorSchoolYears,
    error: errorSchoolYears,
  } = useGetSchoolYearsBySchoolQuery(
    { school: registrationForm.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: registrationForm.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList;

  if (isLoadingSchoolYears) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolYears} />;
  } else if (isSuccessSchoolYears) {
    schoolYearsSelectList = schoolYears?._embedded?.schoolYears.map(
      (schoolYear) => {
        return (
          <MenuItem key={schoolYear.id} value={schoolYear.id}>
            {schoolYear.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolYears) {
    content = <div>{errorSchoolYears.toString}</div>;
  }

  const {
    data: admissions,
    isLoading: isLoadingAdms,
    isSuccess: isSuccessAdms,
    isError: isErrorAdms,
    error: errorAdms,
  } = useGetAdmissionsBySchoolYearQuery(
    {
      schoolYearId: registrationForm.schoolYearId,
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      skip: registrationForm.schoolYearId ? false : true,
    }
  );

  let admissionsSelectList;

  if (isLoadingAdms) {
    content = <EduxooBackdrop isLoading={isLoadingAdms} />;
  } else if (isSuccessAdms) {
    admissionsSelectList = admissions?._embedded?.admissions.map(
      (admission) => {
        return (
          <MenuItem value={admission.id} key={admission.id}>
            {admission.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorAdms) {
    content = <div>{errorAdms.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(registrationForm?.schoolYearId, {
    skip: registrationForm.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const resetForm = () => {
    setRegistrationForm({
      //schoolId: "",
      studentId: "",
      schoolYearId: "",
      schoolClassId: "",
      id: "",
      occupiedCapacity: 0,
      maxCapacity: 0,
    });
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolId: "",
      schoolYearId: "",
      schoolClassId: "",
      studentId: "",
      id: "",
    });
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
    isValidEntry = true;
  } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
    isValidEntry = true;
  }*/

    if (e.target.name === "schoolClassId") {
    }

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSchoolClassChange = (e) => {
    let isValidEntry = true;

    let selectedSchoolClass = schoolClasses._embedded.schoolClasses.find(
      (schoolClass) => schoolClass.id === e.target.value
    );

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
        occupiedCapacity: selectedSchoolClass.occupiedCapacity,
        maxCapacity: selectedSchoolClass.maxCapacity,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let schoolId = "";
    let schoolYearId = "";
    let schoolClassId = "";
    let studentId = "";

    if (registrationForm.schoolId.trim() === "") {
      schoolId = "Please enter a Name";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.schoolYearId === "") {
      schoolYearId = "Please select an Academic Year";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.schoolClassId.trim() === "") {
      schoolClassId = "Please select a Classroom";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.studentId.trim() === "") {
      studentId = "Please select a Teacher";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        schoolId,
        schoolYearId,
        schoolClassId,
        studentId,
      });
    }

    return isValid;
  };

  const onSaveRegisterStudentClicked = async () => {
    try {
      console.log("RegistrationForm", registrationForm);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await registerStudent(registrationForm);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Student Registered Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  return (
    <Container maxWidth="sm">
      {content}
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Register a Student
        </Typography>

        {/*<div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="school-select">School</InputLabel>
              <Select
                labelId="school-select"
                id="schoolId"
                name="schoolId"
                value={registrationForm.schoolId}
                label="School"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a School</MenuItem>
                {schoolsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>*/}
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="schoolyear-select">School Year</InputLabel>
              <Select
                labelId="schoolyear-select"
                id="schoolYearId"
                name="schoolYearId"
                value={registrationForm.schoolYearId}
                label="School Year"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Year</MenuItem>
                {schoolYearsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="admission-select">Admission</InputLabel>
              <Select
                labelId="admission-select"
                id="id"
                name="id"
                value={registrationForm.id}
                label="Location"
                onChange={handleChange}
              >
                <MenuItem value={undefined}>Please Select admission</MenuItem>
                {admissionsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="schoolclass-select">School Class</InputLabel>
              <Select
                labelId="schoolclass-select"
                id="schoolClassId"
                name="schoolClassId"
                value={registrationForm.schoolClassId}
                label="School Year"
                onChange={handleSchoolClassChange}
              >
                <MenuItem value={""}>Please Select a Class</MenuItem>
                {schoolClassesSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div>
          <Typography variant="h7" align="left">
            Occupied Capacity : {registrationForm.occupiedCapacity} - Max
            Capacity : {registrationForm.maxCapacity}
          </Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="student-select">Student</InputLabel>
              <Select
                labelId="student-select"
                id="studentId"
                name="studentId"
                value={registrationForm.studentId}
                label="Student"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Student</MenuItem>;
                {studentsSelectList}
              </Select>
            </FormControl>
          </Box>
        </div>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <EduxooCreateButton handleSubmit={onSaveRegisterStudentClicked} />
      </Box>
    </Container>
  );
};

export default StudentsRegister;
