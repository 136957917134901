import { Container, CssBaseline } from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../../../components/common/EduxooCustomTable";
import { useGetMedicalTypesQuery } from "./apiMedicalTypesSlice";

export const MedicalTypesList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: medicalTypes,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMedicalTypesQuery(
    { rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  let pageInfo = {
    pageInfo: medicalTypes?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 6,
  };

  const entityHeaderList = ["Id", "Name", "Description"];

  const entityValues = medicalTypes?._embedded?.medicalTypes?.map(
    (medicalType) => {
      let id = medicalType.id;
      let name = medicalType.name;
      let description = medicalType.description;

      return {
        values: [id, name, description],
        links: medicalType._links.self.href,
      };
    }
  );

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/medicalTypesView" },
            { type: "Update", to: "/medicalTypesUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {content}
      </Container>
    </>
  );
};

export default MedicalTypesList;
