import { Container, CssBaseline } from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../../../components/common/EduxooConstans";
import { EduxooCustomTable } from "../../../../components/common/EduxooCustomTable";
import { useGetActivityLogsQuery } from "./apiActivityLogsSlice";

export const ActivityLogsList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: activityLogs,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetActivityLogsQuery(
    { rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["Username", "Logged Time", "Method", "Page"];

  const entityValues = activityLogs?._embedded?.activityLogs?.map(
    (activityLog) => {
      let name = activityLog.activityUser;
      let loggedTime = activityLog.loggedTime;
      let method = activityLog.method;
      let pageInfo = activityLog.page;

      return {
        values: [name, loggedTime, method, pageInfo],
        links: activityLog._links.self.href,
      };
    }
  );

  let pageInfo = {
    pageInfo: activityLogs?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 4,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [{ type: "View", to: "/activityLogsView" }],
        }}
      />
    );
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {content}
      </Container>
    </>
  );
};

export default ActivityLogsList;
