import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useAddStudentMutation } from "./apiStudentsSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { EduxooErrorBox } from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import CreateIcon from "@mui/icons-material/Create";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetMedicalTypesQuery } from "../eduxoo/system/medicaltypes/apiMedicalTypesSlice";

export const StudentsAdd = () => {
  const [open, setOpen] = useState(false);

  const [
    addNewStudent,
    { isLoading, isSuccess, isError, error },
  ] = useAddStudentMutation();

  const school = useSelector(selectCurrentSelectedSchool);

  const [student, setStudent] = useState({
    gender: "Na",
    title: "Na",
    firstName: "",
    lastName: "",
    referenceNo: "",
    dateOfBirth: null,
    schoolId: school.id,
    medicalTypeId: "",
    medicalTypeName: "",
    medicalNote: "",
    medicals: [],
    emailPersonal: "",
    emailWork: "",
    mobileTelephoneNumber: "",
    homeTelephoneNumber: "",
    workTelephoneNumber: "",
    address1: "",
    address2: "",
    address3: "",
    county: "",
    town: "",
    postcode: "",
    addressType: "HOME",
  });

  let content;

  const {
    data: medicalTypes,
    isLoading: isLoadingMedicalTypes,
    isSuccess: isSuccessMedicalTypes,
    isError: isErrorMedicalTypes,
    error: errorMedicalTypes,
  } = useGetMedicalTypesQuery({ rowsPerPage: ROWS_PER_PAGE, page: 0 });

  let medicalTypesSelectList;
  let medicalTypesList = [];

  if (isLoadingMedicalTypes) {
    content = <EduxooBackdrop isLoading={isLoadingMedicalTypes} />;
  } else if (isSuccessMedicalTypes) {
    medicalTypesList = medicalTypes?._embedded?.medicalTypes.map(
      (medicalType) => {
        return { id: medicalType.id, name: medicalType.name };
      }
    );
    medicalTypesSelectList = medicalTypes?._embedded?.medicalTypes.map(
      (medicalType) => {
        return (
          <MenuItem value={medicalType.id} key={medicalType.id}>
            {medicalType.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorMedicalTypes) {
    content = <div>{errorMedicalTypes.toString}</div>;
  }

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    referenceNo: "",
    dateOfBirth: "",
    schoolId: "",
    medicalTypeId: "",
    medicalTypeName: "",
    medicalNote: "",
    address1: "",
    postcode: "",
    isValid: true,
    errorCount: 0,
  });

  const onSaveStudentClicked = async () => {
    try {
      setOpen(true);
      if (validateForm()) {
        resetValidationErrors();
        await addNewStudent(student);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    if (isValidEntry) {
      setStudent({
        ...student,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let schoolId = "";
    let firstName = "";
    let lastName = "";
    let dateOfBirth = "";
    let errorCount = 0;
    let address1 = "";
    let postcode = "";

    if (student.schoolId === "") {
      schoolId = "Please, Select a School";
      isValid = false;
      errorCount++;
    }

    if (student.firstName.trim() === "") {
      firstName = "Please enter a First Name";
      isValid = false;
      errorCount++;
    }

    if (student.lastName.trim() === "") {
      lastName = "Please enter a Last Name";
      isValid = false;
      errorCount++;
    }

    if (student.dateOfBirth === null) {
      dateOfBirth = "Please enter a Date of Birth";
      isValid = false;
      errorCount++;
    }

    if (
      (student.address2.trim() !== "" ||
        student.address3.trim() !== "" ||
        student.town.trim() !== "" ||
        student.postcode.trim() !== "" ||
        student.county.trim() != "") &&
      student.address1.trim() === ""
    ) {
      address1 = "Please enter an address1";
      isValid = false;
      errorCount++;
    }

    if (
      (student.address1.trim() !== "" ||
        student.address2.trim() !== "" ||
        student.address3.trim() !== "" ||
        student.town.trim() !== "" ||
        student.county.trim() !== "") &&
      student.postcode.trim() === ""
    ) {
      postcode = "Please enter a postcode";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        schoolId,
        firstName,
        lastName,
        dateOfBirth,
        address1,
        postcode,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      schoolId: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      address1: "",
      postcode: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const resetForm = () => {
    setStudent({
      gender: "Na",
      title: "Na",
      firstName: "",
      lastName: "",
      referenceNo: "",
      dateOfBirth: null,
      schoolId: school.id,
      medicalTypeId: "",
      medicalTypeName: "",
      medicalNote: "",
      medicals: [],
      emailPersonal: "",
      emailWork: "",
      mobileTelephoneNumber: "",
      homeTelephoneNumber: "",
      workTelephoneNumber: "",
      address1: "",
      address2: "",
      address3: "",
      county: "",
      town: "",
      postcode: "",
      addressType: "HOME",
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Student is Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const handleDateOfBirthChange = (newBirthOfDate) => {
    setStudent({
      ...student,
      dateOfBirth: dayjs(newBirthOfDate).format("YYYY-MM-DD"),
    });
  };

  const handleMedicalSubmit = () => {
    let medicalType = medicalTypesList.find(
      (a) => a.id === student.medicalTypeId
    );

    let prevMedicalType = student.medicals.find(
      (medical) => medical.medicalTypeId === student.medicalTypeId
    );

    if (!prevMedicalType)
      setStudent({
        ...student,
        medicals: [
          ...student.medicals,
          {
            medicalTypeId: medicalType.id,
            medicalTypeName: medicalType.name,
            note: student.medicalNote,
          },
        ],
      });
  };

  const handleMedicalDeleteSubmit = ({ id }) => {
    setStudent({
      ...student,
      medicals: student.medicals.filter((a) => a.medicalTypeId !== id),
    });
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Student Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div>
              <FormControl
                variant="outlined"
                style={{ align: "left", minWidth: 120, margin: 10 }}
              >
                <InputLabel htmlFor="title">Title</InputLabel>
                <Select
                  native
                  value={student.title}
                  onChange={handleChange}
                  label="Title"
                  inputProps={{
                    name: "title",
                    id: "title",
                  }}
                >
                  <option aria-label="None" value="Na" />
                  <option value="Mr">MR</option>
                  <option value="Mrs">MRS</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Mxa">Mx</option>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl
                variant="outlined"
                style={{ align: "left", minWidth: 120, margin: 10 }}
              >
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                  native
                  value={student.gender}
                  onChange={handleChange}
                  label="Gender"
                  inputProps={{
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <option aria-label="None" value="Na">
                    None
                  </option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </Select>
              </FormControl>
            </div>

            <div>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={student.firstName}
                onChange={handleChange}
                error={validationErrors.firstName !== ""}
                helperText={validationErrors.firstName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                error={validationErrors.lastName !== ""}
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={student.lastName}
                onChange={handleChange}
                helperText={validationErrors.lastName}
                margin="dense"
              />
            </div>
            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date of Birth"
                    value={student.dateOfBirth}
                    format="DD-MM-YYYY"
                    onChange={(newStartDate) =>
                      handleDateOfBirthChange(newStartDate)
                    }
                    slotProps={{
                      textField: {
                        required: true,
                        error: validationErrors.dateOfBirth !== "",
                        helperText: validationErrors.dateOfBirth,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div>
              <TextField
                fullWidth
                error={validationErrors.referenceNo !== ""}
                id="studentRef"
                name="referenceNo"
                label="Reference No"
                variant="outlined"
                value={student.referenceNo}
                onChange={handleChange}
                helperText={validationErrors.referenceNo}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>
        <Container>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography variant="h6" gutterBottom>
              Communication Details
            </Typography>

            <div>
              <TextField
                fullWidth
                id="emailPersonal"
                name="emailPersonal"
                type="email"
                label="Email(Personal)"
                variant="outlined"
                value={student.emailPersonal}
                onChange={handleChange}
                margin="dense"
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="emailWork"
                name="emailWork"
                type="email"
                label="Email(Work)"
                variant="outlined"
                value={student.emailWork}
                onChange={handleChange}
                margin="dense"
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="homeTelephoneNumber"
                name="homeTelephoneNumber"
                label="Home Tel"
                variant="outlined"
                value={student.homeTelephoneNumber}
                onChange={handleChange}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="mobileTelephoneNumber"
                name="mobileTelephoneNumber"
                label="Mobile Tel"
                variant="outlined"
                value={student.mobileTelephoneNumber}
                onChange={handleChange}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="workTelephoneNumber"
                name="workTelephoneNumber"
                label="Work Tel"
                variant="outlined"
                value={student.workTelephoneNumber}
                onChange={handleChange}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>
        <Container maxWidth="md" margin="normal">
          <Paper style={{ padding: "5px" }}>
            <Typography variant="h5">Address Details</Typography>
            <div>
              <TextField
                fullWidth
                id="address1"
                name="address1"
                label="Address line 1"
                variant="outlined"
                value={student.address1}
                onChange={handleChange}
                margin="dense"
                //required
                error={validationErrors.address1 !== ""}
                helperText={validationErrors.address1}
              />

              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2 (optional)"
                variant="outlined"
                value={student.address2}
                onChange={handleChange}
                margin="dense"
                helperText={validationErrors.address2}
              />

              <TextField
                fullWidth
                id="address3"
                name="address3"
                label="Address line 3 (optional)"
                variant="outlined"
                value={student.address3}
                onChange={handleChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="town"
                name="town"
                label="Town or City (optional)"
                variant="outlined"
                value={student.town}
                onChange={handleChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="county"
                name="county"
                label="County (optional)"
                variant="outlined"
                value={student.county}
                onChange={handleChange}
                margin="dense"
              />
            </div>
            <TextField
              fullWidth
              id="postcode"
              name="postcode"
              label="Postcode"
              variant="outlined"
              value={student.postcode}
              onChange={handleChange}
              margin="dense"
              //required
              error={validationErrors.postcode !== ""}
              helperText={validationErrors.postcode}
            />
          </Paper>
        </Container>
        <Container>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography variant="h6" gutterBottom>
              Medical Details
            </Typography>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.medicalTypeId !== ""}
                  required
                >
                  <InputLabel id="medical-type-select">Medical Type</InputLabel>
                  <Select
                    labelId="medical-type-select"
                    id="medicalTypeId"
                    name="medicalTypeId"
                    value={student.medicalTypeId}
                    label="Medical Type"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Medical Type</MenuItem>
                    {medicalTypesSelectList}
                  </Select>
                  <FormHelperText>
                    {validationErrors.medicalTypeId}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div>
              <TextField
                required
                fullWidth
                error={validationErrors.medicalNote !== ""}
                id="medicalNote"
                name="medicalNote"
                label="Note"
                variant="outlined"
                value={student.medicalNote}
                onChange={handleChange}
                helperText={validationErrors.medicalNote}
                margin="dense"
              />
            </div>
            <Container maxWidth="sm" style={{ textAlign: "center" }}>
              <div>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  //TODO className={classes.button}
                  onClick={handleMedicalSubmit}
                  startIcon={<CreateIcon />}
                >
                  ADD MEDICAL
                </Button>
              </div>
            </Container>
          </Paper>
        </Container>

        <Container sx={{ marginTop: 5 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Medical Type</TableCell>
                  <TableCell>Parent Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {student?.medicals?.map((medical) => {
                  return (
                    <TableRow key={medical.medicalTypeId}>
                      <TableCell align="left">
                        {medical.medicalTypeName}
                      </TableCell>
                      <TableCell align="left">{medical.note}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                          //TODO className={classes.button}
                          onClick={() =>
                            handleMedicalDeleteSubmit({
                              id: medical.medicalTypeId,
                            })
                          }
                        >
                          DELETE
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 5 }}>
          <EduxooCreateButton handleSubmit={onSaveStudentClicked} />
        </Box>
      </Container>
    </>
  );
};

export default StudentsAdd;
