import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import {
  EduxooCreateButton,
  EduxooSearchButton,
} from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import { selectCurrentSelectedSchool } from "../auths/authSlice";
import { useGetSchoolClassesBySchoolYearQuery } from "./apiSchoolClassesSlice";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import { useRegisterStudentMutation } from "../students/apiStudentsSlice";
import { useNavigate } from "react-router-dom";

export const SchoolClassesDescriptionAddSearch = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const school = useSelector(selectCurrentSelectedSchool);

  const [registrationForm, setRegistrationForm] = useState({
    schoolId: school.id,
    studentId: "",
    schoolYearId: "",
    schoolClassId: "",
    occupiedCapacity: 0,
    maxCapacity: 0,
    id: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    schoolId: "",
    studentId: "",
    schoolYearId: "",
    schoolClassId: "",
    id: "",
    errorCount: 0,
    isValid: true,
  });

  const [
    registerStudent,
    { isLoading, isSuccess, isError, error },
  ] = useRegisterStudentMutation();

  let content;

  const {
    data: schoolYears,
    isLoading: isLoadingSchoolYears,
    isSuccess: isSuccessSchoolYears,
    isError: isErrorSchoolYears,
    error: errorSchoolYears,
  } = useGetSchoolYearsBySchoolQuery(
    { school: registrationForm.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: registrationForm.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList;

  if (isLoadingSchoolYears) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolYears} />;
  } else if (isSuccessSchoolYears) {
    schoolYearsSelectList = schoolYears?._embedded?.schoolYears.map(
      (schoolYear) => {
        return (
          <MenuItem key={schoolYear.id} value={schoolYear.id}>
            {schoolYear.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolYears) {
    content = <div>{errorSchoolYears.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(registrationForm?.schoolYearId, {
    skip: registrationForm.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const resetForm = () => {
    setRegistrationForm({
      //schoolId: "",
      studentId: "",
      schoolYearId: "",
      schoolClassId: "",
      id: "",
      occupiedCapacity: 0,
      maxCapacity: 0,
    });
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolId: "",
      schoolYearId: "",
      schoolClassId: "",
      studentId: "",
      id: "",
    });
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
      isValidEntry = true;
    } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
      isValidEntry = true;
    }*/

    if (e.target.name === "schoolClassId") {
    }

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSchoolClassChange = (e) => {
    let isValidEntry = true;

    let selectedSchoolClass = schoolClasses._embedded.schoolClasses.find(
      (schoolClass) => schoolClass.id === e.target.value
    );

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
        occupiedCapacity: selectedSchoolClass.occupiedCapacity,
        maxCapacity: selectedSchoolClass.maxCapacity,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let schoolId = "";
    let schoolYearId = "";
    let schoolClassId = "";
    let studentId = "";

    if (registrationForm.schoolId.trim() === "") {
      schoolId = "Please enter a Name";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.schoolYearId === "") {
      schoolYearId = "Please select an Academic Year";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.schoolClassId.trim() === "") {
      schoolClassId = "Please select a Classroom";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.studentId.trim() === "") {
      studentId = "Please select a Teacher";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        schoolId,
        schoolYearId,
        schoolClassId,
        studentId,
      });
    }

    return isValid;
  };

  const onSaveRegisterStudentClicked = async () => {
    try {
      console.log("RegistrationForm", registrationForm);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await registerStudent(registrationForm);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Student Registered Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  {
    /*const onSearchSchoolClassClicked = async () => {
    try {
      setOpen(true);
      if (validateForm()) {
        resetValidationErrors();
        await educationalRecordQueryTrigger(studentQuery);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };*/
  }

  const handleSchoolClassChangeClicked = ({ e, entityUrl, entityId }) => {
    e.preventDefault();
    navigate("/schoolClassesDescriptionAddSearchView", {
      state: {
        entityUrl: entityUrl,
        entityId: entityId,
      },
    });
  };

  return (
    <>
      <Container maxWidth="sm">
        <Container component="main" maxWidth="sm">
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Add Description to a Class
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="schoolyear-select">School Year</InputLabel>
                  <Select
                    labelId="schoolyear-select"
                    id="schoolYearId"
                    name="schoolYearId"
                    value={registrationForm.schoolYearId}
                    label="School Year"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Year</MenuItem>
                    {schoolYearsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Paper>
        </Container>
        {/*<Box sx={{ display: "flex", justifyContent: "center" }}>
          <EduxooSearchButton handleSubmit={onSearchSchoolClassClicked} />
        </Box>
*/}

        {/*<div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="schoolclass-select">School Class</InputLabel>
              <Select
                labelId="schoolclass-select"
                id="schoolClassId"
                name="schoolClassId"
                value={registrationForm.schoolClassId}
                label="School Year"
                onChange={handleSchoolClassChange}
              >
                <MenuItem value={""}>Please Select a Class</MenuItem>
                {schoolClassesSelectList}
              </Select>
            </FormControl>
          </Box>
        </div> */}

        <Container sx={{ marginTop: 5 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schoolClasses?._embedded?.schoolClasses?.map((schoolClass) => {
                  return (
                    <TableRow key={schoolClass.id}>
                      <TableCell align="left">{schoolClass.name}</TableCell>

                      <TableCell>
                        <Button
                          size="small"
                          type="submit"
                          variant="contained"
                          color="primary"
                          //TODO className={classes.button}
                          onClick={(e) =>
                            handleSchoolClassChangeClicked({
                              e,
                              entityUrl: schoolClass._links.self.href,
                              entityId: schoolClass.id,
                            })
                          }
                        >
                          CHANGE
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveRegisterStudentClicked} />
        </Box>
      </Container>
    </>
  );
};

export default SchoolClassesDescriptionAddSearch;
