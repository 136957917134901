import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  useGenerateWelcomesForUserMutation,
  useGetWelcomesByLoggedInUserQuery,
} from "./apiWelcomesSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import { EduxooRefreshButton } from "../../components/common/EduxooButton";
import SchoolIcon from "@mui/icons-material/School";
import dayjs from "dayjs";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export const WelcomeHomeSchoolAdmin = () => {
  const [open, setOpen] = useState(false);

  const {
    data: welcomeInfo,
    isLoading: isLoadingSchoolClass,
    isSuccess: isSuccessSchoolClass,
    isError: isErrorSchoolClass,
    error: errorSchoolClass,
  } = useGetWelcomesByLoggedInUserQuery();

  const [
    generateWelcomesForUser,
    { isLoading, isSuccess, isError, error },
  ] = useGenerateWelcomesForUserMutation();

  const resetForm = () => {};

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Welcome Info updated Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const onGenerateWelcomesClicked = async () => {
    try {
      setOpen(true);
      await generateWelcomesForUser();

      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        {content}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooRefreshButton handleSubmit={onGenerateWelcomesClicked} />
        </Box>
        <Typography align="center">
          Last Updated{" "}
          {dayjs(welcomeInfo?.lastUpdated).format("DD-MM-YYYY HH:mm:ss")}
        </Typography>
        <Paper
          sx={{
            p: 2,
            marginTop: 15,
            margin: "auto",
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                size="large"
              >
                <SchoolIcon />
              </IconButton>

              {/*<ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src="/static/images/grid/complex.jpg" />
            </ButtonBase> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    You have {welcomeInfo?.schoolCount} school
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Full resolution 1920x1080 • JPEG
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: 1030114
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: "pointer" }} variant="body2">
                    Remove
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  $19.00
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            marginTop: 15,
            margin: "auto",
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                size="large"
              >
                <SchoolIcon />
              </IconButton>

              {/*<ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src="/static/images/grid/complex.jpg" />
            </ButtonBase> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    You have {welcomeInfo?.schoolYearCount} school year(s)
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Full resolution 1920x1080 • JPEG
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: 1030114
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: "pointer" }} variant="body2">
                    Remove
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  $19.00
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            marginTop: 15,
            margin: "auto",
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                size="large"
              >
                <SchoolIcon />
              </IconButton>

              {/*<ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src="/static/images/grid/complex.jpg" />
            </ButtonBase> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    You have {welcomeInfo?.studentCount} student(s)
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Full resolution 1920x1080 • JPEG
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: 1030114
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: "pointer" }} variant="body2">
                    Remove
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  $19.00
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default WelcomeHomeSchoolAdmin;
