import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { useGetStudentQuery } from "./apiStudentsSlice";

export const StudentsView = () => {
  const location = useLocation();
  const {
    data: student,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentQuery(location.state.entityUrl);

  let content;
  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    let contentMedicals = student?.medicals?.map((medical) => {
      return {
        textName: medical.medicalTypeName,
        textValue: medical.note,
      };
    });

    let elementsList = student
      ? {
          title: "Student Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                { textName: "Reference No", textValue: student.referenceNo },
                { textName: "First Name", textValue: student.firstName },
                { textName: "Last Name", textValue: student.lastName },
                { textName: "Date of Birth", textValue: student.dateOfBirth },
              ],
            },
            {
              title: "Medicals",
              subElements: contentMedicals,
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isError) {
    content = <div>{error.toString}</div>;
  }

  return (
    <>
      <CssBaseline />
      {content}
    </>
  );
};

export default StudentsView;
