import { useState } from "react";
import { EduxooCreateButton } from "../../../../components/common/EduxooButton";

import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "dayjs/locale/en-gb";

import { useAddMedicalTypeMutation } from "./apiMedicalTypesSlice";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../../../components/common/EduxooErrorBox";
import { ErrorsSummaryBox } from "../../../../components/common/ErrorsSummaryBox";

export const MedicalTypesAdd = () => {
  const [medicalType, setMedicalType] = useState({
    name: "",
    description: "",
  });

  const [open, setOpen] = useState(false);

  const resetForm = () => {
    setMedicalType({
      name: "",
      description: "",
    });
  };

  const [
    addMedicalType,
    { isLoading, isSuccess, isError, error },
  ] = useAddMedicalTypeMutation();

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Medical Type Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    description: "",
    isValid: true,
    errorCount: 0,
  });

  const validateForm = () => {
    let isValid = true;

    let name = "";
    let description = "";

    let errorCount = 0;

    if (medicalType.name.trim() === "") {
      name = "Please enter a valid Name";
      isValid = false;
      errorCount++;
    }

    if (medicalType.description.trim() === "") {
      description = "Please, enter a description";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        description,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      description: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const handleChange = (e) => {
    setMedicalType({
      ...medicalType,
      [e.target.name]: e.target.value,
    });
  };

  const onCreateMedicalTypeClicked = async () => {
    if (validateForm) {
      {
        try {
          if (validateForm()) {
            setOpen(true);
            resetValidationErrors();
            await addMedicalType(medicalType).unwrap();
          }
        } catch (err) {
          console.error("Failed to save the School Year: ", err);
        }
      }
    }
  };

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Medical Type Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                value={medicalType.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="description"
                name="description"
                label="Description"
                variant="outlined"
                value={medicalType.description}
                onChange={handleChange}
                helperText={validationErrors.description}
                error={validationErrors.description !== ""}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onCreateMedicalTypeClicked} />
        </Box>
      </Container>
    </>
  );
};

export default MedicalTypesAdd;
