import {
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
} from "../../../../components/common/FormValueValidators";
import Box from "@mui/material/Box";
import { ErrorsSummaryBox } from "../../../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../../../components/common/EduxooButton";
import { useAddSchoolForAdminMutation } from "./apiUsersAdminSlice";
import { EduxooBackdrop } from "../../../../components/common/EduxooBackdrop";
import EduxooSuccessBox from "../../../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../../../components/common/EduxooErrorBox";

export const SchoolsAddAdmin = () => {
  const [open, setOpen] = useState(false);

  const [school, setSchool] = useState({
    name: "",
    email: "",
    website: "",
    phone: "",
    fax: "",
    address: {
      address1: "",
      address2: "",
      address3: "",
      county: "",
      town: "",
      postcode: "",
      addressType: "HOME",
    },
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonEmailWork: "",
    contactPersonEmailPersonal: "",
    locationName: "",
    isContactPersonATeacher: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    address1: "",
    postcode: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonEmail: "",
    locationName: "",
    errorCount: 0,
    isValid: true,
  });

  const [
    addNewSchool,
    { isLoading, isSuccess, isError, error },
  ] = useAddSchoolForAdminMutation();

  const handleChange = (e) => {
    let isValidEntry = false;

    if (e.target.name === "fax" || e.target.name === "phone") {
      if (e.target.value === "" || reNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else if (
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "postcode"
    ) {
      if (e.target.value === "" || reAlphaNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSchool({
        ...school,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {
    setSchool({
      name: "",
      email: "",
      website: "",
      phone: "",
      fax: "",
      address: {
        address1: "",
        address2: "",
        address3: "",
        county: "",
        town: "",
        postcode: "",
        addressType: "HOME",
      },
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmailWork: "",
      contactPersonEmailPersonal: "",
      isContactPersonATeacher: false,
      locationName: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      if (validateForm()) {
        addNewSchool(school);
        resetValidationErrors();
        window.scroll(0, 0);
      }
    } catch (err) {
      window.scroll(0, 0);
      console.error("Failed to save the teacher: ", err);
    }
  };

  const validateForm = () => {
    let isValid = true;

    let name = "";
    let email = "";
    let phone = "";
    let address1 = "";
    let postcode = "";
    let contactPersonFirstName = "";
    let contactPersonLastName = "";
    let contactPersonEmail = "";

    let errorCount = 0;

    if (school.name.trim() === "") {
      name = "Please enter a name";
      isValid = false;
      errorCount++;
    }

    if (school.email.trim() === "" || !reEmail.test(school.email)) {
      email = "Please enter a valid email address";
      errorCount++;
      isValid = false;
    }

    if (school.contactPersonFirstName.trim() === "") {
      contactPersonFirstName = "Please, enter a valid First Name";
      errorCount++;
      isValid = false;
    }

    if (school.contactPersonLastName.trim() === "") {
      contactPersonLastName = "Please, enter a valid Last Name";
      errorCount++;
      isValid = false;
    }

    if (
      school.contactPersonEmailPersonal.trim() === "" &&
      school.contactPersonEmailWork.trim() === ""
    ) {
      contactPersonEmail = "Please, enter a valid Personal or Work Email";
      errorCount++;
      isValid = false;
    }

    if (school.phone.trim() === "") {
      phone = "Please enter a valid phone";
      errorCount++;
      isValid = false;
    }

    if (school.address.address1.trim() === "") {
      address1 = "Please enter Address1";
      isValid = false;
      errorCount++;
    }

    if (school.address.postcode.trim() === "") {
      postcode = "Please enter a Postcode";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        name,
        email,
        phone,
        address1,
        postcode,
        contactPersonFirstName,
        contactPersonLastName,
        contactPersonEmail,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      name: "",
      phone: "",
      email: "",
      address1: "",
      postcode: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmail: "",
      isValid: true,
      errorCount: 0,
    });
  };

  const handleAddressChange = (e) => {
    let isValidEntry = false;

    if (
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "postcode"
    ) {
      if (e.target.value === "" || reAlphaNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSchool({
        ...school,
        address: {
          ...school.address,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleChangeForCreateTeacher = (event) => {
    setSchool({
      ...school,
      isContactPersonATeacher: event.target.checked,
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"School Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  return (
    <>
      <CssBaseline />

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {message}
        {content}
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            School Create
          </Typography>
          <Typography variant="h6" gutterBottom>
            Main Details
          </Typography>
          <div>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              margin="dense"
              value={school.name}
              onChange={handleChange}
              error={validationErrors.name !== ""}
              helperText={validationErrors.name}
              inputProps={{ maxLength: 160 }}
            />
          </div>
          <div>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              variant="outlined"
              value={school.phone}
              onChange={handleChange}
              helperText={validationErrors.phone}
              error={validationErrors.phone !== ""}
              margin="dense"
              fullWidth
              required
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="fax"
              name="fax"
              label="Fax"
              variant="outlined"
              value={school.fax}
              onChange={handleChange}
              margin="dense"
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              value={school.email}
              onChange={handleChange}
              margin="dense"
              error={validationErrors.email !== ""}
              helperText={validationErrors.email}
              required
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="website"
              name="website"
              label="Website"
              variant="outlined"
              value={school.website}
              onChange={handleChange}
              margin="dense"
            />
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Contact Person Details
            </Typography>
            <div>
              <TextField
                required
                fullWidth
                id="contactPersonFirstName"
                name="contactPersonFirstName"
                label="First Name"
                variant="outlined"
                value={school.contactPersonFirstName}
                onChange={handleChange}
                error={validationErrors.contactPersonFirstName !== ""}
                helperText={validationErrors.contactPersonFirstName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="contactPersonLastName"
                name="contactPersonLastName"
                label="Last Name"
                variant="outlined"
                value={school.contactPersonLastName}
                onChange={handleChange}
                error={validationErrors.contactPersonLastName !== ""}
                helperText={validationErrors.contactPersonLastName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="contactPersonEmailPersonal"
                name="contactPersonEmailPersonal"
                type="email"
                label="Email(Personal)"
                variant="outlined"
                value={school.contactPersonEmailPersonal}
                onChange={handleChange}
                margin="dense"
                error={validationErrors.contactPersonEmail !== ""}
                helperText={validationErrors.contactPersonEmail}
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="contactPersonEmailWork"
                name="contactPersonEmailWork"
                type="email"
                label="Email(Work)"
                variant="outlined"
                value={school.contactPersonEmailWork}
                onChange={handleChange}
                margin="dense"
                error={validationErrors.contactPersonEmail !== ""}
                helperText={validationErrors.contactPersonEmail}
              />
            </div>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Location Details
            </Typography>
            <div>
              <TextField
                required
                fullWidth
                id="locationName"
                name="locationName"
                label="Location Name"
                variant="outlined"
                value={school.locationName}
                onChange={handleChange}
                error={validationErrors.locationName !== ""}
                helperText={validationErrors.locationName}
                margin="dense"
              />
            </div>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Teacher Details
            </Typography>
            <div>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={school.isContactPersonATeacher}
                    onChange={handleChangeForCreateTeacher}
                  />
                }
                label="Create a Teacher with Contanct Info"
                labelPlacement="start"
              />
            </div>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Address Details
            </Typography>
            <div>
              <TextField
                fullWidth
                required
                error={validationErrors.address1 !== ""}
                id="address1"
                name="address1"
                label="Address line 1"
                variant="outlined"
                value={school.address.address1}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address1}
              />

              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2 (optional)"
                variant="outlined"
                value={school.address.address2}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address2}
              />

              <TextField
                fullWidth
                id="address3"
                name="address3"
                label="Address line 3 (optional)"
                variant="outlined"
                value={school.address.address3}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="town"
                name="town"
                label="Town or City (optional)"
                variant="outlined"
                value={school.address.town}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="county"
                name="county"
                label="County (optional)"
                variant="outlined"
                value={school.address.county}
                onChange={handleAddressChange}
                margin="dense"
              />
            </div>
            <TextField
              fullWidth
              required
              error={validationErrors.postcode !== ""}
              id="postcode"
              name="postcode"
              label="Postcode"
              variant="outlined"
              value={school.address.postcode}
              onChange={handleAddressChange}
              margin="dense"
              helperText={validationErrors.postcode}
            />
          </div>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <EduxooCreateButton handleSubmit={handleSubmit} />
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default SchoolsAddAdmin;
