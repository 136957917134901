import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, schoolId, role, schools } = action.payload;
      state.user = user;
      state.token = token;
      state.school = schoolId;
      state.role = role;
      if (schools.length === 1) state.selectedSchool = schools[0];
    },
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      state.school = null;
      state.role = null;
      state.selectedSchool = null;
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentSchool = (state) => state.auth.school;
export const selectCurrentRole = (state) => state.auth.role;
export const selectCurrentSelectedSchool = (state) => state.auth.selectedSchool;
