import { EduxooMainMenu } from "../../components/common/EduxooMainMenu";

export default function SchoolYearsMain() {
  const tiers = [
    [
      {
        title: "School Years",
        id: "SchoolYearCreate",
        subheader: "Create",
        description: ["You can add a new School Year by using this form"],
        buttonText: "Add a new School Year",
        buttonVariant: "contained",
        to: "/schoolYearsAdd",
      },
      {
        title: "School Years",
        id: "SchoolYearView",
        subheader: "View or Update",
        description: ["You can view and update by using this form"],
        buttonText: "View all your School Years",
        buttonVariant: "outlined",
        to: "/schoolYearsList",
      },
    ],
  ];
  return <EduxooMainMenu tiers={tiers} />;
}
