import { useState } from "react";
import { useAddSchoolClassMutation } from "./apiSchoolClassesSlice";
import { useGetClassroomsByLocationQuery } from "../classrooms/apiClassroomsSlice";
import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useGetLocationsBySchoolQuery } from "../locations/apiLocationsSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useGetSchoolYearsBySchoolQuery } from "../schoolyears/apiSchoolYearsSlice";
import { useGetTeachersBySchoolQuery } from "../teachers/apiTeachersSlice";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { useSelector } from "react-redux";
import { selectCurrentSelectedSchool } from "../auths/authSlice";

export const SchoolClassesAdd = () => {
  const school = useSelector(selectCurrentSelectedSchool);

  const [schoolClass, setSchoolClass] = useState({
    name: "",
    currentMaxCapacity: 0,
    currentMinCapacity: 0,
    maxCapacity: 0,
    minCapacity: 0,
    occupiedCapacity: 0,
    reservedCapacity: 0,
    schoolYearId: "",
    yearGroupId: "",
    locationId: "",
    classroomId: "",
    teacherId: "",
    schoolId: school.id,
  });

  const [open, setOpen] = useState(false);

  const [
    addSchoolClass,
    { isLoading, isSuccess, isError, error },
  ] = useAddSchoolClassMutation();

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let content;
  {
    /*let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    content = "";
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }*/
  }

  const {
    data: locations,
    isLoading: isLoadingLoc,
    isSuccess: isSuccessLoc,
    isError: isErrorLoc,
    error: errorLoc,
  } = useGetLocationsBySchoolQuery(
    { school: schoolClass.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: schoolClass.schoolId ? false : true,
    }
  );

  let locationsSelectList;

  if (isLoadingLoc) {
    content = <EduxooBackdrop isLoading={isLoadingLoc} />;
  } else if (isSuccessLoc) {
    locationsSelectList = locations?._embedded?.locations.map((location) => {
      return (
        <MenuItem value={location.id} key={location.id}>
          {location.name}
        </MenuItem>
      );
    });
  } else if (isErrorLoc) {
    content = <div>{errorLoc.toString}</div>;
  }

  const {
    data: classrooms,
    isLoading: isLoadingClassrooms,
    isSuccess: isSuccessClassrooms,
    isError: isErrorClassrooms,
    error: errorClassrooms,
  } = useGetClassroomsByLocationQuery(
    { location: schoolClass.locationId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: schoolClass.locationId ? false : true,
    }
  );

  let classroomsSelectList;

  if (isLoadingClassrooms) {
    content = <EduxooBackdrop isLoading={isLoadingClassrooms} />;
  } else if (isSuccessClassrooms) {
    classroomsSelectList = classrooms?._embedded?.classrooms.map(
      (classroom) => {
        return (
          <MenuItem value={classroom.id} key={classroom.id}>
            {classroom.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorClassrooms) {
    content = <div>{errorClassrooms.toString}</div>;
  }

  const {
    data: schoolYears,
    isLoading: isLoadingSY,
    isSuccess: isSuccessSY,
    isError: isErrorSY,
    error: errorSY,
  } = useGetSchoolYearsBySchoolQuery(
    { school: schoolClass.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: schoolClass.schoolId ? false : true,
    }
  );

  let schoolYearsSelectList;

  if (isLoadingSY) {
    content = <EduxooBackdrop isLoading={isLoadingSY} />;
  } else if (isSuccessSY) {
    schoolYearsSelectList = schoolYears?._embedded?.schoolYears.map(
      (schoolYear) => {
        return (
          <MenuItem value={schoolYear.id} key={schoolYear.id}>
            {schoolYear.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSY) {
    content = <div>{errorSY.toString}</div>;
  }

  const {
    data: teachers,
    isLoading: isLoadingTeachers,
    isSuccess: isSuccessTeachers,
    isError: isErrorTeachers,
    error: errorTeachers,
  } = useGetTeachersBySchoolQuery(
    { school: schoolClass.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: schoolClass.schoolId ? false : true,
    }
  );

  let teachersSelectList;

  if (isLoadingTeachers) {
    content = <EduxooBackdrop isLoading={isLoadingTeachers} />;
  } else if (isSuccessTeachers) {
    teachersSelectList = teachers?._embedded?.teachers.map((teacher) => {
      return (
        <MenuItem value={teacher.id} key={teacher.id}>
          {teacher.person.firstName + " " + teacher.person.lastName}
        </MenuItem>
      );
    });
  } else if (isErrorTeachers) {
    content = <div>{errorTeachers.toString}</div>;
  }

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    schoolYearId: "",
    yearGroupId: "",
    classroomId: "",
    teacherId: "",
    locationId: "",
    schoolId: "",
    maxCapacity: "",
    minCapacity: "",
    minMaxCapacity: "",
    maxCapacitySmallerThanMinCapacity: "",
    errorCount: 0,
    isValid: true,
  });

  const handleChange = (e) => {
    let isValidEntry = false;

    if (e.target.name === "emailWork" || e.target.name === "emailPersonal") {
      isValidEntry = true;
    } else if (e.target.value !== "") {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setSchoolClass({
        ...schoolClass,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClassroomChange = (e) => {
    let isValidEntry = true;

    let selectedClassroom = classrooms?._embedded.classrooms.find(
      (classroom) => classroom.id === e.target.value
    );

    if (isValidEntry) {
      setSchoolClass({
        ...schoolClass,
        [e.target.name]: e.target.value,
        currentMaxCapacity: selectedClassroom.maxCapacity,
        currentMinCapacity: selectedClassroom.minCapacity,
        minCapacity: selectedClassroom.minCapacity,
        maxCapacity: selectedClassroom.maxCapacity,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let name = "";
    let schoolId = "";
    let schoolYearId = "";
    let yearGroupId = "";
    let classroomId = "";
    let teacherId = "";
    let maxCapacity = "";
    let minCapacity = "";
    let locationId = "";
    let minMaxCapacity = "";
    let maxCapacitySmallerThanMinCapacity = "";

    if (schoolClass.schoolId === "") {
      schoolId = "Please, Select a School";
      isValid = false;
      errorCount++;
    }

    if (schoolClass.name.trim() === "") {
      name = "Please enter a Name";
      isValid = false;
      errorCount++;
    }

    if (schoolClass.schoolYearId <= 0) {
      schoolYearId = "Please select an School Year";
      isValid = false;
      errorCount++;
    }

    if (schoolClass.classroomId.trim() === "") {
      classroomId = "Please select a Classroom";
      isValid = false;
      errorCount++;
    }

    if (schoolClass.teacherId.trim() === "") {
      teacherId = "Please select a Teacher";
      isValid = false;
      errorCount++;
    }

    if (parseInt(schoolClass.minCapacity) <= 0) {
      minCapacity = "Please, enter a valid Min Capacity";
      isValid = false;
      errorCount++;
    }

    if (parseInt(schoolClass.maxCapacity) <= 0) {
      maxCapacity = "Please, enter a valid Max Capacity";
      isValid = false;

      errorCount++;
    }

    if (parseInt(schoolClass.maxCapacity) < parseInt(schoolClass.minCapacity)) {
      maxCapacitySmallerThanMinCapacity =
        "Please, enter a Max Capacity Greater than Min Capacity";
      isValid = false;
      errorCount++;
    }

    if (
      parseInt(schoolClass.minCapacity) <
      parseInt(schoolClass.currentMinCapacity)
    ) {
      minCapacity =
        "Please, enter a Min Capacity Greater than Classroom Min Capacity";
      isValid = false;
      errorCount++;
    }

    if (
      parseInt(schoolClass.maxCapacity) >
      parseInt(schoolClass.currentMaxCapacity)
    ) {
      maxCapacity =
        "Please, enter a Max Capacity Smaller than Classroom Max Capacity";
      isValid = false;
      errorCount++;
    }

    //TODO
    /*if (parseInt(schoolClass.maxCapacity) > this.state.classroom.maxCapacity) {
      maxCapacity =
        "Please enter a number smaller and equal than Classroom Max Capacity";
      isValid = false;
      errorCount++;
    }*/

    //TODO
    /*
    if (parseInt(schoolClass.minCapacity) < this.state.classroom.minCapacity) {
      minCapacity =
        "Please enter a number greater and equal than Classroom Min Capacity";
      isValid = false;
      errorCount++;
    }*/

    //TODO
    /*
    if (parseInt(schoolClass.minCapacity) > parseInt(schoolClass.maxCapacity)) {
      minMaxCapacity = "Min Capacity can not be greater than Max Capacity";
      isValid = false;
      errorCount++;
    }*/

    if (schoolClass.locationId.trim() === "") {
      locationId = "Please Select a Location";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,

        schoolId,
        schoolYearId,
        yearGroupId,
        classroomId,
        teacherId,
        name,
        maxCapacity,
        minCapacity,
        locationId,
        minMaxCapacity,
        maxCapacitySmallerThanMinCapacity,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolId: "",
      schoolYearId: "",
      locationId: "",
      yearGroupId: "",
      classroomId: "",
      teacherId: "",
      name: "",
      maxCapacity: "",
      minCapacity: "",
      minMaxCapacity: "",
      maxCapacitySmallerThanMinCapacity: "",
    });
  };

  const onSaveSchoolClassClicked = async () => {
    try {
      console.log("SchoolClass", schoolClass);
      if (validateForm()) {
        resetValidationErrors();
        await addSchoolClass(schoolClass);
        setOpen(true);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const resetForm = () => {
    setSchoolClass({
      name: "",
      maxCapacity: 0,
      minCapacity: 0,
      occupiedCapacity: 0,
      reservedCapacity: 0,
      schoolYearId: "",
      yearGroupId: "",
      locationId: "",
      classroomId: "",
      teacherId: "",
      //schoolId: "",
    });
  };

  let message;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"School Class is Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              School Class Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            {/*<div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.schoolId !== ""}
                  required
                >
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={schoolClass.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.schoolId}</FormHelperText>
                </FormControl>
              </Box>
            </div>*/}
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.locationId !== ""}
                  required
                >
                  <InputLabel id="location-select">Location</InputLabel>
                  <Select
                    labelId="location-select"
                    id="locationId"
                    name="locationId"
                    value={schoolClass.locationId}
                    label="Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select Location
                    </MenuItem>
                    {locationsSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.locationId}</FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.classroomId !== ""}
                  required
                >
                  <InputLabel id="classroom-select">Classroom</InputLabel>
                  <Select
                    labelId="classroom-select"
                    id="classroomId"
                    name="classroomId"
                    value={schoolClass.classroomId}
                    label="Classroom"
                    onChange={handleClassroomChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select Classroom
                    </MenuItem>
                    {classroomsSelectList}
                  </Select>
                  <FormHelperText>
                    {validationErrors.classroomId}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.schoolYearId !== ""}
                  required
                >
                  <InputLabel id="schoolyear-select">School Year</InputLabel>
                  <Select
                    labelId="schoolyear-select"
                    id="schoolYearId"
                    name="schoolYearId"
                    value={schoolClass.schoolYearId}
                    label="School Year"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select a School Year
                    </MenuItem>
                    {schoolYearsSelectList}
                  </Select>
                  <FormHelperText>
                    {validationErrors.schoolYearId}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.teacherId !== ""}
                  required
                >
                  <InputLabel id="teacher-select">Teacher</InputLabel>
                  <Select
                    labelId="teacher-select"
                    id="teacherId"
                    name="teacherId"
                    value={schoolClass.teacherId}
                    label="Teacher"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select a Teacher
                    </MenuItem>
                    {teachersSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.teacherId}</FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                value={schoolClass.name}
                onChange={handleChange}
                error={validationErrors.name !== ""}
                helperText={validationErrors.name}
                margin="dense"
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Typography variant="h6" gutterBottom>
                Classroom Min Capacity : {schoolClass.currentMinCapacity}
              </Typography>
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                required
                fullWidth
                id="minCapacity"
                name="minCapacity"
                label="Min Capacity"
                variant="outlined"
                value={schoolClass.minCapacity}
                onChange={handleChange}
                error={validationErrors.minCapacity !== ""}
                helperText={validationErrors.minCapacity}
                margin="dense"
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Typography variant="h6" gutterBottom>
                Classroom Max Capacity : {schoolClass.currentMaxCapacity}
              </Typography>
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                required
                fullWidth
                id="maxCapacity"
                name="maxCapacity"
                label="Max Capacity"
                variant="outlined"
                value={schoolClass.maxCapacity}
                onChange={handleChange}
                error={
                  validationErrors.maxCapacity !== "" ||
                  validationErrors.maxCapacitySmallerThanMinCapacity !== ""
                }
                helperText={
                  validationErrors.maxCapacity +
                  validationErrors.maxCapacitySmallerThanMinCapacity
                }
                margin="dense"
              />
            </div>
          </Paper>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveSchoolClassClicked} />
        </Box>
      </Container>
    </>
  );
};

export default SchoolClassesAdd;
