import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Button, Container } from "@mui/material";
import {
  purple,
  teal,
  orange,
  lime,
  blueGrey,
  red,
  cyan,
} from "@mui/material/colors";
import { styled } from "@mui/system";
import CreateIcon from "@mui/icons-material/Create";
import UpdateIcon from "@mui/icons-material/Update";
import RefreshIcon from "@mui/icons-material/Refresh";

const SearchButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(lime[500]),
  backgroundColor: lime[500],
  "&:hover": {
    backgroundColor: lime[700],
  },
}));

export const EduxooSearchButton = (props) => {
  return (
    <SearchButton
      size="large"
      type="submit"
      variant="contained"
      color="primary"
      //className={classes.button}
      onClick={props.handleSubmit}
      startIcon={<SearchIcon />}
    >
      SEARCH
    </SearchButton>
  );
};

const GetAllButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.ochre.contrastText,
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}));

export const EduxooGetAllButton = (props) => {
  return (
    <GetAllButton
      size="large"
      type="submit"
      variant="contained"
      color="primary"
      //className={classes.button}
      onClick={props.handleSubmit}
      startIcon={<FindInPageIcon />}
      style={{ marginLeft: "50px" }}
    >
      GET ALL
    </GetAllButton>
  );
};

const ListViewButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    "&:hover": {
      backgroundColor: blueGrey[700],
    },
    marginRight: theme.spacing(2),
    minWidth: "87px",
  },
}));

export const EduxooListViewButton = (props) => {
  return (
    <ListViewButton
      id="View"
      size="small"
      type="submit"
      variant="contained"
      color="primary"
      onClick={(e) => props.handleSubmit(e, props.link)}
    >
      VIEW
    </ListViewButton>
  );
};

export const EduxooBackButton = (props) => {
  //TODO const classes = useStyles();
  return (
    <div style={{ textAlign: "center" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={props.handleBackButtonClick}
        //TODO className={classes.button}
        startIcon={<ArrowBackIcon />}
      >
        BACK
      </Button>
    </div>
  );
};

const CreateButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
    "&:hover": {
      backgroundColor: teal[700],
    },
  },
}));

export const EduxooCreateButton = (props) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      <div>
        <CreateButton
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          //TODO className={classes.button}
          onClick={props.handleSubmit}
          startIcon={<CreateIcon />}
        >
          CREATE
        </CreateButton>
      </div>
    </Container>
  );
};

const RefreshButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}));

export const EduxooRefreshButton = (props) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      <div>
        <RefreshButton
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          //TODO className={classes.button}
          onClick={props.handleSubmit}
          startIcon={<RefreshIcon />}
        >
          REFRESH
        </RefreshButton>
      </div>
    </Container>
  );
};

const UpdateButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
}));

export const EduxooUpdateButton = (props) => {
  return (
    <Container maxWidth="sm">
      <div style={{ textAlign: "center" }}>
        <UpdateButton
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          //TODO className={classes.button}
          onClick={props.handleSubmit}
          startIcon={<UpdateIcon />}
        >
          UPDATE
        </UpdateButton>
      </div>
    </Container>
  );
};

const ListUpdateButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    "&:hover": {
      backgroundColor: blueGrey[700],
    },
    marginRight: theme.spacing(2),
    minWidth: "87px",
  },
}));

export const EduxooListUpdateButton = (props) => {
  return (
    <ListUpdateButton
      id="Update"
      size="small"
      type="submit"
      variant="contained"
      color="primary"
      onClick={(e) => props.handleSubmit(e, props.link)}
    >
      UPDATE
    </ListUpdateButton>
  );
};

const ListRegisterButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    "&:hover": {
      backgroundColor: blueGrey[700],
    },
    marginRight: theme.spacing(2),
    minWidth: "87px",
  },
}));

export const EduxooListRegisterButton = (props) => {
  return (
    <ListUpdateButton
      id="Register"
      size="small"
      type="submit"
      variant="contained"
      color="primary"
      onClick={(e) => props.handleSubmit(e, props.link)}
    >
      REGISTER
    </ListUpdateButton>
  );
};
