import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  reNumeric,
  reAlphaNumeric,
  reEmail,
  reForNames,
  reAlphabetic,
} from "../../components/common/FormValueValidators";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import { useAddParentMutation } from "./apiParentsSlice";
import { useGetSchoolsQuery } from "../schools/apiSchoolsSlice";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { useGetStudentsBySchoolQuery } from "../students/apiStudentsSlice";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";

export const ParentsAdd = () => {
  const [
    addParent,
    { isLoading, isSuccess, isError, error },
  ] = useAddParentMutation();
  const [open, setOpen] = useState(false);

  const [parent, setParent] = useState({
    schoolId: "",
    personRelatedId: "",
    relationshipType: "",
    person: {
      dateOfBirth: "",
      emailPersonal: "",
      emailWork: "",
      firstName: "",
      lastName: "",
      gender: "Na",
      homeTelephoneNumber: "",
      mobileTelephoneNumber: "",
      occupation: "",
      title: "Na",
      workTelephoneNumber: "",
    },
    address: {
      address1: "",
      address2: "",
      address3: "",
      county: "",
      town: "",
      postcode: "",
      addressType: "HOME",
    },
  });

  const [validationErrors, setValidationErrors] = useState({
    schoolId: "",
    personRelatedId: "",
    relationshipType: "",
    firstName: "",
    lastName: "",
    emailPersonal: "",
    emailWork: "",
    address1: "",
    postcode: "",
    isValid: true,
    errorCount: 0,
  });

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();
  let schoolsSelectList;

  let content;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem key={school.id} value={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }
  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
    isError: isErrorStudents,
    error: errorStudents,
  } = useGetStudentsBySchoolQuery(
    { school: parent.schoolId, page: 0, rowsPerPage: ROWS_PER_PAGE },
    {
      skip: parent.schoolId ? false : true,
    }
  );

  let studentsSelectList;

  if (isLoadingStudents) {
    content = <EduxooBackdrop isLoading={isLoadingStudents} />;
  } else if (isSuccessStudents) {
    studentsSelectList = students?._embedded?.students.map((student) => {
      return (
        <MenuItem key={student.personId} value={student.personId}>
          {student.firstName + " " + student.lastName}
        </MenuItem>
      );
    });
  } else if (isErrorStudents) {
    content = <div>{errorStudents.toString}</div>;
  }

  const resetForm = () => {
    setParent({
      schoolId: "",
      personRelatedId: "",
      relationshipType: "",
      person: {
        dateOfBirth: "",
        emailPersonal: "",
        emailWork: "",
        firstName: "",
        lastName: "",
        gender: "",
        homeTelephoneNumber: "",
        mobileTelephoneNumber: "",
        occupation: "",
        title: "",
        workTelephoneNumber: "",
      },
      address: {
        address1: "",
        address2: "",
        address3: "",
        county: "",
        town: "",
        postcode: "",
        addressType: "HOME",
      },
    });
  };

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Parent Created Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  const onSaveParentClicked = async () => {
    try {
      if (validateForm()) {
        setOpen(true);
        resetValidationErrors();
        await addParent(parent);
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the parent: ", err);
    }
  };

  const handlePersonChange = (e) => {
    let isValidEntry = false;

    if (
      e.target.id === "homeTel" ||
      e.target.id === "mobileTel" ||
      e.target.id === "workTel"
    ) {
      if (e.target.value === "" || reNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else if (
      e.target.id === "address1" ||
      e.target.id === "address2" ||
      e.target.id === "postcode"
    ) {
      if (e.target.value === "" || reAlphaNumeric.test(e.target.value)) {
        isValidEntry = true;
      }
    } else if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
      isValidEntry = true;
    } else if (e.target.id === "firstName" || e.target.id === "lastName") {
      if (e.target.value === "" || reForNames.test(e.target.value)) {
        isValidEntry = true;
      }
    } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
      isValidEntry = true;
    }

    if (isValidEntry) {
      setParent({
        ...parent,
        person: {
          ...parent.person,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleAddressChange = (e) => {
    setParent({
      ...parent,
      address: {
        ...parent.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
      isValidEntry = true;
    } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
      isValidEntry = true;
    }*/

    if (isValidEntry) {
      setParent({
        ...parent,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let schoolId = "";
    let personRelatedId = "";
    let relationshipType = "";

    let firstName = "";
    let lastName = "";
    let emailPersonal = "";
    let emailWork = "";
    let address1 = "";
    let postcode = "";

    let errorCount = 0;

    if (parent.schoolId.trim() === "") {
      schoolId = "Please, Select a School";
      isValid = false;
      errorCount++;
    }

    if (parent.personRelatedId.trim() === "") {
      personRelatedId = "Please, Select a Student";
      isValid = false;
      errorCount++;
    }

    if (parent.relationshipType.trim() === "") {
      relationshipType = "Please, Select RelationshipType";
      isValid = false;
      errorCount++;
    }

    if (parent.person.firstName.trim() === "") {
      firstName = "Please enter a First Name";
      isValid = false;
      errorCount++;
    }

    if (parent.person.lastName.trim() === "") {
      lastName = "Please enter a Last Name";
      isValid = false;
      errorCount++;
    }

    if (
      parent.person.emailPersonal &&
      parent.person.emailPersonal.trim() !== "" &&
      !reEmail.test(parent.person.emailPersonal)
    ) {
      emailPersonal = "Please enter a valid Email";
      isValid = false;
      errorCount++;
    }

    if (
      parent.person.emailWork &&
      parent.person.emailWork.trim() !== "" &&
      !reEmail.test(parent.person.emailWork)
    ) {
      emailWork = "Please enter a valid Email";
      isValid = false;
      errorCount++;
    }

    if (
      parent.address &&
      (parent.address.address1.trim() !== "" ||
        parent.address.address2.trim() !== "" ||
        parent.address.address3.trim() !== "" ||
        parent.address.town.trim() !== "" ||
        parent.address.county.trim() !== "" ||
        parent.address.postcode.trim() !== "")
    ) {
      if (parent.address.address1.trim() === "") {
        address1 = "Please enter a valid Address";
        isValid = false;
        errorCount++;
      }
      if (parent.address.postcode.trim() === "") {
        postcode = "Please enter a valid Postcode";
        isValid = false;
        errorCount++;
      }
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        schoolId,
        personRelatedId,
        relationshipType,
        firstName,
        lastName,
        emailPersonal,
        emailWork,
        address1,
        postcode,
        errorCount,
        isValid,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      schoolId: "",
      personRelatedId: "",
      relationshipType: "",
      firstName: "",
      lastName: "",
      emailPersonal: "",
      emailWork: "",
      address1: "",
      postcode: "",
      isValid: true,
      errorCount: 0,
    });
  };

  let message = "";
  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          {content}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Parent Create
            </Typography>
            <Typography variant="h6" gutterBottom>
              Main Details
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.schoolId !== ""}
                  required
                >
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={parent.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select a School
                    </MenuItem>
                    {schoolsSelectList}
                  </Select>
                  <FormHelperText>{validationErrors.schoolId}</FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.personRelatedId !== ""}
                  required
                >
                  <InputLabel id="student-select">Student</InputLabel>
                  <Select
                    labelId="student-select"
                    id="personRelatedId"
                    name="personRelatedId"
                    value={parent.personRelatedId}
                    label="Student"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select a Student
                    </MenuItem>
                    ;{studentsSelectList}
                  </Select>
                  <FormHelperText>
                    {validationErrors.personRelatedId}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl
                  fullWidth
                  error={validationErrors.relationshipType !== ""}
                  required
                >
                  <InputLabel id="relationship-select">Relationship</InputLabel>
                  <Select
                    labelId="relationship-select"
                    id="relationshipType"
                    name="relationshipType"
                    value={parent.relationshipType}
                    label="Relationship"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a relationship</MenuItem>
                    <MenuItem value="FATHER">FATHER</MenuItem>
                    <MenuItem value="MOTHER">MOTHER</MenuItem>
                  </Select>
                  <FormHelperText>
                    {validationErrors.relationshipType}
                  </FormHelperText>
                </FormControl>
              </Box>
            </div>
            <div>
              <FormControl
                variant="outlined"
                //className={classes.formControl}
                style={{ align: "left", minWidth: 120, margin: 10 }}
              >
                <InputLabel htmlFor="title">Title</InputLabel>
                <Select
                  native
                  value={parent.person.title}
                  onChange={handlePersonChange}
                  label="Title"
                  inputProps={{
                    name: "title",
                    id: "title",
                  }}
                >
                  <option aria-label="None" value="Title" />
                  <option value="Mr">MR</option>
                  <option value="Mrs">MRS</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Mxa">Mx</option>
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl
                variant="outlined"
                //className={classes.formControl}
                style={{ align: "left", minWidth: 120, margin: 10 }}
              >
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                  native
                  value={parent.person.gender}
                  onChange={handlePersonChange}
                  label="Gender"
                  inputProps={{
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <option aria-label="None" value="Gender" />
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={parent.person.firstName}
                onChange={handlePersonChange}
                error={validationErrors.firstName !== ""}
                helperText={validationErrors.firstName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                required
                fullWidth
                error={validationErrors.lastName !== ""}
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={parent.person.lastName}
                onChange={handlePersonChange}
                helperText={validationErrors.lastName}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="occupation"
                name="occupation"
                label="Occupation"
                variant="outlined"
                value={parent.person.occupation}
                onChange={handlePersonChange}
                margin="dense"
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="emailPersonal"
                name="emailPersonal"
                type="email"
                label="Email(Personal)"
                variant="outlined"
                value={parent.person.emailPersonal}
                onChange={handlePersonChange}
                margin="dense"
                error={validationErrors.emailPersonal !== ""}
                helperText={validationErrors.emailPersonal}
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="emailWork"
                name="emailWork"
                type="email"
                label="Email(Work)"
                variant="outlined"
                value={parent.emailWork}
                onChange={handleChange}
                margin="dense"
                error={validationErrors.emailWork !== ""}
                helperText={validationErrors.emailWork}
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="homeTelephoneNumber"
                name="homeTelephoneNumber"
                label="Home Tel"
                variant="outlined"
                value={parent.person.homeTelephoneNumber}
                onChange={handlePersonChange}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="mobileTelephoneNumber"
                name="mobileTelephoneNumber"
                label="Mobile Tel"
                variant="outlined"
                value={parent.person.mobileTelephoneNumber}
                onChange={handlePersonChange}
                margin="dense"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="workTelephoneNumber"
                name="workTelephoneNumber"
                label="Work Tel"
                variant="outlined"
                value={parent.person.workTelephoneNumber}
                onChange={handlePersonChange}
                margin="dense"
              />
            </div>
          </Paper>
        </Container>

        <Container maxWidth="md" margin="normal">
          <Paper style={{ padding: "5px" }}>
            <Typography variant="h5">Address Details</Typography>
            <div>
              <TextField
                fullWidth
                id="address1"
                name="address1"
                label="Address line 1"
                variant="outlined"
                value={parent.address.address1}
                onChange={handleAddressChange}
                margin="dense"
                //required
                error={validationErrors.address1 !== ""}
                helperText={validationErrors.address1}
              />

              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2 (optional)"
                variant="outlined"
                value={parent.address.address2}
                onChange={handleAddressChange}
                margin="dense"
                helperText={validationErrors.address2}
              />

              <TextField
                fullWidth
                id="address3"
                name="address3"
                label="Address line 3 (optional)"
                variant="outlined"
                value={parent.address.address3}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="town"
                name="town"
                label="Town or City (optional)"
                variant="outlined"
                value={parent.address.town}
                onChange={handleAddressChange}
                margin="dense"
              />

              <TextField
                fullWidth
                id="county"
                name="county"
                label="County (optional)"
                variant="outlined"
                value={parent.address.county}
                onChange={handleAddressChange}
                margin="dense"
              />
            </div>
            <TextField
              fullWidth
              id="postcode"
              name="postcode"
              label="Postcode"
              variant="outlined"
              value={parent.address.postcode}
              onChange={handleAddressChange}
              margin="dense"
              //required
              error={validationErrors.postcode !== ""}
              helperText={validationErrors.postcode}
            />
          </Paper>
        </Container>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton handleSubmit={onSaveParentClicked} />
        </Box>
      </Container>
    </>
  );
};

export default ParentsAdd;
