import { useGetSchoolsQuery } from "./apiSchoolsSlice";
import { CssBaseline } from "@mui/material";

import { EduxooCustomTable } from "../../components/common/EduxooCustomTable";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import { useState } from "react";

export default function SchoolsList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const {
    data: schools,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSchoolsQuery(
    { rowsPerPage, page },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const entityHeaderList = ["Name", "Email", "Website", "Phone", "Fax"];

  const entityValues = schools?._embedded?.schools?.map((school) => {
    let name = school.name;
    let email = school.email;
    let website = school.website;
    let phone = school.phone;
    let fax = school.fax;
    return {
      values: [name, email, website, phone, fax],
      links: school._links.self.href,
    };
  });

  let pageInfo = {
    pageInfo: schools?.page,
    customPage: page,
    customSetPage: setPage,
    customRowsPerPage: rowsPerPage,
    customSetRowsPerPage: setRowsPerPage,
    colSpan: 4,
  };

  let content;

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooCustomTable
        pageInfo={pageInfo}
        entityHeaderList={entityHeaderList}
        entityList={{
          values: entityValues,
          buttons: [
            { type: "View", to: "/schoolsView" },
            { type: "Update", to: "/schoolsUpdate" },
          ],
        }}
      />
    );
  } else if (isError) {
    console.log("error", error);
    content = <div>{error?.data?.message}</div>;
  }

  return (
    <>
      <CssBaseline />

      {content}
    </>
  );
}
