import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooCreateButton } from "../../components/common/EduxooButton";
import EduxooErrorBox from "../../components/common/EduxooErrorBox";
import EduxooSuccessBox from "../../components/common/EduxooSuccessBox";

import { EduxooViewGenerator } from "../../components/common/EduxooViewGenerator";
import { ErrorsSummaryBox } from "../../components/common/ErrorsSummaryBox";
import {
  useGetEducationalRecordQuery,
  useUpdateEducationalRecordForClassMutation,
} from "../educationalrecords/apiEducationalRecordsSlice";
import { useGetSchoolClassesBySchoolYearQuery } from "../schoolclasses/apiSchoolClassesSlice";

export const StudentsClassChangeView = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [
    updateEducationalRecord,
    { isLoading, isSuccess, isError, error },
  ] = useUpdateEducationalRecordForClassMutation();

  const [registrationForm, setRegistrationForm] = useState({
    schoolClassId: "",
    note: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    schoolClassId: "",
    note: "",
    errorCount: 0,
    isValid: true,
  });

  const {
    data: educationalRecord,
    isLoading: isLoadingEduRec,
    isSuccess: isSuccessEduRec,
    isError: isErrorEduRec,
    error: errorEduRec,
  } = useGetEducationalRecordQuery(location.state.entityUrl);

  let content;

  if (isLoadingEduRec) {
    content = <EduxooBackdrop isLoading={isLoadingEduRec} />;
  } else if (isSuccessEduRec) {
    let elementsList = educationalRecord
      ? {
          title: "Student Information",
          elements: [
            {
              title: "Main Details",
              subElements: [
                {
                  textName: "Reference No",
                  textValue: educationalRecord.studentRef,
                },
                {
                  textName: "First Name",
                  textValue: educationalRecord.studentFirstName,
                },
                {
                  textName: "Last Name",
                  textValue: educationalRecord.studentLastName,
                },
                {
                  textName: "Date of Birth",
                  textValue: educationalRecord.dateOfBirth,
                },
                {
                  textName: "Class",
                  textValue: educationalRecord.schoolClassName,
                },
              ],
            },
          ],
        }
      : [];

    content = <EduxooViewGenerator elementsList={elementsList} />;
  } else if (isErrorEduRec) {
    content = <div>{errorEduRec.toString}</div>;
  }

  const {
    data: schoolClasses,
    isLoading: isLoadingSchoolClasses,
    isSuccess: isSuccessSchoolClasses,
    isError: isErrorSchoolClasses,
    error: errorSchoolClasses,
  } = useGetSchoolClassesBySchoolYearQuery(educationalRecord?.schoolYearId, {
    skip: educationalRecord?.schoolYearId ? false : true,
  });

  let schoolClassesSelectList;

  if (isLoadingSchoolClasses) {
    content = <EduxooBackdrop isLoading={isLoadingSchoolClasses} />;
  } else if (isSuccessSchoolClasses) {
    schoolClassesSelectList = schoolClasses?._embedded?.schoolClasses.map(
      (schoolClass) => {
        return (
          <MenuItem key={schoolClass.id} value={schoolClass.id}>
            {schoolClass.name}
          </MenuItem>
        );
      }
    );
  } else if (isErrorSchoolClasses) {
    content = <div>{errorSchoolClasses.toString}</div>;
  }

  const validateForm = () => {
    let isValid = true;
    let errorCount = 0;

    let schoolClassId = "";
    let note = "";

    if (registrationForm.schoolClassId.trim() === "") {
      schoolClassId = "Please select a Class";
      isValid = false;
      errorCount++;
    }

    if (registrationForm.note.trim() === "") {
      note = "Please enter a note";
      isValid = false;
      errorCount++;
    }

    if (!isValid) {
      setValidationErrors({
        ...validationErrors,
        errorCount,
        isValid,
        schoolClassId,
        note,
      });
    }

    return isValid;
  };

  const resetValidationErrors = () => {
    setValidationErrors({
      ...validationErrors,
      isValid: true,
      errorCount: 0,
      schoolClassId: "",
      note: "",
    });
  };

  const onSaveRegisterStudentClicked = async (id) => {
    try {
      console.log("RegistrationForm", registrationForm);
      console.log("educationRecordI", id);
      if (validateForm()) {
        resetValidationErrors();
        setOpen(true);
        await updateEducationalRecord({
          educationalRecordId: id,
          note: registrationForm.note,
          newSchoolClassId: registrationForm.schoolClassId,
        });
      }
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  const handleChange = (e) => {
    let isValidEntry = true;

    /*if (e.target.id === "emailWork" || e.target.id === "emailPersonal") {
    isValidEntry = true;
  } else if (e.target.value === "" || reAlphabetic.test(e.target.value)) {
    isValidEntry = true;
  }*/

    if (isValidEntry) {
      setRegistrationForm({
        ...registrationForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {};

  if (isLoading) {
    content = <EduxooBackdrop isLoading={isLoading} />;
  } else if (isSuccess) {
    content = (
      <EduxooSuccessBox
        open={open}
        setOpen={setOpen}
        resetForm={resetForm}
        success={"Student Class Changed Successfully!!!"}
      />
    );
  } else if (isError) {
    content = <EduxooErrorBox error={error} open={open} setOpen={setOpen} />;
  }

  let message;

  if (!validationErrors.isValid) {
    message = <ErrorsSummaryBox errorCount={validationErrors.errorCount} />;
  }

  return (
    <>
      <CssBaseline />
      {message}
      {content}
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <div style={{ marginTop: 10 }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={validationErrors.schoolClassId !== ""}
              required
            >
              <InputLabel id="schoolclass-select">School Class</InputLabel>
              <Select
                labelId="schoolclass-select"
                id="schoolClassId"
                name="schoolClassId"
                value={registrationForm.schoolClassId}
                label="School Year"
                onChange={handleChange}
              >
                <MenuItem value={""}>Please Select a Class</MenuItem>
                {schoolClassesSelectList}
              </Select>
              <FormHelperText>{validationErrors.schoolClassId}</FormHelperText>
            </FormControl>
          </Box>
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            required
            id="note"
            name="note"
            label="Note"
            margin="dense"
            multiline
            maxRows={2}
            value={registrationForm.note}
            onChange={handleChange}
            error={validationErrors.note !== ""}
            helperText={validationErrors.note}
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EduxooCreateButton
            handleSubmit={() =>
              onSaveRegisterStudentClicked(educationalRecord.id)
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default StudentsClassChangeView;
