import { apiSlice } from "../api/apiSlice";

export const apiLocationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (customPageable) =>
        `/locations?size=${customPageable.rowsPerPage}&page=${customPageable.page}`,
      providesTags: ["Location"],
    }),
    getLocation: builder.query({
      query: (entityUrl) => `${entityUrl}`,
      providesTags: ["Location"],
    }),
    addLocation: builder.mutation({
      query: (newLocation) => ({
        url: "/locations",
        method: "POST",
        body: newLocation,
      }),
      invalidatesTags: ["Location"],
    }),
    getLocationsBySchool: builder.query({
      query: (queryInfo) =>
        `/schools/${queryInfo.school}/locations?size=${queryInfo.rowsPerPage}&page=${queryInfo.page}`,
      providesTags: ["Location"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetLocationQuery,
  useAddLocationMutation,
  useGetLocationsBySchoolQuery,
} = apiLocationsSlice;
