import {
  Box,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { EduxooBackdrop } from "../../components/common/EduxooBackdrop";
import { EduxooSearchButton } from "../../components/common/EduxooButton";
import { ROWS_PER_PAGE } from "../../components/common/EduxooConstans";
import AdmissionRegistersAppSearchResult from "../admissionregisters/AdmissionRegistersAppSearchResult";
import { useGetAdmissionsBySchoolQuery } from "../admissions/apiAdmissionsSlice";
import { useGetAuthorizedSchoolsByUserQuery } from "../users/apiUsersSlice";
import AdmissionRegistersListForParent from "./AdmissionRegistersListForParent";
import {
  useGetAdmissionsByParentQuery,
  useGetChildrenByParentQuery,
} from "./apiParentsUserSlice";

export const ParentRegistrationHistory = () => {
  const [searchQuery, setSearchQuery] = useState({
    schoolId: "",
    admissionId: "",
    childId: "",
    status: "",
  });

  const [isSearch, setIsSearch] = useState(false);

  const {
    data: schools,
    isLoading: isLoadingSchools,
    isSuccess: isSuccessSchools,
    isError: isErrorSchools,
    error: errorSchools,
  } = useGetAuthorizedSchoolsByUserQuery();

  let content;
  let schoolsSelectList;

  if (isLoadingSchools) {
    content = <EduxooBackdrop isLoading={isLoadingSchools} />;
  } else if (isSuccessSchools) {
    schoolsSelectList = schools?._embedded?.schools.map((school) => {
      return (
        <MenuItem value={school.id} key={school.id}>
          {school.name}
        </MenuItem>
      );
    });
  } else if (isErrorSchools) {
    content = <div>{errorSchools.toString}</div>;
  }

  const {
    data: admissions,
    error: errorAdms,
    status: statusAdms,
  } = useGetAdmissionsBySchoolQuery(
    { school: searchQuery.schoolId, rowsPerPage: ROWS_PER_PAGE, page: 0 },
    {
      skip: searchQuery.schoolId ? false : true,
    }
  );

  let admissionsSelectList = admissions?._embedded?.admissions.map(
    (admission) => {
      return (
        <MenuItem value={admission.id} key={admission.id}>
          {admission.name}
        </MenuItem>
      );
    }
  );

  const {
    data: children,
    isLoading: isLoadingChildren,
    isSuccess: isSuccessChildren,
    isError: isErrorChildren,
    error: errorChildren,
  } = useGetChildrenByParentQuery(
    {
      parent: "550f02b6-b1c6-42f6-8dac-fe95a2dd4a6b",
      rowsPerPage: ROWS_PER_PAGE,
      page: 0,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  let childrenSelectList;

  if (isLoadingChildren) {
    content = <EduxooBackdrop isLoading={isLoadingChildren} />;
  } else if (isSuccessChildren) {
    childrenSelectList = children?._embedded?.relationships.map((relation) => {
      return (
        <MenuItem
          value={relation.personRelatedId}
          key={relation.personRelatedId}
        >
          {relation.personRelatedFirstName +
            " " +
            relation.personRelatedLastName}
        </MenuItem>
      );
    });
  } else if (isErrorChildren) {
    content = <div>{errorChildren.toString}</div>;
  }

  let message;

  const validateForm = () => {
    return true;
  };

  const resetValidationErrors = () => {};

  const handleChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };

  const onSearchClicked = async () => {
    try {
      setIsSearch(true);
      window.scroll(0, 0);
    } catch (err) {
      console.error("Failed to save the teacher: ", err);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {message}
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Admission Registers
            </Typography>

            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="school-select">School</InputLabel>
                  <Select
                    labelId="school-select"
                    id="schoolId"
                    name="schoolId"
                    value={searchQuery.schoolId}
                    label="School"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>Please Select School</MenuItem>;
                    {schoolsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="admission-select">Admission</InputLabel>
                  <Select
                    labelId="admission-select"
                    id="admissionId"
                    name="admissionId"
                    value={searchQuery.admissionId}
                    label="Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={undefined}>
                      Please Select admission
                    </MenuItem>
                    {admissionsSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="child-select">Child</InputLabel>
                  <Select
                    labelId="child-select"
                    id="childId"
                    name="childId"
                    value={searchQuery.childId}
                    label="Child"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Child</MenuItem>;
                    {childrenSelectList}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div style={{ marginTop: 10 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="application-status-select">Status</InputLabel>
                  <Select
                    labelId="application-status-select"
                    id="status"
                    name="status"
                    value={searchQuery.status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>Please Select a Status</MenuItem>;
                    <MenuItem value={"STARTED"}>STARTED</MenuItem>;
                    <MenuItem value={"PROCESSING"}>PROCESSING</MenuItem>;
                    <MenuItem value={"WAITING"}>WAITING</MenuItem>;
                    <MenuItem value={"COMPLETED"}>COMPLETED</MenuItem>;
                    <MenuItem value={"NOT_COMPLETED"}>NOT_COMPLETED</MenuItem>;
                    <MenuItem value={"REJECTED"}>REJECTED</MenuItem>;
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <EduxooSearchButton handleSubmit={onSearchClicked} />
          </Box>
        </Container>

        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Paper>
            {isSearch ? (
              <AdmissionRegistersListForParent
                searchQuery={searchQuery}
                setIsSearch={setIsSearch}
              />
            ) : (
              <div>waiting </div>
            )}
          </Paper>
        </Container>
      </Container>
    </>
  );
};

export default ParentRegistrationHistory;
